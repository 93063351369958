import React, {Component} from "react";
import WxComponentBase from "../base/WxComponentBase";
import {fireWxEvent,isLocalFile,resolveLocalFile} from '../../../core/utils';
import cx from 'classnames';

// noprotect
export default class WxImage extends WxComponentBase {
  constructor(...args){
    super(...args);
    this.state = {
      src: "",
      imageSrc:""
    };
    if (!(this._img && this._img instanceof Image)) {
      this._img = new Image;
      let self = this;
      this._img.onerror = function(event) {
        let n = {
          errMsg: "GET " + self._img.src + " 404 (Not Found)"
        };
        fireWxEvent(self,"error",n);
      };
      this._img.onload = function(event) {
        let n = {
          width: self._img.width,
          height: self._img.height
        };
        let rate = this.width / this.height;
        self.setState({rate:rate});

        fireWxEvent(self,"load",n);
      }
  }
  }

  setImageSrc(src){
    if(isLocalFile(src)){
      resolveLocalFile(src).then((file) => {
        const URL = window.URL || window.webkitURL || window.mozURL;
        this.imageObjectUrl = URL.createObjectURL(file);
        this.setState({"imageSrc":this.imageObjectUrl});
        this._imgUrl=this.imageObjectUrl;
      });
    }else{
      this.setState({"imageSrc":src});
      this._imgUrl=src;
    }
  }

  componentWillReceiveProps(nextProps) {
    super.componentWillReceiveProps(nextProps);
    if(nextProps.src != this.state.src){
      this.setImageSrc(nextProps.src);
    }
  }

  componentDidMount(...args){
    this.setImageSrc(this.props.src);
    if(this.props.mode === "widthFix" && this.refs.imageEle){
      this.refs.imageEle.parentElement.style.height =  this.refs.imageEle.style.height;
    }
  }

  doRender() {
    if(this._img && this._img.src != this.state.imageSrc && this.state.imageSrc){
      this._img.src = this.state.imageSrc;
    }

    let repeat = "no-repeat";
    let size = "100% 100%";
    let position = this.props.mode;
    let height = "100%";
    if(this.props.mode === "scaleToFill"){
      size = "100% 100%";
      position = "center";
    }
    if(this.props.mode === "aspectFill"){
      size = "cover";
      position = "center";
    }
    if(this.props.mode === "aspectFit"){
      size= "contain";
      position = "center";
    }
    if(this.props.mode === "widthFix" && this.refs.imageEle && this.state.rate){
      //缩放	widthFix	宽度不变，高度自动变化，保持原图宽高比不变
      let imageWidth = this.refs.imageEle.offsetWidth;
      if(imageWidth>0){
        this.imageWidth = imageWidth;
      }else if(this.imageWidth){
        imageWidth = this.imageWidth;
      }else if(!this._reload){
        //重新刺激计算
        this._reload = true;
        setTimeout(()=>{
          let imageSrc = this._imgUrl;
          imageSrc = imageSrc + (imageSrc.indexOf("?")>=0?"&":"?")+"t="+(new Date()).getTime();
          this.setState({imageSrc:imageSrc});
          this._reload = false;;
        },100);
      }
      if(imageWidth>0) height = imageWidth / this.state.rate + "px";
    }
    if(this.props.mode == "center" ||this.props.mode == "top" || this.props.mode == "bottom" || this.props.mode == "right" || this.props.mode == "left" || this.props.mode == "top-left" || this.props.mode == "top-right" || this.props.mode == "bottom-left" || this.props.mode == "bottom-right"){
    	size = "";
    	position = position.replace("-"," ");
    }

    let imageStyle = {
      backgroundPosition: position,
      backgroundImage:(this.state.imageSrc && this.state.imageSrc.indexOf("%")==-1)?"url(" + encodeURI(this.state.imageSrc) + ")":"url(" + this.state.imageSrc + ")",
      backgroundRepeat:repeat,
      backgroundSize:size,
      height:height
    };



    return (
      <div {...this.toDOMAttrs(this.props)} {...this.htmlProps}>
        {this.state.imageSrc?<div ref="imageEle" style={imageStyle}>
        </div>:<div/>}
      </div>
    );
  }
};
