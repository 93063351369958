export default class GeoLocationImpl {
	constructor(page) {
		this.page = page;
	}

	request(option) {
		let self = this;
		return new Promise(function (resolve, reject) {
			option = option || {};
			option.success = function (res) {
				if ((res.statusCode >= 200) && (res.statusCode < 300)) {
					resolve(res.data);
				} else reject(res);
			};
			option.fail = function (error) {
				reject(error);
			};
			self.page.request(option);
		});
	}

	resolveLocation(evtName, orgRes, dispatchEventFn) {
		var self = this;
		console.log(orgRes);
		var latlng = "" + orgRes.latitude + "," + orgRes.longitude;
		this.request({
			method: "GET",
			url: (wx.App.baseUrl || "") + "/geocoder/v1/?location=" + latlng
		}).then(function (data) {
			console.log(data);
			orgRes.address = orgRes.address || data.result.address;
			orgRes.city = data.result.address_component.city;
			orgRes.district = data.result.address_component.district;
			orgRes.nation = data.result.address_component.nation;
			orgRes.province = data.result.address_component.province;
			orgRes.street = data.result.address_component.street;
			orgRes.street_number = data.result.address_component.street_number;
			dispatchEventFn(evtName, { originalEvent: orgRes });
		}).catch(function (error) {
			console.log(error);
		});
	}

	getLocation(args, dispatchEventFn) {
		var self = this;
		var type = "wgs84";
		if (args && args.type) {
			type = args.type;
		}
		var ret = wx.getLocation({
			type: type
		});
		ret.then(function (res) {
			self.resolveLocation(GeoLocationImpl.EVENT_SUCCESS, res, dispatchEventFn);
		}).catch(function (res) {
			console.log("地理位置失败" + res);
			dispatchEventFn(GeoLocationImpl.EVENT_FAIL, { originalEvent: res });
		});
		return ret;
	}

	chooseLocation(args, dispatchEventFn) {
		var self = this;
		var ret;
		if (args && args.latitude && args.longitude) {
			ret = wx.chooseLocation({
				latitude: args.latitude,
				longitude: args.longitude
			});
		} else {
			ret = wx.chooseLocation({});
		}
		ret.then(function (res) {
			self.resolveLocation(GeoLocationImpl.EVENT_LOCATIONSUCCESS, res, dispatchEventFn);
			dispatchEventFn(GeoLocationImpl.EVENT_LOCATIONCOMPLETE, { originalEvent: res });
		}).catch(function (res) {
			dispatchEventFn(GeoLocationImpl.EVENT_LOCATIONFAIL, { originalEvent: res });
			dispatchEventFn(GeoLocationImpl.EVENT_LOCATIONCOMPLETE, { originalEvent: res });
		});
		return ret;
	}
	openLocation(args) {
		if (args && args.latitude && args.longitude) {
			return wx.openLocation({
				latitude: parseFloat(args.latitude),
				longitude: parseFloat(args.longitude),
				scale: parseInt(args.scale),
				name: args.name,
				address: args.address,
			})
		} else {
			var ret = new Promise((resolve, reject) => {
				wx.getLocation({
					type: 'gcj02', //返回可以用于wx.openLocation的经纬度
				}).then(function (res) {
					var latitude = res.latitude;
					var longitude = res.longitude;
					wx.openLocation({
						latitude: latitude,
						longitude: longitude,
						scale: 28
					}).then(function (res1) {
						resolve(res1);
					}).catch(function (res1) {
						reject(res1);
					});
				}).catch(function (res) {
					reject(res)
				});
			});

			return ret;
		}
	}
}

GeoLocationImpl.EVENT_SUCCESS = "success";
GeoLocationImpl.EVENT_FAIL = "fail";
GeoLocationImpl.EVENT_COMPLETE = "complete";
GeoLocationImpl.EVENT_LOCATIONSUCCESS = "locationsuccess";
GeoLocationImpl.EVENT_LOCATIONFAIL = "locationfail";
GeoLocationImpl.EVENT_LOCATIONCOMPLETE = "locationcomplete";
GeoLocationImpl.EVENT_CANCEL = "cancel";
