import PageImpl from "../../../lib/base/pageImpl";

export default class IndexPage extends PageImpl {
	constructor(...args){
		super(...args);
	}

	onGetUserProfile(){
		var self = this;
		wx.getUserProfile({desc:"用于完善用户信息"}).finally(function(){
            return self.navigateBack();
        });
	}
}
