import RadioCheckboxBase from "./radioCheckboxBase";
export default class Checkbox extends RadioCheckboxBase {
     constructor(page, id, props, context){
        super(page, id, props, context);
     }
}

Checkbox.EVENT_VALUE_CHANGE = "valuechange";

wx.comp = wx.comp || {};
wx.comp.Checkbox = Checkbox;

