import BindComponent from "../../lib/base/bindComponent";
import { observable, autorun, untracked } from '../../lib/mobx/mobx-2.6.2.umd';
import {calItems} from "../checkboxGroup/util";


export default class PopCheckboxGroup extends BindComponent {
	constructor(page, id, props, context) {
		super(page, id, props, context);
	}

    buildState(context){
   	 var state = super.buildState(context);
   	 state.displayValue = this._getDisplayValue(context.vars);
   	
   	 return state;
    }
    
    getSeperator(){
    	return this.props.separator || ",";
    }
    
    _getDisplayValue(vars){
    	var ret = "";
    	var value = this.getValue();
    	if (value){
    		var labels = [];
    		var sep = this.getSeperator();
    		var vals = value.split(sep);
    		var items = calItems(this, vars);
        	for (let i=0; i<vals.length; i++){
        		labels.push(this._getDisplayValueItem(vals[i], items));
        	}
        	ret = labels.join(sep);
    	}
    	
    	if (!ret){
    		ret = this.props.placeholder || "";
    	}
    	return ret;
    }
    
    _getDisplayValueItem(val, items){
    	var ret = val;
    	for (let i=0; i<items.length; i++){
    		if (items[i][this.props.itemValue] == val){
    			ret = items[i][this.props.itemLabel];
    		}
    	}
    	return ret;
    }

	showDialog(evt) {
		if ((evt.currentTarget.dataset.disabled == true) || (evt.currentTarget.dataset.disabled == "true")) return;
		if (evt.target.id == this.props.id + "_input") {
			let checkboxGroup = this.page.comp(this.props.checkboxGroupId, this);
			if (checkboxGroup ) {
				let v = this.getValue();
				checkboxGroup._value.set(v);
				this._tempValue = v;
			}
			let dlg = this.page.comp(this.props.dlgId, this);
			dlg && dlg.show();
		}
	}

	doOK() {
		let oldValue = this.getValue()
		let value = this._tempValue;
		this.doChange({value: value});
		oldValue != value && this.onChange({ oldValue, value });
	
		let dlg = this.page.comp(this.props.dlgId, this);
		dlg && dlg.hide();
	}

	doValueChange(evt) {
		this._tempValue = evt.value;
	}

	onChange(evt) {
		this.fireEvent(PopCheckboxGroup.EVENT_VALUE_CHANGE, { oldValue: evt.oldValue, value: evt.value, source: this });
	}
}

PopCheckboxGroup.EVENT_VALUE_CHANGE = "valuechange";

wx.comp = wx.comp || {};
wx.comp.PopCheckboxGroup = PopCheckboxGroup;

