import { toJS} from "../../lib/mobx/mobx-2.6.2.umd";
export function calItems(comp, vars){
	 var ret = null;
	 if (comp.props.$items){
		 ret = [];
		 var items = comp.page[comp.props.$items](vars);
		 if (items && items.length){
			 var newVars = Object.assign({}, vars);
			 for (let i=0; i<items.length; i++){
				 if (comp.props.$filter){
					 newVars[comp.props.id] = items[i];
					 newVars.$row = items[i];
					 if (comp.page[comp.props.$filter](newVars)){
						 ret[ret.length] = toJS(items[i], true, null, true);	 	 
					 }
				 }else{
					 ret[ret.length] = toJS(items[i], true, null, true);	 
				 }
			 }
		 }
	 }
	 return ret;
}
