import Config from "./config";
import DateUtil from "./DateUtil";
import WorkCalendar, { WORK_STATE } from "./WorkCalendar";
import { DbrestFilter, DbrestWrapper } from "./dbrest";

export default {

    async getWorkDates(orgID, start, end) {
        let sYear = DateUtil.getYear(start);
        let eYear = DateUtil.getYear(end);
        let ret = [];
        for (let year = sYear; year <= eYear; year++) {
            let code = await this._getCalendarCode(orgID, year);
            if (year != sYear) {
                start = year + "-01-01";
            }
            let endDay = end;
            if (year != eYear) {
                endDay = year + "-12-31";
            }
            let workCalendar = new WorkCalendar(code, year);
            let days = workCalendar.getWorkDays(start, endDay);
            ret = ret.concat(days);
        }
        return ret;
    },

    async getRestDates(orgID, start, end) {
        let sYear = DateUtil.getYear(start);
        let eYear = DateUtil.getYear(end);
        let ret = [];
        for (let year = sYear; year <= eYear; year++) {
            let code = await this._getCalendarCode(orgID, year);
            if (year != sYear) {
                start = year + "-01-01";
            }
            if (year != eYear) {
                end = year + "-12-31";
            }
            let workCalendar = new WorkCalendar(code, year);
            let days = workCalendar.getRestDays(start, end);
            ret = ret.concat(days);
        }
        return ret;
    },

    async getWorkDays(orgID, start, end) {
        let days = await this.getWorkDates(orgID, start, end);
        return days.length;
    },

    async getRestDays(orgID, start, end) {
        let days = await this.getRestDates(orgID, start, end);
        return days.length;
    },

    async getEndDate(orgID, start, daysNum) {
        let sYear = DateUtil.getYear(start);
        let code = await this._getCalendarCode(orgID, sYear);
        let workCalendar = new WorkCalendar(code, sYear);
        let end = start;
        let count = 0;
        let i = 0;
        let year = sYear;
        while (count < Math.abs(daysNum)) {
            let eYear = DateUtil.getYear(end);
            if (year != eYear) {
                year = eYear;
                code = await this._getCalendarCode(orgID, year);
                workCalendar = new WorkCalendar(code, year);
            }
            let state = workCalendar.getStateByDate(end);
            if (WORK_STATE == state) {
                count++;
            }
            if (daysNum < 0) {
                i--;
            } else {
                i++;
            }
            end = DateUtil.addDay(start, i);
        }
        let len = daysNum > 0 ? -1 : daysNum==0 ? 0:1;
        return DateUtil.addDay(end, len);
    },

    async getCalendarDay(orgID, date) {
        let year = DateUtil.getYear(date);
        let content = await this._getCalendar(orgID, year);
        let code = content.code;
        let mark = content.mark;
        let workCalendar = new WorkCalendar(code, year, mark);
        return workCalendar.getCalendarDay(date);
    },

    async getEndCalendarDay(orgID, start, daysNum) {
        let end = DateUtil.addDay(start, daysNum);
        let year = DateUtil.getYear(end);
        let content = await this._getCalendar(orgID, year);
        let code = content.code;
        let mark = content.mark;
        let workCalendar = new WorkCalendar(code, year, mark);
        return workCalendar.getCalendarDay(end);
    },

    async getWorkDaysByYear(orgID, year) {
        let code = await this._getCalendarCode(orgID, year);
        let workCalendar = new WorkCalendar(code, year);
        return workCalendar.getAllWorkDaysNumber();
    },

    async getRestDaysByYear(orgID, year) {
        let code = await this._getCalendarCode(orgID, year);
        let workCalendar = new WorkCalendar(code, year);
        return workCalendar.getAllRestDaysNumber();
    },

    async getWorkerByDate(orgID, date, me) {
        let members = await this._getConfig(orgID, me);
        let year = DateUtil.getYear(date);
        let ret = [];
        let names = [];
        let states = [];
        for (let i in members) {
            let fid = members[i].fid;
            let calendarName = await this._getCalendarName(fid, year);
            if (names.length == 0 || !names.includes(calendarName)) {
                let code = await this._getCalendarCodeByName(calendarName);
                let workCalendar = new WorkCalendar(code, year);
                let state = workCalendar.getStateByDate(date);
                if (WORK_STATE == state) {
                    ret.push(members[i]);
                }
                names.push(calendarName);
                states.push(state);
            } else {
                let index = names.indexOf(calendarName);
                let state = states[index];
                if (WORK_STATE == state) {
                    ret.push(members[i]);
                }
            }
        }
        return ret;
    },

    async getWorkerNumByDate(orgID, date, me) {
        let members = await this.getWorkerByDate(orgID, date, me);
        return members.length;
    },

    async _getCalendarCode(orgID, year) {
        let calendarName = await this._getCalendarName(orgID, year);
        return await this._getCalendarCodeByName(calendarName);
    },

    async _getCalendarName(orgID, year) {
        try {
            let data = await Config.getConfig("entry", year + "-calendar", "WorkCalendarOrg", orgID, null);
            var content = data[0].content || "";
            return content.calendarName || "";
        } catch (error) {
            if (error == "获取系统配置出错") {
                return "";
            }
        }
    },

    async _getCalendarCodeByName(calendarName) {
        if (!calendarName) {
            //没定义工作日历
            return new Promise(resolve => {
                resolve("");
            });
        }
        let data = await Config.getConfig("entry", calendarName, "WorkCalendar", null, null);
        var content = data[0].content || {};
        return content.code || "";
    },

    async _getCalendar(orgID, year) {
        let calendarName = await this._getCalendarName(orgID, year);
        let ret = {};
        if (!calendarName) {
            //没定义工作日历
            return new Promise(resolve => {
                ret = { code: "", mark: "" }
                resolve(ret);
            });
        }
        let data = await Config.getConfig("entry", calendarName, "WorkCalendar", null, null);
        var content = data[0].content || {};
        return content;
    },

    async _getConfig(orgId, me) {
        var dbrest = new DbrestWrapper("/opm/orgs", me);
        dbrest.setServiceName("entry");
        dbrest.eq("type", "psm").like("fid", orgId);
        let data = await dbrest.selectAll();
        return data;
    }

}
