import BindComponent from "../../lib/base/bindComponent";
import {observable,autorun,untracked} from '../../lib/mobx/mobx-2.6.2.umd';
import {isArray, isObject} from "../../lib/base/util";
import {calItems} from "./util";

export default class CheckboxGroup extends BindComponent {
     constructor(page, id, props, context){
        super(page, id, props, context);
        this._value = observable("");
        this.separator = this.props.separator || ",";
     }

     buildState(context){
    	 var state = super.buildState(context);
    	 Object.assign(state, this._buildState(context.vars));
    	 return state;
     }
     
     _buildState(vars){
    	 var ret = {checkeds: [], items: null};
    	 var items = calItems(this, vars);
    	 if (items){
    		 if (this.props.$filter) ret.items = items;	
        	 var values = ((this.getValue() || this._value.get() || "") + "").split(this.separator);
        	 if (items.length){
        		 for (let i=0; i<items.length; i++){
        			 ret.checkeds[ret.checkeds.length] = (values.indexOf(items[i][this.props.itemValue] + "") > -1); 
        		 }
        	 }
    	 }
    	 return ret;
     }
     
     onChange(evt){
    	 let val = evt.detail.value;
    	 if(isArray(val)){
    		 val = val.join(this.separator);
    	 }else{
    		 val = val + "";
    	 }
         this.doChange({value: val});
     }
}

CheckboxGroup.EVENT_VALUE_CHANGE = "valuechange";

wx.comp = wx.comp || {};
wx.comp.CheckboxGroup = CheckboxGroup;

