import Schema from "../tableData/schema";
import {getDefaultValue} from "../tableData/schema";
import {transaction, untracked, observable, extendObservable, computed, autorun, toJS, isObservableArray, isObservableObject, isObservable, intercept, observe} from  "../../lib/mobx/mobx-2.6.2.umd";
import {parsePath} from "../../lib/base/pageImpl";
import Data from "../tableData/data";
import {isArray,isFunction, cloneJSON} from "../../lib/base/util";
import Number from "../../lib/base/number";
import UUID from "../../lib/base/uuid";
import Operational from "../../lib/base/operational";

/**
  	schema定义结构:
  	列表格式
  	{
    	type: "array",
    	items: {
	        type: "object",
	        props: {
	            id: {
	                label: "主键",
	                type: "number",
					default: function($row), //默认值
					required: { //必填
						fn: function($row),  
						msg: "", //提示信息
					}
					readonly: function($row), //只读, 
					calculate: function($row), //计算规则
					constraint: { //约束
						fn: function($row), 
						msg: ""   //提示信息
					}
	            }
	        }
	    } 
	}
	对象格式
	{
        type: "object",
        props: {
            id: {
                label: "主键",
                type: "number",
				default: function($row), //默认值
				required: { //必填
					fn: function($row),  
					msg: "", //提示信息
				}
				readonly: function($row), //只读, 
				calculate: function($row), //计算规则
				constraint: { //约束
					fn: function($row), 
					msg: ""   //提示信息
				}
            }
        }
	}
 
 */


export default class JSONData extends Data {
    constructor(page, id, props, context){
    	props = props || {};
    	props.clz = "JSONData";
        super(page, id, props, context);
    }
    
    getClass(){
    	return JSONData;
    }
    
    checkIdColumn(data){
    	//忽略
    }
    
    enableArrayChangeEvent(){
    	return true;
    }
}

wx.comp = wx.comp || {};
wx.comp.JsonData = JSONData;
