import React from "react";
import WxComponentBase from "../base/WxComponentBase";
import {compatComponentProps,openContactPage,fireWxEvent} from '../../../core/utils';
import {hoverTouchStart,hoverTouchEnd,hoverTouchCancel} from '../../../core/hoverClass';
import header from 'core/header';
import {getUserInfo} from '../../../core/api/openApi';

export default class WxButton extends WxComponentBase{
	constructor(...args){
		super(...args);
	}

	hasDisabledAttr(){
		return true;
	}

	onClickHandler(event){
		//触发form的submit和reset事件
    if(this.toBoolean(this.props.disabled)){
        return;
    }

    let openType = this.props['open-type'];
    let formType = this.props['form-type'];
    if(openType === "share"){
      header.share();
    }else if(openType === "contact"){
      openContactPage(this.props['session-from']);
    }else if(openType === "getUserInfo"){
      let currentTarget = event.currentTarget;
      let self = this;
      getUserInfo({success:function(data){
        fireWxEvent(self,"getuserinfo",data,currentTarget);
      }});
    }else if(openType === "getPhoneNumber"){
      fireWxEvent(self,"getphonenumber",{
        errMsg:"getPhoneNumber:fail"
      });
    }else if(formType =="submit"){
			let event = document.createEvent('Event');
			event.initEvent('formSubmit', true, true);
			document.dispatchEvent(event);
		}else if(formType =="reset"){
			let event = document.createEvent('Event');
			event.initEvent('formReset', true, true);
			document.dispatchEvent(event);
		}
	}

	onTouchStartHandler(event){
		super.onTouchStartHandler(event);
		const hoverClz=this.props["hover-class"];
		const hoverStartTime=Number(this.props["hover-start-time"])||50;
		if(this.toBoolean(this.props.disabled))return;
    let s = this.__root__.wxPageStyle || {};
		hoverTouchStart(s[hoverClz] || hoverClz,"button-hover",hoverStartTime)
			.then(newClassName=>{
				newClassName && (this.rootNode && this.rootNode.classList.add(newClassName))
			})
	}

	onTouchEndHandler(e){
		super.onTouchEndHandler();
		hoverTouchEnd(Number(this.props["hover-stay-time"])||400,this._hoverReset.bind(this));
	}

	onTouchCancelHandler(e){
		super.onTouchCancelHandler();
		hoverTouchCancel(this._hoverReset.bind(this));
	}

	onTouchMoveHandler(e) {
		super.onTouchMoveHandler();
		hoverTouchCancel(this._hoverReset.bind(this));
	}
	_hoverReset(){
		let hoverClz=this.props["hover-class"] || "button-hover";
    let s = this.__root__.wxPageStyle || {};
    hoverClz = s[hoverClz] || hoverClz;
		if(this.rootNode && this.rootNode.className.includes(hoverClz)){
			this.rootNode.classList.remove(hoverClz);
		}
	}

	doRender(){
		let ccp = compatComponentProps(this.props);
		return (
			<div {...this.toDOMAttrs(ccp)} {...this.htmlProps} href="javascript:void(0)"
           disabled={this.toBoolean(this.props.disabled)}
				ref={(ref)=>this.rootNode=ref }>
				{this.props.children}
			</div>
		);
	}
}

WxButton.defaultProps={
  type:"default",
  "session-from":'x5app',
  size:"default",
  plain:false,
  loading:false,
  disabled:false
};
