import React, {Component} from "react";
import WxComponentBase from "../base/WxComponentBase";
import cx from "classnames";

//var ICON_CLASS_PREFIX = "wx-icon-";


export default class WxIcon extends WxComponentBase{
	constructor(...args){
		super(...args);
	}

	getIconClass(type){
		return type ?  `wx-icon-${type}`: ""; 
	}

	doRender(){
		var style = this.htmlProps.style || this.props.style || {};
		var className = this.getClassName();
		
		//standard icon
		if (this.props.hasOwnProperty("type")){
			//type
			var iconClass = this.getIconClass(this.props.type || "");
			className = cx(iconClass, className);
			
			//size
			var size = 23;
			if (this.props.hasOwnProperty("size")){
				size = parseInt(this.props.size);
				if (isNaN(size)){
					size = 0;
				}
			}
			if(this.props.type !="wx-btn-icon"){				
				style.fontSize = size + "px";
			}
			
			//color
			if (this.props.color){
				style.color = this.props.color;
			}
		}
		
		
		
		return (
			<div  {...this.toDOMAttrs(this.props)} {...this.htmlProps}>
				<i  style={style} className={iconClass}></i>
			</div>
		);
	}
}
