import BindComponent from "../../lib/base/bindComponent";
import { observable, toJS, autorun, untracked } from '../../lib/mobx/mobx-2.6.2.umd';

export default class DateSearchBar extends BindComponent {
     constructor(page, id, props, context){
        super(page, id, props, context);
        this.dataComp = null;
        this.inputValue = observable("");
		this.beginDate = null;
		this.endDate = null;
		this.filterMode = props.filterMode || "singleDate";
     }
     
     buildState(context){
		var state = super.buildState(context);
		state.value = this.getInputValue();
		return state;
     }
     
     getInputValue(){
    	 return this.inputValue.get();
     }
     
     setInputValue(val){
    	 this.inputValue.set(val);
     }

     setData(data){
    	 this.dataComp = data; 
     }
     
     getFilter(){
		let beginRelation, endRelation;
		if (this.props.filterMode == "singleDate") {
			beginRelation = endRelation = this.props.dateCol;
		} else {
			beginRelation = this.props.beginDateCol;
			endRelation = this.props.endDateCol;
		}

		var filters = [];
		filters.push({
			op: "LBRAC"
		});
		if (this.beginDate) {
			filters.push({
				  name: endRelation,
				  op: "gte",
				  value: this.beginDate
			  });
		}
		if (this.endDate) {
			var endDate = new Date(this.endDate);
			endDate.setDate(endDate.getDate() + 1);
			filters.push({
				  name: beginRelation,
				  op: "lt",
				  value: endDate
			  });
		}
		filters.push({
			 op: "RBRAC"
		 });
		return filters;
     }
     
     onClear(event){
    	 this.setInputValue("");
    	 this.beginDate = null;
    	 this.endDate = null;
     }
     
     onInput(event){
		 this._update(this.context);
	 }
     
     getSelectDialogUrl(){
 		return this.props.selectDialogUrl;
 	 }

     showSelectDialog(evt){
     	let option = {
     		url: this.getSelectDialogUrl(),
     		params: {
     			options: this.props.options,
     			beginDate: this.beginDate,
     			endDate: this.endDate,
     			selectValue: this.selectValue||this.props.defaultValue
     		}
     	};
  		option.onClose = (event) => {
 			let data = event.data || {};
 			if (data.isOk){
 		    	this.setInputValue(data.label||"");
 		    	this.selectValue = data.selectValue; 
 		    	this.beginDate = data.beginDate;
 		    	this.endDate = data.endDate;
 				this.onSearch();
 			}
 		};
  		wx.navigateTo(option);
      }
     
     onSearch(event){
    	 var filter = this.getFilter();
    	 if (filter){
        	 var eventData = {source: this, filterProps: this.filterProps, cancel: false, filter: filter, refreshOption:{}};
        	 this.fireEvent(DateSearchBar.EVENT_SEARCH, eventData);
        	 if (!eventData.cacnel){
        		 this.doSearch(eventData.filter, eventData.refreshOption);
        	 }
    	 }
     }
     
     getData(){
		 let data = this.dataComp;
		 if (!data && this.props.data){
			 data = this.page.comp(this.props.data);
		 }
		 return data;
     }
     
     doSearch(filter, refreshOption){
    	 if(this.props.autoRefresh){
    		 let data = this.getData();
    		 if (data){
    			 data.setFilter(this.props.id + "__date_filter", filter);
    			 data.refreshData(refreshOption);
    		 }
    	 }
     }
     
     clear(){
    	this.onClear();
    	this.clearDataFilter();
     }
     
     clearDataFilter(){
    	 let data = this.dataComp;
    	 if (!data && this.props.data){
    		 data = this.page.comp(this.props.data);
    	 }
    	 if (data){
    		 data.setFilter(this.props.id + "__date_filter", null);
    	 }
     }
}

DateSearchBar.EVENT_SEARCH = "search";
wx.comp = wx.comp || {};
wx.comp.DateSearchBar = DateSearchBar;

