import BindComponent from "../../lib/base/bindComponent";
import {autorun,observable} from  "../../lib/mobx/mobx-2.6.2.umd";
export default class RadioCheckboxBase extends BindComponent {
     constructor(page, id, props, context){
        super(page, id, props, context);
        this.hasRef = !!this.props.$refFn;
        if (!this.hasRef){
        	this._value =  observable("");
        }
     }
     
     getValue(){
    	 if (this.hasRef){
    		 return super.getValue();
    	 }else{
    		 return this._value.get();
    	 }
     }
     
     setValue(path, prop, value, callback){
    	 if (this.hasRef){
    		 super.setValue(path, prop, value, callback);
    	 }else{
    		 if (this._value.get() !== value){
    			 callback && callback(value, this._value.get());
    			 this._value.set(value);
    		 }
    	 }
     }
     
     buildState(context){
  		var state = super.buildState(context);
 		state.checked = (this.getValue() == this.props.value); 
 		return state;
      }
     
     onTap(event){
    	 var cur = this.getValue();
    	var self = this;
    	//单选行为需要用户实现
    	/* if(this.context.vars.$data){
    		 this.context.vars.$data.each((item)=>{
    			 item.row[self.props.$propName] = "";
    		 })
    	 }*/
    	 var newValue = (cur == this.props.value) ? "" : this.props.value;
    	 this.doChange({value: newValue});
     }
}
