import React, {Component} from "react";
import WxComponentBase from "../base/WxComponentBase";
import {hoverTouchStart,hoverTouchEnd,hoverTouchCancel} from '../../../core/hoverClass';


export default class WxView extends WxComponentBase{
	constructor(...args){
		super(...args);
	}

	onTouchStartHandler(event){
		super.onTouchStartHandler(event);
		const hoverClz= this.props["hoverClass"];
		if(!hoverClz || hoverClz=="none") return false;
		const hoverStartTime=Number(this.props["hoverStartTime"])||50;
    let s = this.__root__.wxPageStyle || {};
		hoverTouchStart(s[hoverClz] || hoverClz,"",hoverStartTime)
			.then( newClassName => {
				newClassName && this.rootNode.classList.add(newClassName)
		})
	}

	onTouchEndHandler(e){
		super.onTouchEndHandler();
		const hoverClassName = this.props["hoverClass"];
		hoverClassName && hoverTouchEnd (Number(this.props["hoverStayTime"])||400,this.hoverReset.bind(this));
	}

	onTouchCancelHandler(e){
		super.onTouchCancelHandler();
		this.props["hoverClass"] && hoverTouchCancel(this.hoverReset.bind(this));
	}

	onTouchMoveHandler (e) {
		super.onTouchMoveHandler();
		this.props["hoverClass"] &&　hoverTouchCancel(this.hoverReset.bind(this));
	}
	hoverReset(){
    let hoverClassName = this.props["hoverClass"];
    let s = this.__root__.wxPageStyle || {};
    hoverClassName = s[hoverClassName] || hoverClassName;
		if(this.rootNode.className.includes(hoverClassName)){
			this.rootNode.classList.remove(hoverClassName)
		}
	}

	componentDidMount(){
		this.rootNode = this.refs.rootNode;
	}

	doRender(){
    return <div {...this.toDOMAttrs(this.props)} {...this.htmlProps}  ref="rootNode">
				{this.props.children}
			</div>;
	}
}
