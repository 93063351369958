import Component from "../../lib/base/component";
import {observable } from  "../../lib/mobx/mobx-2.6.2.umd";

export default class Navbar extends Component {
     constructor(page, id, props, context){
        super(page, id, props, context);
        this.current = observable(props.current);
        var self = this;
        page.on("loaded", function(){  	
        	if(self.current.get() && self.props.autoActive){   		 
        		self.fireEvent(Navbar.EVENT_ACTIVE, {id: self.current.get(),index:self.getIndex(props.bars,self.current.get()), source: self});
        		self.fireEvent("active_"+self.current.get(), {id:self.current.get(),index:self.getIndex(props.bars,self.current.get()),source:self});
        	}	 
        });
     }
     
     getIndex(bars,id){
    	 return bars.indexOf(id);
     }
     buildState(context){
    	 var state = super.buildState(context);
    	 state.current = this.current.get();
    	 return state;
     }
     itemClick(event){
//    	 nav----------------------active
//    	 	bar   -- id1_active(click)
//    	 	bar   -- id2_active2(click)
//    	 	bar   -- id3_active3
//    	 	
//    	 <nav
//    	 	<bar >
//    	 
//    	 <nav on:active1="{xxxxx}"    	 
    	 var id = event.currentTarget.id;
    	 this.to(id);
     }
     to(id,index){
    	 if(id!==this.current.get()){    		 
    		 this.current.set(id);
    		 this.fireEvent(Navbar.EVENT_ACTIVE, {id: id,index:this.getIndex(this.props.bars,id),source:this});
    		 this.fireEvent("active_"+id, {id:id,index:this.getIndex(this.props.bars,id),source:this});
    	 }
     }
     initOperation(){
     	super.initOperation();
 	   	this.defineOperation('setCurrent', {
 	   		 label : "激活",
 	   		 icon : '',
 	   		 init : function() {},
 	   		 argsDef : [ {
 	   			 name : 'current',
 	   			 displayName : "导航项"
 	   		 }],
 	   		 method : function(args) {
 	   			 this.owner.to(args.current);
 	   		 }
 	   	 });
     }
}

Navbar.EVENT_ACTIVE = "active";
wx.comp = wx.comp || {};
wx.comp.Navbar = Navbar;

