import Base64 from './base64';
import CryptoRequest from "core/api/network/crypto-request";

class FileApi {
	getActionUrl(page, props = {}, fileInfo = {}) {
		let actionUrl = fileInfo.actionUrl || props.actionUrl || "/storage";
		let sourceServiceName = fileInfo.sourceServiceName || props.sourceServiceName;
		return page.getServiceUrl(actionUrl, sourceServiceName);
	}

	getFileUrl(info) {
		info.operateType = info.operateType || "browse";
		if (info.operateType == "upload") {
			return info.actionUrl + "/postObject";
		} else if (info.operateType == "fileview") {
			var url = info.actionUrl + "/getObject?storeFileName=" + encodeURIComponent(info.storeFileName)
				+ (info.ownerID ? ("&ownerID=" + encodeURIComponent(info.ownerID)) : "")
				+ (info.realFileName ? ("&realFileName=" + encodeURIComponent(info.realFileName)) : "")
				+ (info.operateType ? ("&operateType=" + encodeURIComponent(info.operateType)) : "")
				+ "&fullfilename=" + encodeURIComponent(info.realFileName || info.storeFileName);
			//浏览 下载 返回绝对路径 支持其他设备或者其他应用使用  uix这一支 因为是小程序规范 所以内部不能是http开头的
			let result = '/fileview/onlinePreview?url=' + encodeURIComponent(Base64.encode(url));
			if (window && window.parentPath) {
				result = window.parentPath + result;
			}
			//return CryptoRequest.encode(result);
			//fileview 不走加密 因为里面pdf转换按钮需要url不加密
			return result;
		} else {
			let result = info.actionUrl + "/getObject?storeFileName=" + encodeURIComponent(info.storeFileName)
				+ (info.ownerID ? ("&ownerID=" + encodeURIComponent(info.ownerID)) : "")
				+ (info.realFileName ? ("&realFileName=" + encodeURIComponent(info.realFileName)) : "")
				+ (info.operateType ? ("&operateType=" + encodeURIComponent(info.operateType)) : "")
				+ "&fullfilename=" + encodeURIComponent(info.realFileName || info.storeFileName);
			//浏览 下载 返回绝对路径 支持其他设备或者其他应用使用  uix这一支 因为是小程序规范 所以内部不能是http开头的
			return CryptoRequest.encode(result);
		}
	}

	getSrc(src, {page, props}) {
		if (src && src.indexOf("$UI") === 0) {
			return page.getUIUrl(src);
		} else if (src && src.startsWith("[{")) {
			let info = this.getFirstImageInfo(src);
			if (info){
				return this.getFileUrl({
					actionUrl: this.getActionUrl(page, props),
					...info
				})
			}
		}
		return src;
	}

	getFirstImageInfo(src) {
		let results = JSON.parse(src);
		return results?.[0];
	}
}

export default new FileApi();
