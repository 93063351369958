import DateUtil from "./DateUtil";
export default class WorkCalendar {
    constructor(code, year, mark) {
        this.code = code;
        this.year = year;
        this.mark = mark;
    }

    getStateByDate(date) {
        let dayNum = DateUtil.getDayNumByDate(date);
        let state = this.code.split("")[dayNum - 1];
        return state;
    }

    getWorkDays(start, end){
        let days = DateUtil.getBetweenDays(start, end);
        let ret = [];
        if(!this.code){
            //没定义工作日历
            if(DEFAULT_STATE == '1'){
                return days;
            }
            return ret;
        }
        for(let i in days){
            let state = this.getStateByDate(days[i]);    
            if(state == '1'){
                ret.push(days[i]);
            }
        }
        return ret;
    }

    getRestDays(start, end){
        let days = DateUtil.getBetweenDays(start, end);
        let ret = [];
        if(!this.code){
            //没定义工作日历
            if(DEFAULT_STATE == '1'){
                return ret;
            }
            return days;
        }
        for(let i in days){
            let state = this.getStateByDate(days[i]);    
            if(state == '0'){
                ret.push(days[i]);
            }
        }
        return ret;
    }

    getStateByDate(date){
        let num = DateUtil.getDayNumByDate(date);
        if(!this.code){
            return DEFAULT_STATE;
        }
        let state = this.code.split("")[num-1];
        return state;
    }

    getCalendarDay(date){
        let ret = {date:date,state:"",mark:""};
        ret.state = this.getStateByDate(date);
        for(let i in this.mark){
            if(this.mark[i].date == date){
                ret.mark = this.mark[i].name;
                break;
            }
        }
        return ret;
    }

    getAllWorkDaysNumber(){
        if(!this.code){
            if(DEFAULT_STATE == "1"){
                return DateUtil.getYearDays(this.year);
            }
            return 0;
        }
        return this.code.replaceAll("0", "").length;
    }

    getAllRestDaysNumber(){
        if(!this.code){
            if(DEFAULT_STATE == "1"){
                return 0;
            }
            return DateUtil.getYearDays(this.year);
        }
        return this.code.replaceAll("1", "").length;
    }

}
export const DEFAULT_STATE = "1";
export const WORK_STATE = "1";
export const REST_STATE = "0";
