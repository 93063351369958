import Component from "../../lib/base/component";
export default class Address extends Component{
    constructor(page, id, props, context){
        super(page, id, props, context);
     }
    
    chooseAddress(){
    	var self = this;
    	var ret = wx.chooseAddress();
    	ret.then(function(res){
			  //console.log("选择地址成功"+ JSON.stringify(res));
			  self.fireEvent(Address.EVENT_SUCCESS , {source: self, data: res});
			  self.fireEvent(Address.EVENT_COMPLETE , {source: self, data: res});
    	}).catch(function(res){
			  //console.log("选择地址失败"+JSON.stringify(res));
			  self.fireEvent(Address.EVENT_FAIL , {source: self, data: res});
			  self.fireEvent(Address.EVENT_COMPLETE , {source: self, data: res});
    	});
    	return ret;
    }
    
    initOperation(){
    	 super.initOperation();
	   	 this.defineOperation('chooseAddress', {
	   		 label : "选择地址",
	   		 icon : '',
	   		 init : function() {},
	   		 argsDef : [],
	   		 method : function(args) {
	   			 return this.owner.chooseAddress(args);
	   		 }
	   	 });
     }
};

Address.EVENT_SUCCESS = "success";
Address.EVENT_FAIL = "fail";
Address.EVENT_COMPLETE = "compete";

wx.comp = wx.comp || {};
wx.comp.Address = Address;
