
export default class ClipboardImpl {
	constructor(page) {
		this.page = page;
	}
	
	setClipboardData(data,dispatchEventFn){
    	var ret = wx.setClipboardData({
    		  data:data	
    	});
    	ret.then(function(res){
    		dispatchEventFn('successBySet' , {});
    	}).catch(function(res){
			dispatchEventFn('failBySet' , {});
    	});
    	return ret;
    }

    getClipboardData(dispatchEventFn){
    	var ret = wx.getClipboardData({});
    	ret.then(function(res){
    		dispatchEventFn('successByGet' , { data: res.data});
    	}).catch(function(res){
    		dispatchEventFn('failByGet' , {});
    	});
    	return ret;
    }
	
}
