
import TableData from "../tableData/tableData";
import _String from "../../lib/base/string";
import Data from "../tableData/data";
import { isArray } from "../../lib/base/util";
import { receiveByMapping } from "./js/receiveMapping";
import {isObservableArray, isObservableObject} from  "../../lib/mobx/mobx-2.6.2.umd";

export default class CommonOperationImpl {
	constructor(page) {
		this.page = page;
	}

	getData(dataId) {
		if (dataId) {
			var data = typeof (dataId) === 'string' ? this.page.comp(dataId) : dataId;
			return data;
		}
	}
	
	removeData(dataId, rows) {
		let data = this.getData(dataId);
		if (!data){
			throw new Error(_String.format(this.page.i18n("源数据集{0}不存在"), dataId));
		}
		data.removeData(rows);
	}

	
	copyRows(sourceDataId, rows, destDataId){
		let sourceData = this.getData(sourceDataId);
		if (!sourceData){
			throw new Error(_String.format(this.page.i18n("源数据集{0}不存在"), sourceDataId));
		}
		return sourceData.copyRows(rows, destDataId);
	}

	/**
	 * 数据赋值
	 * @param data:目标数据集
	 * @param col:列
	 * @param value:值
	 * @param row:行ID
	 */
	setValue(dataId, col, value, row) {
		var data = this.getData(dataId);
		if (data instanceof TableData) {
			if (typeof (row) !== 'string') {
				if (row && data !== row.$data) {//不是真正的行对象，支持包含id列数据的对象，否则给数据当前行赋值				
					let idCol = data.getIdColumn();
					row = data.getRowByID(row[idCol]);
				}
				data.setValue(col, value, row);
			} else
				data.setValueByID(col, value, row);
		} else {
			var msg = this.page.i18n('数据集参数无效');
			throw new Error(msg);
		}
	}
	/**
	 * 多列赋值
	 * @param dataId:目标数据集
	 * @param values:值
	 */
	fieldValues(dataId, values) {
		var data = this.getData(dataId);
		if (data instanceof TableData) {
			if (isArray(values)) {
				for (var i = 0; i < values.length; i++) {
					data.setValue(values[i].filed, values[i].value);
				}
			}
		} else {
			var msg = this.page.i18n('数据集参数无效');
			throw new Error(msg);
		}
	}

	/**
	 * 数据所有行赋值
	 * @param dataId:目标数据集
	 * @param col:列
	 * @param value:值
	 */
	allSetValue(dataId, col, value) {
		var data = this.getData(dataId);
		if (data instanceof TableData) {
			for (let i = 0; i < data.value.length; i++) {
				let row = data.value[i];
				data.setValue(col, value, row);
			}
		} else {
			var msg = this.page.i18n('数据集参数无效');
			throw new Error(msg);
		}
	}

	_getResult(data) {
		var ret = [];
		if (data instanceof TableData) {
			for (let i = 0; i < data.value.length; i++) {
				let row = data.value[i];
				ret.push(data.row2json(row));
			}
		}
		return ret;
	}

	/**
	 * 保存并返回
	 * @param dataId:目标数据集
	 * @param insertPos:新增插入位置
	 */
	saveReturn(dataId, insertPos) {
		var data = this.getData(dataId);
		if (data instanceof TableData) {
			let insPos = insertPos || -1;
			insPos = 'string' === typeof (insPos) ? parseInt(insPos) : insPos;
			let self = this;
			let fn = function () {
				self.page.navigateBack({ message: { type: 1, data: self._getResult(data), insertPos: insPos } });
			};
			if (data.isChanged())
				data.saveAllData({ onSuccess: fn });
			else fn();
		} else this.page.navigateBack();
	}

	_receiveByMapping(mapping, receiveData, insertPos) {
		receiveByMapping(this.page, mapping, receiveData, insertPos);
	}

	/**
	 * 保存
	 * @param dataId:目标数据集
	 */
	saveData(dataId, row, successHint, successInfo, batch) {
		var data = this.getData(dataId);
		if (data instanceof TableData) {
			let ret = data.saveAllData({ batch: batch }).then((eventData) => {
				let saveException = eventData && eventData.data && eventData.data.saveException;
				(false != successHint && 'false' != successHint && !saveException) && this.page.hint(successInfo || this.page.i18n("保存成功"));
			});
			return !batch ? ret : null;
		}
	}

	/**
	 * 删除
	 * @param dataId:目标数据集
	 * @param row:行ID
	 * @param filter:删除条件
	 * @param force:禁止提示
	 */
	deleteData(dataId, row, filter, confirm, batch) {
		let data = this.getData(dataId);
		let o = { async: true, batch: batch };
		if (confirm !== undefined) o['confirm'] = confirm;
		if (data instanceof TableData) {
			if (typeof (filter) === 'function') {
				let rows = [];
				data.each(function (p) {
					if (filter(p.row)) rows.push(p.row);
				});
				if (rows.length > 0) {
					let ret = data.deleteData(rows, o);
					return !batch ? ret : null;
				}
			} else {
				let ret = data.deleteData(row, o);
				return !batch ? ret : null;
			}
		} else {
			var msg = this.page.i18n('数据集参数无效');
			throw new Error(msg);
		}
	}

	/**
	 * 加载数据
	 * @param dataId: 目标数据集
	 * @param rows:数据
	 * @param append:增量模式
	 */
	loadData(dataId, rows, append, parent, index, override) {
		var data = this.getData(dataId);
		if (data) {
			if (data instanceof TableData) {
				var ret = data.loadData(rows, append, parent, index, override);
				if (isArray(ret) && ret.length > 0) data.to(ret[0]);
			} else this.page.hint(_String.format(this.page.i18n("加载数据时指定的数据组件{0}不存在"), dataId), 'warn');
		} else this.page.hint(this.page.i18n("加载数据操作必须指定数据组件"), 'warn');
	}

	/**
	 * 打开子页面
	 * @param url:页面源
	 * @param params:参数
	 * @param receData:共享数据集
	 * @param option:对话框配置
	 * @deprecated
	 */
	openPageDialog(url, dataId, params, option, row, wait, openType) {
		return new Promise((resolve, reject) => {
			option = option || {};
			option.openType = openType
			url && (option.url = url);
			params && (option.params = params);
			let self = this;
			let data = this.getData(dataId);
			if (data instanceof TableData && data.className) {
				if (!option.params) option.params = {};
				if (!data.multiplePrimary) {
					let pname = data.className + '.id';
					if (!option.params.hasOwnProperty(pname))
						option.params[pname] = data.getRowID(row);
				} else {
					let idColumns = data.primaryColumns;
					for (let i = 0, len = idColumns.length; i < len; i++) {
						let pname = data.className + "." + idColumns[i];
						if (!option.params.hasOwnProperty(pname))
							option.params[pname] = data.getValue(idColumns[i], row);
					}
				}

				let idCol = data.getIdColumn();
				let mapping = { dataID: data.id, operation: 'edit', mappings: [], locfrom: idCol, locto: idCol, disableRecordChange: true };
				if (data.multiplePrimary) {
					mapping.locfrom = [];
					mapping.locto = [];
					let idColumns = data.primaryColumns;
					for (let i = 0, len = idColumns.length; i < len; i++) {
						mapping.locfrom.push(idColumns[i]);
						mapping.locto.push(idColumns[i]);
					}
				}
				let cols = data.getColumnIDs();
				if (cols) {
					cols = cols.split(data.delim);
					for (let i = 0, len = cols.length; i < len; i++) {
						let o = cols[i];
						mapping.mappings.push({ from: o, to: o });
					}
				}
				option.onClose = function (event) {
					//数据返回处理
					if (event.data && event.data.type === 1) {
						let retData = event.data.data;
						let insertPos = undefined === event.data.insertPos ? -1 : event.data.insertPos;
						self._receiveByMapping(mapping, retData, insertPos);
					}
					if (wait) {
						//通知共享数据集更新数据,便于后续操作使用共享数据					
						Data.loadShareData(self.page);
						resolve();
					}
				};
			} else if (wait) {
				option.onClose = function (event) {
					//通知共享数据集更新数据,便于后续操作使用共享数据					
					Data.loadShareData(self.page);
					resolve();
				};
			}
			this.page.navigateTo(option);
			if (!wait) resolve();
		});
	}

	/** 关闭页面*/
	close() {
		Data.updateShareData(this.page);
		if (this.page.getParentFrame && this.page.getParentFrame() && this.page.getParentFrame().postMessage){
			this.page.getParentFrame().postMessage({isOk: false});
		}else{
			return this.page.navigateBack();	
		}
	}

	/**
	 * 确定返回
	 * @param dataId:目标数据集
	 * @param index:新增插入位置
	 */
	okclose(dataId, insertPos) {
		Data.updateShareData(this.page);
		let parentFrame = null;
		/**
		 *   getParentFrame 底层实现 __$$pageFrames 是老版本实现
		 *
		 *   data instanceof TableData 是兼容小程序实现
		 *
		 *  pageType == "uix" 走新体系
		 */
		if(this.page.pageType == "uix"){
			//hcr  mapping
			let message = { isOk: true };

			let data = this.getData(dataId);
			if (data instanceof TableData) {
				let insPos = insertPos || -1;
				insPos = 'string' === typeof (insPos) ? parseInt(insPos) : insPos;
				message = { isOk: true, type: "mapping",  data: this._getResult(data), insertPos: insPos };
			}
			this.page.navigateBack({
				message:message
			});
		}else {
			//hcr 兼容老体系parentFrame 以及 页面返回方案
			if (this.page.getParentFrame && this.page.getParentFrame()){
				parentFrame = this.page.getParentFrame();
			}
			let data = this.getData(dataId);
			if (data instanceof TableData) {
				let insPos = insertPos || -1;
				insPos = 'string' === typeof (insPos) ? parseInt(insPos) : insPos;
				if (parentFrame && parentFrame.postMessage){
					parentFrame.postMessage({ isOk: true,  type: "mapping", data: this._getResult(data), insertPos: insPos });
				}else{
					this.page.navigateBack({ message: { isOk: true,  type: "mapping", data: this._getResult(data), insertPos: insPos } });
				}

			} else {
				if (parentFrame && parentFrame.postMessage){
					parentFrame.postMessage({ isOk: true });
				}else{
					this.page.navigateBack({
						message: {
							isOk: true
						}
					});
				}
			}
		}
	}

	/**
	 * 阻止冒泡
	 */
	stopPropagation() {
		event.stopPropagation();
	}
}
