import Config from "../../../lib/base/config";

/**

 提供一个同步请求的api ，这个api默认是同步返回 加异步请求的方案

 1.可以支持多个配置项的请求
 CacheConfig.getConfig("entry", ["portal-config-pro","icon"])
 2.	每个配置项单独作为缓存项
 configcontext.context.${currentUserName}.entry.
 3.	每个配置项单独发送请求(服务端支持原本的304 进行网络优化)
 4. 根据当前人返回符合配置原则的配置信息


 ###	缓存生效方式
 为保证绝大多数场景最优化 逻辑为 （当前场景对首次使用不优 配置变化后的更新走的是刷新浏览器 考虑用跨页面动画做过滤---看效果待定）
 1. 如果浏览器缓存中没有内容 直接返回空
 2. 发起异步请求
 3. 有结果后 存储到浏览器缓存中 缓存key ${currentUserName} + "entry" + "portal-config-pro"
 4. 如果存储缓存前 原本的缓存和准备写入的缓存md5不同 等待所有CacheLoader完毕后触发 配置 configCacheUpdateAction
 5. configCacheUpdateAction默认行为进行刷新页面 （用户可以定制为右上角弹出提醒等）


 */
import ConfigContextProcessor from 'core/framework/ConfigContextProcessor';
import {isEqual,indexOf} from 'lodash';
import userImpl from '../UserImplement';

export default class  CacheConfig{
    static async getConfigs(serviceName,configNames){
        let currentUserId = userImpl.getUserIdObservable().get() || "anonymous";
        let result =[];
        let updatePromise = [];
        let loadPromises = configNames.map(async (configName,index)=>{

            let {content:previousValue} = await ConfigContextProcessor.configContextStore.config.where({
                userId:currentUserId,
                name:configName,
                serviceName:serviceName
            }).first() || {};


            updatePromise.push((async ()=>{
                const [{content:value}]= await Config.getConfig(serviceName, configName) || [];
                if(!isEqual(previousValue,value)){
                    await ConfigContextProcessor.configContextStore.config.put({
                        id: currentUserId +  "_" + serviceName + "_" + configName,
                        userId:currentUserId,
                        name:configName,
                        serviceName:serviceName,
                        content:value
                    })
                    return true;
                }
            }));
            return previousValue;
        });

        ((async () => {
            //等待所有配置的本地缓存加载完毕
            await Promise.all(loadPromises);

            let updates =  await Promise.all(updatePromise.map((updater)=>{
                return updater();
            }));
            return updates;
        })()).then((updates)=>{
            if (indexOf(updates,true) != -1) {
                location.reload();
            }
        })
        return await Promise.all(loadPromises);
    }
}
