import Data from "./tableData";
Data.prototype[Symbol.iterator] = function(){
	var params = [];
	var index = 0;
	this.each(function(param){
		params.push(param);
	});
	return {
		next:function(){
			var done = index >= params.length?true:false;
			return {done:done,value:params[index++]};
		}
	}
};

