import RadioCheckboxBase from "../checkbox/radioCheckboxBase";

import {observable,autorun} from  "../../lib/mobx/mobx-2.6.2.umd";
export default class Radio extends RadioCheckboxBase {
    constructor(page, id, props, context){
        super(page, id, props, context);
        this.labelRadio = observable("");
        var self = this;
        autorun(() => {
        	if (self.props.$labelRadioAttrFn && (typeof self.page[self.props.$labelRadioAttrFn]==="function")){
        		var nameResult = self.page[self.props.$labelRadioAttrFn](self.context.vars);
        		if(nameResult){
        			self.labelRadio.set(nameResult);
        		}
        	}
        })
     }
    buildState(context){
   	 var state = super.buildState(context);
   	 state.labelRadio = this.labelRadio.get(); 
	 return state;
    }
   
}


Radio.EVENT_VALUE_CHANGE = "valuechange";

wx.comp = wx.comp || {};
wx.comp.Radio = Radio;

