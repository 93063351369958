import Component from "../../lib/base/component";
import {observable,toJS,autorun} from '../../lib/mobx/mobx-2.6.2.umd';

export default class Flod extends Component {
     constructor(page, id, props, context){
        super(page, id, props, context);
        this.hiden = observable("");
        if(this.props.allow == "false"){
        	this.hiden.set(false)
        }else{        	
        	this.hiden.set(this.props.hiden === "true" ? true : false);
        }
     }
     
     buildState(context){
    	 var state = super.buildState(context);
    	 state.hiden = this.hiden.get();
    	 return state;
     }
     flodClick(e){
    	 if(this.props.allow == "false")return;
		 if(this.hiden.get()){
	         this.hiden.set(false);
	       }else{
	         this.hiden.set(true);
	       }
     }
}

wx.comp = wx.comp || {};
wx.comp.Flod = Flod;

