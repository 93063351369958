/*! 
* WeX5 v3 (http://www.justep.com) 
* Copyright 2015 Justep, Inc.
* Licensed under Apache License, Version 2.0 (http://www.apache.org/licenses/LICENSE-2.0) 
*/ 

import Base64 from './base64';

function _encodeUrlItem(val) {
    return encodeURIComponent(val);
}

const DefaultAlias="__a_l_i_a_s__";

export class DbrestFilter {
	constructor(alias) {
		this.filterString = "";
        this.subQueries = [];
        this.setAlias(alias);
	}
    setAlias(alias) {
        this.tableAlias = alias || DefaultAlias;
    }
	_appendFilterString(filter) {
		if (this.filterString.length == 0) {
			this.filterString = filter;
		} else {
			this.filterString += "&" + filter;
		}
		return this;
	}
	appendFilter(field, op, value) {
        var filter = field + op + (value || "");
        if (field.indexOf(".") < 0) {
            filter = this.tableAlias + "." + filter;
        }
        return this._appendFilterString(filter)
	}
    addSubQueryWrapper(subQueryName, wrapper) {
        this.subQueries.push({"queryName": subQueryName, "wrapper": wrapper});
        return this;
    }
    encodeUrlItem(val) {
        if (Object.prototype.toString.call(val) === '[object Date]') {
            val = val.toISOString();
        }
        return _encodeUrlItem(val);
    }
	eq(field, value) {
		return this.appendFilter(field, "=eq.", this.encodeUrlItem(value));
	}
	neq(field, value) {
		return this.appendFilter(field, "=neq.", this.encodeUrlItem(value));
	}
	gt(field, value) {
		return this.appendFilter(field, "=gt.", this.encodeUrlItem(value));
	}
	gte(field, value) {
		return this.appendFilter(field, "=gte.", this.encodeUrlItem(value));
	}
	lt(field, value) {
		return this.appendFilter(field, "=lt.", this.encodeUrlItem(value));
	}
	lte(field, value) {
		return this.appendFilter(field, "=lte.", this.encodeUrlItem(value));
	}
	like(field, value) {
		return this.appendFilter(field, "=like.*", this.encodeUrlItem(value) + "*");
	}
	notLike(field, value) {
		return this.appendFilter(field, "=not.like.*", this.encodeUrlItem(value) + "*");
	}
	leftLike(field, value) {
		return this.appendFilter(field, "=like.*", this.encodeUrlItem(value));
	}
	rightLike(field, value) {
		return this.appendFilter(field, "=like.", this.encodeUrlItem(value) + "*");
	}
	isNull(field) {
		return this.appendFilter(field, "=is.null");
	}
	isNotNull(field) {
		return this.appendFilter(field, "=not.is.null");
	}
	in(field, value) {
		return this.appendFilter(field, "=in.", this.encodeUrlItem(value));
	}
	notIn(field, value) {
		return this.appendFilter(field, "=not.in.", this.encodeUrlItem(value));
	}
	between(field, value1, value2) {
		return this.appendFilter(field, "=gte.", this.encodeUrlItem(value1)).appendFilter(field, "=lte.", this.encodeUrlItem(value2));
	}
	notBetween(field, value1, value2) {
		return this._appendFilterString("Not&(&").between(field, value1, value2)._appendFilterString("&)");
	}
    and(filter) {
        return this._appendFilterString("(&" + filter._getUrlString() + "&)");
    }
    or(filter) {
        return this._appendFilterString("or&(&" + filter._getUrlString() + "&)");
    }
	_getUrlString() {
        if (this.subQueries.length > 0) {
            var queries = {};
            for (var i=0; i<this.subQueries.length; i++) {
                const subQuery = this.subQueries[i];
                queries[subQuery.queryName] = subQuery.wrapper._getQueryUrl(0, -1);
            }
            var subQueriesString = Base64.encode(JSON.stringify(queries));
            return this.filterString + "&$subquery=" + subQueriesString;
        }
		return this.filterString;
	}
	getUrlString() {
        const ret = this._getUrlString();
        return ret.replaceAll(DefaultAlias + ".", ""); 
    }
}

export class DbrestWrapper {
	
	constructor(className, pageModel) {
		this.setClassName(className);
		this.pageModel = pageModel;
		this.headers = { "Content-type" : 'application/json'};
		this.filter = new DbrestFilter(this.modelName);
        this.filter.setAlias(this.modelName);
		this._columns = "";
        this._orderBy = "";
        this._groupBy = "";
        this._having = "";
        this._join = "";
        this._streamColumn = "";
        this._streamKeyOrIndex = "";
        this._distinct = false;
        this._leftAliasUsed = undefined;
        this._currentJoinModelName = undefined;
        this._aliasMap = {};
        this._countField = "";
        this._avgField = "";
        this._minField = "";
        this._maxField = "";
        this._sumField = "";
	}
	
	setServiceName(name) {
		this.serviceName = name;
		return this;
	}

	setClassName(name) {
		this.className = name;
        var pos = name.lastIndexOf("/");
        this.modelName = name.substring(pos + 1);
        this.mainTableAlias = this.modelName;
		return this;
	}

	setMainTableAlias(name) {
		this.mainTableAlias = name;
        this.filter.setAlias(name);
		return this;
	}

	setLeftAliasUsed(name) {
		this._leftAliasUsed = name;
		return this;
	}

	useDistinct() {
		this._distinct = true;
		return this;
	}

	countField(field) {
		this._countField = field;
		return this;
	}

	avgField(field) {
		this._avgField = field;
		return this;
	}

	minField(field) {
		this._minField = field;
		return this;
	}

	maxField(field) {
		this._maxField = field;
		return this;
	}

	sumField(field) {
		this._sumField = field;
		return this;
	}

    addSubQueryWrapper(subQueryName, wrapper) {
        this.filter.addSubQueryWrapper(subQueryName, wrapper);
        return this;
    }
    
    leftJoin(table) {
        return this.joinTable(".left.", table);
    }

    rightJoin(table) {
        return this.joinTable(".right.", table);
    }

    innerJoin(table) {
        return this.joinTable(".inner.", table);
    }

    fullJoin(table) {
        return this.joinTable(".full.", table);
    }

    joinTable(joinType, table) {
        if (this.mainTableAlias.length == 0) {
            throw new Error("请先设置被关联的表的别名");
        }
        if (this._join.length > 0) {
            this._join += ",";
        }
        this._currentJoinModelName = table;
        if (this._leftAliasUsed && this._leftAliasUsed.length > 0) {
            this._join += this._aliasMap[this._leftAliasUsed] + "|";
            this._join += this._leftAliasUsed + joinType + table;
        } else {
            this._join += this.modelName + "|";
            this._join += this.mainTableAlias + joinType + table;
        }
        return this;
    }

    alias(alias) {
        this._aliasMap[alias] = this._currentJoinModelName;
        this._join += "|" + alias;
        return this;
    }
    on(onCondition) {
        if (this._join.endsWith("]")) {
            this._join = this._join.substring(0, this._join.length - 1);
            this._join += ":" + onCondition + "]";
        } else {
            this._join += "[" + onCondition + "]";
        }
        return this;
    }
    
	columns(val) {
		this._columns = val;
		return this;
	}

    stream(column, keyOrIndex) {
        this._streamColumn = column;
        this._streamKeyOrIndex = keyOrIndex;
        return this;
    }

    orderByAsc(val) {
        if (this._orderBy !== "") {
            this._orderBy += ",";
        }
        var arr = val.split(",");
        for (var i=0; i<arr.length; i++) {
            this._orderBy += arr[i] + ".asc,";
        }
        this._orderBy = this._orderBy.substring(0, this._orderBy.length - 1);
        return this;
    }
    orderByDesc(val) {
        if (this._orderBy !== "") {
            this._orderBy += ",";
        }
        var arr = val.split(",");
        for (var i=0; i<arr.length; i++) {
            this._orderBy += arr[i] + ".desc,";
        }
        this._orderBy = this._orderBy.substring(0, this._orderBy.length - 1);
        return this;
    }
    groupBy(val) {
        if (this._groupBy !== "") {
            this._groupBy += ",";
        }
        this._groupBy += val;
        return this;
    }
    having(val) {
        this._having = val;
        return this;
    }

	eq(field, value) {
		this.filter.eq(field, value);
		return this;
	}
	neq(field, value) {
		this.filter.neq(field, value);
		return this;
	}
	gt(field, value) {
		this.filter.gt(field, value);
		return this;
	}
	gte(field, value) {
		this.filter.gte(field, value);
		return this;
	}
	lt(field, value) {
		this.filter.lt(field, value);
		return this;
	}
	lte(field, value) {
		this.filter.lte(field, value);
		return this;
	}
	like(field, value) {
		this.filter.like(field, value);
		return this;
	}
	notLike(field, value) {
		this.filter.notLike(field, value);
		return this;
	}
	leftLike(field, value) {
		this.filter.leftLike(field, value);
		return this;
	}
	rightLike(field, value) {
		this.filter.rightLike(field, value);
		return this;
	}
	isNull(field, value) {
		this.filter.isNull(field, value);
		return this;
	}
	isNotNull(field, value) {
		this.filter.isNotNull(field, value);
		return this;
	}
	in(field, value) {
		this.filter.in(field, value);
		return this;
	}
	notIn(field, value) {
		this.filter.notIn(field, value);
		return this;
	}
	between(field, value1, value2) {
		this.filter.between(field, value1, value2);
		return this;
	}
	notBetween(field, value1, value2) {
		this.filter.notBetween(field, value1, value2);
		return this;
	}
	and(filter) {
		this.filter.and(filter);
		return this;
	}
	or(filter) {
		this.filter.or(filter);
		return this;
	}

    _getMark() {
        if (this._questionMark) {
            return "&";
        } else {
            this._questionMark = true;
            return "?"
        }
    }
    _getBaseUrl() {
		var url;
        var serviceName = this.serviceName || this.pageModel.getServiceName();
		if (serviceName) {
			url = "/" + serviceName;
		} else {
            url = "";
        }
		var arr = this.className.split("/");
		if (arr.length == 3 && arr[0] == "") {
			url += "/" + arr[1] + "/dbrest/" + arr[2];
        } else if (arr.length == 4 && arr[0] == "") {
			url += "/" + arr[2] + "/dbrest/" + arr[3];
		} else if (arr.length == 2) {
			url += "/" + arr[0] + "/dbrest/" + arr[1];
		} else {
			throw new Error("Invalid className: " + this.className);
		}
        return url;
    }
	_getQueryUrl(offset, limit) {
		var url = this._getBaseUrl();
		this._questionMark = false;
		if (offset != undefined && limit) {
			url += "?offset=" + offset + "&limit=" + limit;
			this._questionMark = true;
		}
        if (this._streamColumn.length > 1 && ("" + this._streamKeyOrIndex).length > 0) {
            url += this._getMark() + "$get=" + this._streamColumn + ".stream" + _encodeUrlItem("(" + this._streamKeyOrIndex + ")");
        } else if (this._countField.length > 0 || this._maxField.length > 0 || this._minField.length > 0 || this._avgField.length > 0 || this._sumField.length > 0) {
            url += this._getMark() + "select=";
            if (this._countField.length > 0) {
                url += "count(" + this._countField + "),";
            }
            if (this._avgField.length > 0) {
                url += "avg(" + this._avgField + "),";
            }
            if (this._minField.length > 0) {
                url += "min(" + this._minField + "),";
            }
            if (this._maxField.length > 0) {
                url += "max(" + this._maxField + "),";
            }
            if (this._sumField.length > 0) {
                url += "sum(" + this._sumField + "),";
            }
            url = url.substring(0, url.length - 1);
        } else if (this._columns.length > 1) {
			url += this._getMark() + "select=" + _encodeUrlItem(this._columns);
		}
        if (this._distinct == true) {
			url += this._getMark() + "distinct=true";
        }
		if (this.filter.getUrlString().length > 1) {
			url += this._getMark() + this.filter.getUrlString();
		}
        if (this._orderBy.length > 1) {
			url += this._getMark() + "order=" + _encodeUrlItem(this._orderBy);
        }
        if (this._groupBy.length > 1) {
			url += this._getMark() + "groupby=" + _encodeUrlItem(this._groupBy);
        }
        if (this._having.length > 1) {
			url += this._getMark() + "having::" + this._having;
        }
        if (this._join.length > 1) {
			url += this._getMark() + "join=" + _encodeUrlItem(this._join);
        }
		return url;
	}

	async selectAll() {
        var data = await this.pageModel.request({
            method: "GET",
            global: false,
            url: this._getQueryUrl(0, -1),
            dataType: 'json',
            async: false,
            cache: false,
        });
        return data.data;
	}
	async selectOne() {
        var data = await this.pageModel.request({
            method: "GET",
            global: false,
            url: this._getQueryUrl(0, 1),
            dataType: 'json',
            async: false,
            cache: false,
        });
        if (data && data.data && data.data.length > 0) {
            return data.data[0];
        } else {
            return undefined;
        }
	}

    _setRangeInfo(rangeInfo) {
        var range = rangeInfo.split("/");
        this.total = range[1];
        range = range[0].split("-");
        this.startNo = range[0];
        this.endNo = range[1];
    }
    getTotal() {
        return this.total;
    }
    getStartNo() {
        return this.startNo;
    }
    getEndNo() {
        return this.endNo;
    }
	async selectPage(offset, limit) {
        var data = await this.pageModel.request({
            method: "GET",
            global: false,
            url: this._getQueryUrl(offset, limit),
            dataType: 'json',
            async: false,
            cache: false,
        });
        this._setRangeInfo(data.header["content-range"]);
        return data.data;
	}

	async insert(data) {
        if (typeof(data) != "string") {
            data = JSON.stringify(data);
        }
        return await this.pageModel.request({
            method: "POST",
            global: false,
            url: this._getBaseUrl(),
            dataType: 'json',
            async: false,
            cache: false,
            data: data
        });
	}

	async upsert(data) {
        if (typeof(data) != "string") {
            data = JSON.stringify(data);
        }
        return await this.pageModel.request({
            method: "PUT",
            global: false,
            url: this._getBaseUrl() + "?pk=idCol",
            dataType: 'json',
            async: false,
            cache: false,
            data: data
        });
    }

	async delete(data) {
        if (typeof(data) != "string") {
            data = JSON.stringify(data);
        }
        return await this.pageModel.request({
            method: "DELETE",
            global: false,
            url: this._getBaseUrl() + "?$body=" + _encodeUrlItem(data),
            dataType: 'json',
            async: false,
            cache: false,
            data: data
        });
    }

	async deleteByCondition() {
        return await this.pageModel.request({
            method: "DELETE",
            global: false,
            url: this._getQueryUrl(),
            dataType: 'json',
            async: false,
            cache: false,
        });
	}

	async getStream() {
        return await this.pageModel.request({
            method: "GET",
            global: false,
            url: this._getQueryUrl(),
            dataType: 'json',
            async: false,
            cache: false,
        });
	}

}

DbrestWrapper.SubQueryPrefix = "$subquery:";


