import ReactContainer from "../reactContainer/reactContainer";
import {observable } from  "../../lib/mobx/mobx-2.6.2.umd";
import TableData from  "../tableData/tableData";


export default class Pagination extends ReactContainer {
     constructor(page, id, props, context){
        super(page, id, props, context);

	   	this.current = observable(1);
		this.pageSize = observable(20);
		this.total = observable(0);
		this.data = null;
		this.afterRefreshhandle = (event)=>{
			this.doAfterRefresh(event);
		}

        page.on("load", ()=>{
        	this.setData(this.getData());
        });
     }
     
     _getData(){
     	if (this.props.data){
     		let data = this.page.comp(this.props.data);
     		if (data) return data;
     	}
     	return null;
     }
     
     getData(){
    	 if(!this.data){
    		 this.data = this._getData();
    	 }
    	 return this.data;
     }
     
     setData(data){
		 let dataComp = this.data;
		 if(dataComp && dataComp instanceof TableData){
			 dataComp.off('afterRefresh', this.afterRefreshhandle);
		 }
		 this.data = data;
		 if(this.data && this.data instanceof TableData){
			 this.data.on('afterRefresh', this.afterRefreshhandle);
			 this.doAfterRefresh({source:this.data,limit:this.data.getLimit(),offset:this.data.getOffset()});
		 }
     }
     
     doAfterRefresh(event){
		 let options = event.options;
		 let limit = event.limit;
		 if(limit){
			 this.pageSize.set(limit);
			 let total = event.source.getTotal(options ? options.parentRow : null);
			 this.total.set(total);
			 let offset = event.offset;
			 this.current.set(offset/limit);
		 }
     }
     
     doCustomEvent(event){
    	 let detail = event && event.detail;
    	 if(detail && detail.type){
			 let dataComp = this.getData();
			 if(dataComp && dataComp instanceof TableData){
				 if(detail.type==='change'){;
					 dataComp.loadPageData(detail.page);
				 }else if(detail.type==='showSizeChange'){
					 dataComp.refreshData({limit: detail.size});
				 }
			 }
    	 }
     }
     
     buildState(context){
    	 let state = super.buildState(context);
    	 state.current = this.current.get();
    	 state.pageSize = this.pageSize.get();
    	 state.total = this.total.get();
    	 state.hideOnSinglePage = this.props.hideOnSinglePage=='true';
    	 let pageSizeOptions = this.props.pageSizeOptions || "20,40,60,100";
    	 state.pageSizeOptions = pageSizeOptions.split(",");
    	 state.showQuickJumper = this.props.showQuickJumper=='true';
    	 state.showSizeChanger = this.props.showSizeChanger=='true';
    	 state.size = this.props.size;
    	 return state;
     }
}

wx.comp = wx.comp || {};
wx.comp.Pagination = Pagination;

