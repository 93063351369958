import WrapperComponent from "../wrapper/wrapper";
export default class Gallery extends WrapperComponent{
    constructor(page, id, props, context){
        super(page, id, props, context);
        this.fileType = [".gif",".jpeg",".jpg",".png",".svg"];
		this.actionUrl = this.page.getServiceUrl(this.props.actionUrl || "/storage");
     }
    showGallery(args){
    	var rows = [];
    	var self = this;
    	let dataID = this.props.$events["-showImage"].split(".")[0];
    	let colName = this.props.$events["-showImage"].split(".")[2];
    	let data = this.context.vars[dataID];
    	data.each((item)=>{
    		args.urls =  JSON.parse(item.row[colName])[0];
    		var name = args.urls.realFileName;
			var type = name.substring(name.lastIndexOf("."),name.length);
			if(self.fileType.indexOf(type) != -1 ){    				
				let p1 = new Promise((resolve, reject) => {
					self.page.request({
						url: self.actionUrl + '/presignedGetObject',
						data: {
							objectName: args.urls.storeFileName
						},
						header: {
							'content-type': 'application/json'
						},
						success: function (res) {
							resolve(res.data);
						}
					})
					
				})
				rows.push(p1);
			}
    	})
    	if(rows.length > 0 ){ 			
			Promise.all(rows).then(result => wx.previewImage({
				current : result[args.index],
				urls: result
			}))
		}
    /*	if(args && args.urls && args.urls.indexOf("[{\"storeFileName\"") == 0){
    		args.urls = JSON.parse(args.urls);
    		for(var i = 0 ; i < args.urls.length ; i ++){
    			var name = args.urls[i].realFileName;
    			var type = name.substring(name.lastIndexOf("."),name.length);
    			if(this.fileType.indexOf(type) != -1 ){    				
    				const p1 = new Promise((resolve, reject) => {
    					self.page.request({
    						url: this.actionUrl + '/presignedGetObject',
    						data: {
    							objectName: args.urls[i].storeFileName
    						},
    						header: {
    							'content-type': 'application/json'
    						},
    						success: function (res) {
    							resolve(res.data);
    						}
    					})
    					
    				})
    				rows.push(p1);
    			}
    		}
    		if(rows.length > 0 ){ 			
    			Promise.all(rows).then(result => wx.previewImage({
    				urls: result
    			}))
    		}
    	}*/
    }
    initOperation(){
    	 super.initOperation();
	   	 this.defineOperation('showGallery', {
	   		 label : "显示画廊",
	   		 icon : '',
	   		 init : function() {},
	   		 argsDef : [{name:"urls",displayName:"图片"}],
	   		 method : function(args) {
	   			 this.owner.showGallery(args);
	   		 }
	   	 })
     }
};

wx.comp = wx.comp || {};
wx.comp.Gallery = Gallery;
