import React,{Component} from 'react';
import WxComponentBase from '../base/WxComponentBase';
import cx from "classnames"
import WxPickerDate from './WxPickerDate';
import WxPickerTime from './WxPickerTime';
import WxPickerSelector from './WxPickerSelector';
import detector from 'detector';

export default class WxPicker extends WxComponentBase{
    constructor(...args){
        super(...args);
        this.getPicker=this.getPicker.bind(this);
        this.state={
        	childHidden:true,
            //mode:this.props.mode||"selector",
            //array:this.props.range||[],
            current:(this.props.mode==="selector"||!this.props.mode)?this.props.value||0:this.props.value||"",
        }
        this.cancel=this.cancel.bind(this);
        this.confirm=this.confirm.bind(this);
        this.show=this.show.bind(this);
    }
    //确认
    confirm(e){
        this.cancel();
        var outputVal=this.refs[this.props.mode||"selector"].getValue();
        this.setState({current:outputVal});
        if(this.props.bindchange){
        	var eventObj = this.createEventObjByNode(this.refs._picker, "change", e.timeStamp);
        	eventObj.detail = {value: outputVal};
        	this.__root__.__$$callFn(this.props.bindchange, [eventObj]);
        }
    }
    //取消
    cancel(e){
        this.setState({
        	childHidden:true,
            mode:"none"
        });
    }
    //根据Mode显示对应的picker
    getPicker(){
        switch(this.props.mode||"selector"){
            case "selector":
            return (
                <WxPickerSelector {...this.props} confirmFn={this.confirm}  array={this.props.range||[]} current={this.props.value||0} ref="selector" />
            )
            case "time":
            return(
                <WxPickerTime {...this.props} className={(this.props.className||"") + " WxPickerTime"} current={this.props.value} ref="time" />
            );
            case "date":
                return(
                 <WxPickerDate fields={this.props.fields||"day"} {...this.props} className={(this.props.className||"") + " WxPickerDate"} current={this.props.value} ref="date"/>
                )
            default:
            	/*return (
            			<WxPickerSelector {...this.props} array={this.state.array} current={this.state.array.indexOf(this.props.value)} ref="selector"/>
                    )	*/
        }
    }
    show(e){
        if(this.toBoolean(this.props.disabled)) return;
    	const {value}=this.props;
    	this.setState({
    		childHidden:false,
    		current:value
    	})
    }
    

    doRender(){
    	let lineHeight = 0;
    	if(this.refs._picker){
    		 lineHeight = this.refs._picker.style.height;
    	}
    	let line = {
    			"line-height" : lineHeight ? lineHeight : "" 
    	}
		let pickerClassName = this.props.mode==="date"?"WxPickerDate ":(this.props.mode==="time"?"WxPickerTime ":"");
		
		if(detector.os.name == "ios"){
			if(this.state.childHidden){
				document.querySelector('#container').classList.remove("ios-fixed-view-popup");
			}else{
				document.querySelector('#container').classList.add("ios-fixed-view-popup");
			}
		}
    	
    	
        return(
        	<div ref="_picker" {...this.toDOMAttrs(this.props)} {...this.htmlProps}>
            <div style={{display:this.state.childHidden?"none":""}} ref="pickerContent">
                <div className={this.state.childHidden?"wx-picker-mask weui-animate-fade-out":"wx-picker-mask weui-animate-fade-in"} onClick={this.cancel}>
                </div>
                <div className={pickerClassName + (this.state.childHidden?"wx-picker weui-animate-slide-down":"wx-picker weui-animate-slide-up")}>
                    <div className="wx-picker-hd">
                        <a href="javascript:void(0)" onClick={this.cancel} className="wx-picker-action">取消</a>
                        <a href="javascript:void(0)" onClick={this.confirm} className="wx-picker-action">确定</a>
                    </div>
                    {this.getPicker()}
                </div>
            </div>
            <div onClick={this.show} style={line}>{this.props.children}</div>
            </div>

        )
    }
}
