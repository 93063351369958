import BindComponent from "../../lib/base/bindComponent";
import {untracked} from  "../../lib/mobx/mobx-2.6.2.umd";
import UUID from "../../lib/base/uuid";


export default class AssociationSelect extends BindComponent {
     constructor(page, id, props, context){
        super(page, id, props, context);
        this.dataID = props.dataID;
        this.itemsetDataID = props.itemsetDataID;
        this.singleValue = props.singleValue!='false';
		this.uuid = new UUID().toString();
		AssociationSelect.regComp(this);
     }

	 destroy() {
		 AssociationSelect.unregComp(this);
		super.destroy();
	 }
	 
	 getDataComp(){
		 return this.page.comp(this.dataID);
	 }
		
	 getItemsetDataComp(){
		 return this.page.comp(this.itemsetDataID);
	 }
		
     buildState(context){
    	 var state = super.buildState(context);
    	 return state;
     }

     getSelectDialogUrl(){
		return this.props.selectDialogUrl;
	 }
     
     showSelectDialog(evt){
    	let dataComp = this.getItemsetDataComp();
    	if(dataComp){
    		dataComp.refreshData();
    	} 
    	if ((evt.currentTarget.dataset.disabled==true) || (evt.currentTarget.dataset.disabled=="true")) return;
    	let multiSelection = !this.singleValue;
    	let option = {
    		url: this.getSelectDialogUrl(),
    		params: {
    			compid: this.uuid,
    			multiSelection: multiSelection
    		}
    	};
 		option.onClose = (event) => {
			let data = event.data || {};
			if (data.isOk){
				if(data.compid==this.uuid){
					let dataComp = this.getItemsetDataComp();
					if(dataComp){
						let rows = [];
						dataComp.each((p)=>{
							if(p.row['selected']=='1'){
								rows.push(p.row);
								if(!multiSelection) return false;
							}
						});
						this.onChange(rows);
					}
				}else{
					console.error("返回组件id不匹配");
				}
			}
		};
 		wx.navigateTo(option);
     }
     
     onChange(rows){
    	 untracked(() => {
    		 let value = null, label = '';
    		 if(this.singleValue){
    			 let row = (rows && rows.length>0)?rows[0]:{};
    			 value = row[this.props.itemValue];
    			 label = row[this.props.itemLabel]||'';
    			 //处理填充规则
    			 if (this.props.mappings && this.props.mappings.length>0){
    				 for(let i=0,len=this.props.mappings.length;i<len;i++){
    					 let m = this.props.mappings[i];
    					 this.setValue(this.getRefPath(), m.toProp, row[m.prop]);
    				 }
    			 }
    			 //处理关联的数据进行赋值
    			 /*TODO
    			 let dataComp = this.getDataComp();
    			 if(dataComp && dataComp.join){
    				 for(let i in dataComp.join){
    					 let join = dataComp.join[i];
    					 var rightTable = join['rightTable'];
    					 var rightTableAlias = join['rightTableAlias'];
    					 var joinOn = join['on'][0];//目前支持一个
    					 joins.push(_String.format("{0}.{1}.{2}.{3}.{4}", leftTable,joinOn.leftField,joinOn.fn,rightTable+(rightTableAlias?('|'+rightTableAlias):''),joinOn.rightField));
    					 this.setValue(m.path, m.prop, row[m.prop]);
    				 }
    			 }
    			 */
    		 }else{
    			 if (rows && rows.length>0){
    				 let values = [],labels=[];
    				 for(let i=0,len=this.singleValue?1:rows.length;i<len;i++){
    					 let row = rows[i];
    					 values.push(row[this.props.itemValue]);
    					 labels.push(row[this.props.itemLabel]||''); 
    				 }
    				 value = values.join(',');
    				 label = labels.join(',');
    			 }
    		 }
    		 this.doChange({value: value});//触发valueChange时间，必须先修改value
   	         if (this.props.$labelpath){
   	        	 let labelpath = this.page[this.props.$labelpath](this.context.vars);
   	        	 labelpath && this.setValue(labelpath, this.props.labelprop, label);
   	         }
    	 });
     }

}

let _comps = {};	
AssociationSelect.regComp = function(comp){
	comp && comp.uuid && (_comps[comp.uuid] = comp);
};

AssociationSelect.unregComp = function(comp){
	comp && comp.uuid && (delete _comps[comp.uuid]);
};

AssociationSelect.getComp = function(uuid){
	return _comps[uuid];
};

AssociationSelect.getDataDef = function(uuid){
	let comp = AssociationSelect.getComp(uuid);
	if(comp){
		return {
			data: comp.getItemsetDataComp(),
			labels: [{name:comp.props.itemLabel}]
		};
	}
}

wx.comp = wx.comp || {};
wx.comp.AssociationSelect = AssociationSelect;

