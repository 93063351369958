import Component from "../../lib/base/component";
import {observable } from  "../../lib/mobx/mobx-2.6.2.umd";

export default class Swipecell extends Component {
     constructor(page, id, props, context){
        super(page, id, props, context);
        this.leftWidth = props.leftWidth || 0;
        this.rightWidth = props.rightWidth || 0;
        this.leftTransform = "-100%";
   	 	this.rightTransform = "100%";
   	 	this.status = "center;"
     }
     
     buildState(context){
 		var state = super.buildState(context);
 		state.leftWidth = this.leftWidth;
 		state.rightWidth = this.rightWidth;
 		
 		state.leftTransform = this.leftTransform;
 		state.rightTransform = this.rightTransform;
 		state.centerTransform = this.centerTransform;
 		return state;
     }
     
     touchstart(event){
    	 this.startInfo = event.touches[0];
     }
     
     touchend(event){
    	 this.endInfo = event.nativeEvent.changedTouches[0];
    	 var delatX = this.endInfo.screenX - this.startInfo.screenX;
    	 var delatY = this.endInfo.screenY - this.startInfo.screenY;
    	 if(Math.abs(delatY) > 20 || Math.abs(delatX) < 100){
    		 //纵向变化过大   横向变化过小
    		 return;
    	 }else{
    		 if(delatX > 0 ){//右滑
    			 if(this.status == "center"){
    				 this.showLeft();
    			 }else {
    				 this.showCenter();
    			 }
    		 }else { //左滑
    			 if(this.status == "center"){
    				 this.showRight();
    			 }else {
    				 this.showCenter();
    				 
    			 }
    		 }
    	 }
     }
     
     showRight(){
    	 this.leftTransform = "-100%";
    	 this.rightTransform = "0";
    	 this.centerTransform = "-" + this.rightWidth + "px";;
    	 this._update(this.context);
    	 this.status = "right";
     }
     
     showLeft(){
    	 this.leftTransform = "0";
    	 this.rightTransform = "100%";
    	 this.centerTransform = this.leftWidth + "px";
    	 this._update(this.context);
    	 this.status = "left";
     }
     
     showCenter(){
    	 this.leftTransform = "-100%";
    	 this.rightTransform = "100%";
    	 this.centerTransform = "0";
    	 this._update(this.context);
    	 this.status = "center";
     }
}

wx.comp = wx.comp || {};
wx.comp.Swipecell = Swipecell;

