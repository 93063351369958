import BindComponent from "../../lib/base/bindComponent";
import QR from "./js/wxqrcode" ;

export default class Qrcode extends BindComponent {
     constructor(page, id, props, context){
        super(page, id, props, context);
     }
     buildState(context){
     	var state = super.buildState(context);
     	state.imageBase64 = this.calUrl();
     	return state;
     }
     calUrl(){
    	 var url = this.getValue();
    	 return this.generateBaser64(url);
      }
     generateBaser64(url){
    	 return url?QR.createQrCodeImg(url):''; 
     }
     
}

wx.comp = wx.comp || {};
wx.comp.Qrcode = Qrcode;
