import ReactContainer from "../reactContainer/reactContainer";
import {observable, autorun, toJS, untracked, transaction} from  "../../lib/mobx/mobx-2.6.2.umd";
import {cloneJSON} from "../../lib/base/util";


export default class Table extends ReactContainer{
     constructor(page, id, props, context){
        super(page, id, props, context);

        this.dataSource = this.props.dataSource || "";
        this.columns = observable(cloneJSON(this.props.columns));

        this.bordered = observable(this.props.bordered=="true" || false);
        this.loading = observable(this.props.loading=="true" || false);
        this.pagination = observable(this.props.pagination || "false");
        this.size = observable(this.props.size || "default");
        this.showHeader = observable(this.props.showHeader=="false" || true);
        this.scrollX = observable(this.props.scrollX);
        this.scrollY = observable(this.props.scrollY);
        this.tableLayout = observable(this.props.tableLayout);
        this.rowSelectionType = observable(this.props.rowSelectionType);
        this.idColumn = this.props.idColumn;
        this.dataComp = observable();
        
        this.selectedRowIds = null;
        	
        this.initEvents();
     }
     
     onRowClick(detail){
    	 var data = this._getData();
    	 if (data && detail && detail.row){
    		 let rowId = detail.row[data.getIdColumn()];
    		 rowId && data.to(rowId);
    	 }
     }
     
     initEvents(){
     }
     
     destroy(){
    	 super.destroy();
     }
     
     setData(data){
    	 this.dataComp.set(data);
     }

     _getData(){
    	let data = this.dataComp.get();
    	if(data) return data;
    	return super._getData();
     }
     
     getSelectedRowIds(){
    	 return this.selectedRowIds;
     }
     
     setSorter(field, order){
    	 let dataComp = this._getData();
    	 if(dataComp && field){
    		 this.orderByField && dataComp.setOrderBy(this.orderByField);
    		 dataComp.setOrderBy(field, 'ascend'===order?1:('descend'===order?0:undefined));
    		 dataComp.refreshData();
    		 this.orderByField = ['ascend','descend'].indexOf(order)>-1?field:null;
    	 }
     }
     
     getEventVars(event){
    	 let detail = event && event.detail;
    	 var result = {};
    	 result[this.index] = detail.index; 
    	 result[this.item] = detail.row;
    	 result.$item = this.itemsOB[result[this.index]];
    	 result.$data = this._getData();
    	 return result;
     }
     
     doCustomEvent(event){
    	 let detail = event && event.detail;
    	 if(detail && detail.type){
    		 if(detail.type==='rowClick'){
    			 this.onRowClick(detail);
    		 }else if(detail.type==='selectChange'){
    			 this.selectedRowIds = detail && detail.selectedRowKeys;
    		 }else if(detail.type==='filterOrSorter'){
    			 if(detail.sorter){
    				 this.setSorter(detail.sorter.field, detail.sorter.order);
    			 }
    			 return;
    		 }
    		 this.fireEvent(detail.type, event);
    	 }
     }
     
     set(props){
    	 if(props){
    		 transaction(()=>{
    			 props.hasOwnProperty("bordered") && this.bordered.set(props.bordered);
    			 props.hasOwnProperty("loading") && this.loading.set(props.loading);
    			 props.hasOwnProperty("pagination") && this.pagination.set(props.pagination);
    			 props.hasOwnProperty("size") && this.size.set(props.size);
    			 props.hasOwnProperty("showHeader") && this.showHeader.set(props.showHeader);
    			 props.hasOwnProperty("scrollX") && this.scrollX.set(props.scrollX);
    			 props.hasOwnProperty("scrollY") && this.scrollY.set(props.scrollY);
    			 props.hasOwnProperty("tableLayout") && this.tableLayout.set(props.tableLayout);
    			 props.hasOwnProperty("rowSelectionType") && this.rowSelectionType.set(props.rowSelectionType);
    			 props.hasOwnProperty("idColumn") && (this.idColumn=props.idColumn);
    			 props.hasOwnProperty("data") && (this.setData(props.data));
    			 if(props.hasOwnProperty("columns")){
    				 this.columns.clear();
    				 props.columns && props.columns.length>0 && this.columns.push.apply(this.columns, props.columns);
    			 }
    		 });
    	 }
     }
     
     addColumn(col){
    	 
     }
     
     removeColumn(col){
    	 
     }
     
     findColumn(){
    	 
     }
     
     calItemProps(vars, items){
         if ((this.props.$rowClassName || this.props.$rowSelectDisabled) && this.idColumn && items && (items.length > 0)){
        	 let result;  
        	 let newVars = this._copyVars(vars);
        	 for (let i=0; i<items.length; i++){
        		 let item = items[i];
        		 let id = item && item[this.idColumn];
        		 if(id){
        			 newVars[this.item] = item;
        			 newVars[this.index] = i;
        			 if(this.props.$rowClassName){
        				 let clz = this.page[this.props.$rowClassName](newVars);
        				 if(clz){
        					 let l = [];
        					 for(let c in clz){
        						 clz[c] && l.push(c);
        					 }
        					 if(l.length>0){
        						 result || (result={}); 
        						 result[id] || (result[id]={});
        						 result[id]['className'] = l.join(" ");
        					 }
        				 }
        			 }
        			 if(this.props.$rowSelectDisabled && this.page[this.props.$rowSelectDisabled](newVars)){
						 result || (result={}); 
						 result[id] || (result[id]={});
						 result[id]['disabled'] = true;
        			 }
        		 }
        	 }
        	 return result;
         }
      }

    buildState(context){
    	let state = super.buildState(context);

    	state.columns = cloneJSON(toJS(this.columns, true, null, true), true);
    	let rowProps = this.calItemProps(context.vars, state.items);
    	rowProps && (state.rowProps = rowProps);
    	let position = this.pagination.get(); 
    	state.bordered = this.bordered.get();
    	state.loading = this.loading.get();
    	state.pagination = (position && position!=='false' && {position}) || false;
    	state.size = this.size.get();
    	state.showHeader = this.showHeader.get();
    	let scrollX = this.scrollX.get(), scrollY = this.scrollY.get();
    	scrollX = scrollX!=='true'?scrollX:true; 
    	scrollY = scrollY!=='true'?scrollY:true; 
    	state.scroll = (scrollX || scrollY)?{x: scrollX, y: scrollY}:undefined;
    	state.tableLayout = this.tableLayout.get();
    	state.rowSelectionType = this.rowSelectionType.get();
    	state.rowSelectionFixed = this.props.rowSelectionFixed==='true';
        
    	return state;
    }
    
}



wx.comp = wx.comp || {};
wx.comp.Table = Table;
