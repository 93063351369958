import PageComponent from  '../../../../../core/framework/PageComponent'; 
import componentRenderWrapper from  '../../../../../core/render/ComponentRenderWrapper'; 
import {propWrapper,childPropWrapper,__expression,__getPopupContainer,_exRun,__exRun,__toUrl,__renderBlock,__toArraySlot,__toJSONObjectSlot,__toJSONArraySlot,__styleToJSON,_toArray,} from  '../../../../../core/utils'; 
import _WxButton from  '../../../../../components/wx/WxButton/WxButton'; 
import _WxIcon from  '../../../../../components/wx/WxIcon/WxIcon'; 
import _WxInput from  '../../../../../components/wx/WxInput/WxInput'; 
import _WxView from  '../../../../../components/wx/WxView/WxView'; 
import _WxText from  '../../../../../components/wx/WxText/WxText'; 
import React from 'react';
import {Fragment as React_Fragment} from 'react';
const Fragment =  componentRenderWrapper(React_Fragment);
import Table from './pcxSelectValue.table';const WxButton =  componentRenderWrapper(_WxButton);
const WxIcon =  componentRenderWrapper(_WxIcon);
const WxInput =  componentRenderWrapper(_WxInput);
const WxView =  componentRenderWrapper(_WxView);
const WxText =  componentRenderWrapper(_WxText);
let wxPagePathResolver = (function(currentPath){
return function(path,isCodePath){return __toUrl(currentPath,path,isCodePath)}}
)('/wxsys/comps/associationSelect/dlg/pcxSelectValue.w');
import wxPageDeclaration from './pcxSelectValue';
import wxPageStyle from './pcxSelectValue.css'; 
 var  wxPageConfig = {};		let configContext = {};
export default class __pcxSelectValuePage__ extends PageComponent {
	constructor(props,context){
		super(props,{...context,configContext});
		this.wxPagePathResolve = wxPagePathResolver;
		this.wxPageDeclaration = wxPageDeclaration();
		this.wxPageStyle = wxPageStyle;
		this.wxPageConfig = wxPageConfig;
		this.constructed();
		this.onLoad();
	}
	render(){
		let $page = this.wxPageDeclaration.$page;
	if(!$page){return <React.Fragment></React.Fragment>}		var $state = this.state.data || {data:{}};
		var cancel=$state.cancel;
		var __pageid__=$state.__pageid__;
		var pagination=$state.pagination;
		var rowSelectionFixed=$state.rowSelectionFixed;
		var loader=$state.loader;
		var columns=$state.columns;
		var show=$state.show;
		var bordered=$state.bordered;
		var toptips=$state.toptips;
		var debounce=$state.debounce;
		var compid=$state.compid;
		var weui=$state.weui;
		var __auto1=$state.__auto1;
		var loadingAfterShow=$state.loadingAfterShow;
		var text=$state.text;
		var ok=$state.ok;
		var value=$state.value;
		var table=$state.table;
		var __toptips__=$state.__toptips__;
		var msgs=$state.msgs;
		var tableLayout=$state.tableLayout;
		var showHeader=$state.showHeader;
		var length=$state.length;
		var rowSelectionType=$state.rowSelectionType;
		var scroll=$state.scroll;
		var loading=$state.loading;
		var message=$state.message;
		var icon1=$state.icon1;
		var input=$state.input;
		var hide=$state.hide;
		var size=$state.size;
		var searchBar=$state.searchBar;
		var typeClass=$state.typeClass;
		var loadingNum=$state.loadingNum;
		var page=$state.page;
		var items=$state.items;
		return (
<WxView id="page" style={__styleToJSON('')} data-compid={((_exRun('page.compid','page'))(page))} data-pageid={(__pageid__)} className={this.getModuledCss('x-page x-page-wx')}>
	
	<WxView style={__styleToJSON('z-index: 10001;')} id="__auto1" data-compid={((_exRun('__auto1.compid','__auto1'))(__auto1))} className={this.getModuledCss('weui-loader-full ' + ((_exRun('('+(__exRun(''+(__exRun('__auto1.loadingAfterShow','__auto1'))(__auto1)+'&&'+(__exRun(''+(__exRun('__auto1.loadingNum','__auto1'))(__auto1)+'<=0','__auto1'))(__auto1)+'','__auto1'))(__auto1)+')?"weui-loader-hide":""','__auto1'))(__auto1)))}>
		<WxView id="__auto2" className={this.getModuledCss('ball-scale-ripple-multiple')}>
			<WxView id="__auto3" className={this.getModuledCss('weui-loader-view')}/>
			<WxView id="__auto4" className={this.getModuledCss('weui-loader-view')}/>
			<WxView id="__auto5" className={this.getModuledCss('weui-loader-view')}/>
			<WxView id="__auto6" className={this.getModuledCss('weui-loader-view')}/>
			<WxView id="__auto7" className={this.getModuledCss('weui-loader-view')}/>
		</WxView>
	</WxView>
	<WxView id="inVisibleCompContainer"/>
	<WxView id="panel" style={__styleToJSON('')} className={this.getModuledCss('x-panel x-full')}>
		<WxView id="top" className={this.getModuledCss('x-panel-top')}>
			<WxView id="buttonGroup" className={this.getModuledCss('wx-btn-group')}/>
			<WxView id="searchBar" placeholder="查询条件" data-compid={((_exRun('searchBar.compid','searchBar'))(searchBar))} className={this.getModuledCss('weui-search-bar')}>
				<WxView id="view" className={this.getModuledCss('weui-search-bar__box')}>
					<WxIcon id="icon" size="16" type="search" className={this.getModuledCss('weui-icon-search_in-box')}/>
					<WxInput id="input" placeholder="请输入" type="text" value={((_exRun('searchBar.value','searchBar'))(searchBar))} data-compid={((_exRun('input.compid','input'))(input))} data-pageid={(__pageid__)} bindinput="dispatchBind" data-bindinput={((_exRun('searchBar.compid','searchBar'))(searchBar)) + ':onInput,' + ((_exRun('input.compid','input'))(input)) + ':onChange'} className={this.getModuledCss('weui-search-bar__input')}/>
					<WxIcon id="icon1" size="16" type="clear" hidden={((_exRun('!'+(__exRun('searchBar.value.length','searchBar'))(searchBar)+'','searchBar'))(searchBar))} data-compid={((_exRun('icon1.compid','icon1'))(icon1))} data-bindtap={((_exRun('searchBar.compid','searchBar'))(searchBar)) + ':onClear'} data-pageid={(__pageid__)} bindtap="dispatchBind" className={this.getModuledCss('weui-icon-clear')}/>
				</WxView>
				<WxText id="text" data-compid={((_exRun('text.compid','text'))(text))} data-bindtap={((_exRun('searchBar.compid','searchBar'))(searchBar)) + ':onSearch'} data-pageid={(__pageid__)} bindtap="dispatchBind" className={this.getModuledCss('weui-search-bar-btn')}>{(_exRun('text.text','text'))(text)}</WxText>
			</WxView>
		</WxView>
		<WxView id="content" style={__styleToJSON('top:48px;bottom:48px')} className={this.getModuledCss('x-panel-content')}>
				<Table id="table" label="数据列表" _created="true" dataSource="tableData.value" item="tableitem" index="tableindex" items={((_exRun('table.items','table'))(table))} idColumn="__id_" columns={((_exRun('table.columns','table'))(table))} bordered={((_exRun('table.bordered','table'))(table))} loading={((_exRun('table.loading','table'))(table))} pagination={((_exRun('table.pagination','table'))(table))} size={((_exRun('table.size','table'))(table))} showHeader={((_exRun('table.showHeader','table'))(table))} tableLayout={((_exRun('table.tableLayout','table'))(table))} rowSelectionType={((_exRun('table.rowSelectionType','table'))(table))} rowSelectionFixed={((_exRun('table.rowSelectionFixed','table'))(table))} scroll={((_exRun('table.scroll','table'))(table))} data-compid={((_exRun('table.compid','table'))(table))} data-pageid={(__pageid__)} custom={this.dispatchCompEvent.bind(this,(_exRun('table.compid','table'))(table),'Table','custom')}>
					
				</Table>
		</WxView>
		<WxView id="bottom" className={this.getModuledCss('x-panel-bottom')}>
			<WxView id="buttonGroup1" className={this.getModuledCss('wx-btn-group')}>
				<WxButton id="ok" loading={((_exRun('ok.debounce','ok'))(ok))} data-compid={((_exRun('ok.compid','ok'))(ok))} data-bindtap="onOkTap" data-pageid={(__pageid__)} bindtap="debounceDispatchBind" className={this.getModuledCss('wx-btn')}>
					<WxIcon id="icon2" size="15" type="wx-btn-icon" className={this.getModuledCss(' wx-icon')}/>
					<WxView id="view2" className={this.getModuledCss('wx-btn-label')}>{(_exRun('('+(__exRun(''+(__exRun(''+(__exRun(''+(__exRun('("确定")'))()+'===null'))()+'||'+(__exRun(''+(__exRun('("确定")'))()+'===undefined'))()+''))()+'||'+(__exRun(''+(__exRun('("确定")'))()+'===""'))()+''))()+')?"":("确定")'))()}</WxView>
				</WxButton>
				<WxButton id="cancel" loading={((_exRun('cancel.debounce','cancel'))(cancel))} data-compid={((_exRun('cancel.compid','cancel'))(cancel))} data-bindtap="$evtH_cancel_tap" data-pageid={(__pageid__)} bindtap="debounceDispatchBind" className={this.getModuledCss('wx-btn')}>
					<WxIcon id="icon3" size="15" type="wx-btn-icon" className={this.getModuledCss(' wx-icon')}/>
					<WxView id="view3" className={this.getModuledCss('wx-btn-label')}>{(_exRun('('+(__exRun(''+(__exRun(''+(__exRun(''+(__exRun('("取消")'))()+'===null'))()+'||'+(__exRun(''+(__exRun('("取消")'))()+'===undefined'))()+''))()+'||'+(__exRun(''+(__exRun('("取消")'))()+'===""'))()+''))()+')?"":("取消")'))()}</WxView>
				</WxButton>
			</WxView>
		</WxView>
	</WxView>
<WxView id="__toptips__" hidden={((_exRun('!'+(__exRun('__toptips__.show','__toptips__'))(__toptips__)+'','__toptips__'))(__toptips__))} data-compid={((_exRun('__toptips__.compid','__toptips__'))(__toptips__))} className={this.getModuledCss('wx-toptips')}>{(_toArray(((_exRun('__toptips__.msgs','__toptips__'))(__toptips__)))).map((item, index)  => (<WxView id="__auto8" key={item['_key'] || _.random(0, 1000,true)} className={this.getModuledCss('wx-toptips-item ' + ((_exRun('item.typeClass','item'))(item)) + ' ' + ((_exRun('item.show?"wx-toptips--show":""','item'))(item)))}>{(_exRun('item.message','item'))(item)}</WxView>))}
</WxView></WxView>
		);
	}
}
