import Component from "../../lib/base/component";
import ClipboardImpl from "./ClipboardImpl";
export default class Clipboard extends Component{
    constructor(page, id, props, context){
        super(page, id, props, context);
		this.impl = new ClipboardImpl(page);
     }
    
    setClipboardData(data){
		return this.impl.setClipboardData(data,(eventName,data)=>{
			data.source = this;
			this.fireEvent(eventName,data);
		})
    }

    getClipboardData(){
		return this.impl.getClipboardData((eventName,data)=>{
			data.source = this;
			this.fireEvent(eventName,data);
		})
    }
    
    initOperation(){
    	 super.initOperation();
	   	 this.defineOperation('setClipboardData', {
	   		 label : "设置剪切板",
	   		 icon : '',
	   		 init : function() {},
	   		 argsDef : [{
 				name : 'data',
				displayName : '值',
				required : true
			}],
	   		 method : function(args) {
	   			 return this.owner.setClipboardData(args.data);
	   		 }
	   	 });
	   	 this.defineOperation('getClipboardData', {
	   		 label : "获取剪切板",
	   		 icon : '',
	   		 init : function() {},
	   		 argsDef : [],
	   		 method : function(args) {
	   			 return this.owner.getClipboardData();
	   		 }
	   	 });
     }
};

wx.comp = wx.comp || {};
wx.comp.Clipboard = Clipboard;
