import BindComponent from "../../lib/base/bindComponent";
import {observable,autorun} from  "../../lib/mobx/mobx-2.6.2.umd";

export default class Slider extends BindComponent {
     constructor(page, id, props, context){
        super(page, id, props, context);
        this.min = observable("");
        this.max = observable("");
        var self = this;
        autorun(() => {
        	if (self.props.$minAttrFn && (typeof self.page[self.props.$minAttrFn]==="function")){
        		var minResult = self.page[self.props.$minAttrFn](self.context.vars);
        		console.log(minResult);
        		if(!isNaN(minResult)){
        			self.min.set(minResult*1);
        		}
        	}
        	if (self.props.$maxAttrFn && (typeof self.page[self.props.$maxAttrFn]==="function")){
        		var maxResult = self.page[self.props.$maxAttrFn](self.context.vars);
        		console.log(maxResult);
        		if(!isNaN(maxResult)){
        			self.max.set(maxResult*1);
        		}
        	}
        });
     }
     buildState(context){
    	 var state = super.buildState(context);
    	 state.min = this.min.get(); 
      	 state.max = this.max.get();
    	 return state;
     }
}

Slider.EVENT_VALUE_CHANGE = "valuechange";

wx.comp = wx.comp || {};
wx.comp.Slider = Slider;

