import {observable, autorun, toJS, untracked} from  "../../lib/mobx/mobx-2.6.2.umd";
import Component from "../../lib/base/component";
//TODO: autoplay为true时, 不支持setCurrent方法
export default class Swiper extends Component {
    constructor(page, id, props, context){
        super(page, id, props, context);
        this.current = props.current || 0;
    }
    buildState(context){
		var state = super.buildState(context);
		if (!this.props.autoplay){
			state.current = this.current;
		}
		return state;
    }
    
    setCurrent(current){
    	if (this.props.autoplay){
    		//自动播放时，不允许设置序号
    		wx.showToast({title: '不允许设置序号！'});
    	}else{
        	current = current || 0;
        	if (isNaN(current*1)) current = 0;
        	if (current < 0) current = 0;
        	if (this.current != current){
        		this.current = current;
        		this.fireEvent(Swiper.EVENT_INDEX_CHANGED, {current: current, source: this});
        		this._update(this.context);
        	}
    	}
    }
    
    onIndexChanged(evt){
    	if (this.props.autoplay){
    		this.fireEvent(Swiper.EVENT_INDEX_CHANGED, {current: evt.detail.current, source: this});
    	}else{
    		this.setCurrent(evt.detail.current);	
    	}
    }
    
    initOperation(){
    	super.initOperation();
	   	this.defineOperation('setCurrent', {
	   		 label : "设置当前序号",
	   		 icon : '',
	   		 init : function() {},
	   		 argsDef : [ {
	   			 name : 'current',
	   			 displayName : "序号"
	   		 }],
	   		 method : function(args) {
	   			 this.owner.setCurrent(args.current);
	   		 }
	   	 });
    }
}

Swiper.EVENT_INDEX_CHANGED = "indexchanged";

wx.comp = wx.comp || {};
wx.comp.Swiper = Swiper;
