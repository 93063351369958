import Component from "../../lib/base/component";
import {observable,toJS,autorun, untracked} from '../../lib/mobx/mobx-2.6.2.umd';

export default class RichText extends Component{
    constructor(page, id, props, context){
        super(page, id, props, context);
        this.baseUrl = wx.App.baseUrl || "";
        this.text = observable("");
        autorun(() => {
        	if (this.props.$richtextFn && (typeof this.page[this.props.$richtextFn]==="function")){
        		var textResult = this.page[this.props.$richtextFn](this.context.vars);
        		untracked(() => {
            		if(textResult && typeof textResult ==="string"){
            			//textResult = "<p><img src=\"/storage/getObject?objectName=C7CC1F5EA4200001155B1E2012C0Ffff20\"/><img src=\"/storage/getObject?objectName=C7CC1F5EA4200001155B1E2012C0F720\"/></p>"
            			var imgReg = /<img.*?(?:>|\/>)/gi;
            			//匹配src属性
            			var srcReg = /src=[\'\"]?([^\'\"]*)[\'\"]?/i;
            			var arr = textResult.match(imgReg)||[];
            			for (var i = 0; i < arr.length; i++) {
            				var src = arr[i].match(srcReg);
            				if (src && src[0] && src[1] && src[1].indexOf("/storage")==0) {
            					textResult = textResult.replace(src[1],this.baseUrl+src[1]);
            				}
            			}
            			this.text.set(textResult);
            		}else{
            			this.text.set(textResult);
            		}
        		});
        	}
        })	      
    }
    
    buildState(context){
      	 var state = super.buildState(context);
      	 state.text = this.text.get(); 
      	 return state;
    }
    
};
wx.comp = wx.comp || {};
wx.comp.RichText = RichText;
