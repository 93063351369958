import component from "../../lib/base/component";

export default class ActionSheet extends component {
    constructor(page, id, props, context) {
        super(page, id, props, context);
    }

    show(evt) {
        var ret = wx.showActionSheet({
            itemList: this.props.labels,
            itemColor : this.props.itemColor || "#000"
        });
        var self = this;
        ret.then(function(res){
            if (!res.cancel) {
            	self.fireEvent("itemSelect" + res.tapIndex,{source: self});
            }
        },()=>{});
        return ret;
    }
    
    initOperation() {
        super.initOperation();
        this.defineOperation('show', {
            label: "显示",
            icon: 'glyphicon glyphicon-floppy-disk',
            argsDef: [],
            method: function(args) {
                return this.owner.show();
            }
        });
    }
}

wx.comp = wx.comp || {};
wx.comp.ActionSheet = ActionSheet;
