
import JSBI from "./jsbi-umd";

var DIGITS = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ".split('');
//将长整型数值转换为指定的进制数（最大支持62进制，字母数字已经用尽）
function to62(i){
	var negative = i<0;
	var qutient = negative ? -i : i;
	var buf = [];
	do {
	    var mod = qutient % DIGITS.length;
	    buf.unshift(DIGITS[mod]);
	    qutient = (qutient - mod) / DIGITS.length;
	} while (qutient);
	
	var ret = (negative?"-":"") + buf.join("");
	return ret;
}

function doDigits(val, hi) {
	//var number = (BigInt(hi) | (BigInt(val) & BigInt(hi-1))).toString() * 1;
	var number = JSBI.bitwiseOr(JSBI.BigInt(hi), JSBI.bitwiseAnd(JSBI.BigInt(val), JSBI.BigInt(hi-1))).toString() * 1;
	return to62(number).substring(1);
}

export default class UUID{
	constructor(shortUUID){
		if (shortUUID){
			this.id = UUID.createShortUUID();
		}else{
			this.id = UUID.createUUID();
		}
	}
	
	valueOf(){
		return this.id;
	}
	
	toString(){
		return this.id;
	}
	
	static createShortUUID(uuid){
		var temp = uuid || UUID.createUUID().toString();
		temp = temp.replace(/-/g, "").toLocaleLowerCase();
		if (temp.length != 32) throw new Error("uuid必须是32位");
		var buf = "";
		buf += doDigits(parseInt(temp.substring(0, 8), 16), Math.pow(2, 4*8)); //前32位
		buf += doDigits(parseInt(temp.substring(8, 12), 16), Math.pow(2, 4*4)); //次16位 
		buf += doDigits(parseInt(temp.substring(12, 16), 16), Math.pow(2, 4*4)); //次16位
		buf += doDigits(parseInt(temp.substring(16, 20), 16), Math.pow(2, 4*4)); //次16位
		buf += doDigits(parseInt(temp.substring(20, 32), 16), Math.pow(2, 4*12)); //后48位
		return buf;
	}
	
	static createUUID(){
		return this.doCreateUUID(false);
	}
	
	static createLongUUID(){
		return this.doCreateUUID(true);
	}
	
	static doCreateUUID(isLong){
		var dg = new Date(1582, 10, 15, 0, 0, 0, 0);
		var dc = new Date();
		var t = dc.getTime() - dg.getTime();
		
		var tl = this._getIntegerBits(t, 0, 31);
		var tm = this._getIntegerBits(t, 32, 47);
		var thv = this._getIntegerBits(t, 48, 59) + '1'; // version 1,
		// security
		// version is 2
		var csar = this._getIntegerBits(this._rand(4095), 0, 7);
		var csl = this._getIntegerBits(this._rand(4095), 0, 7);

		var n = this._getIntegerBits(this._rand(8191), 0, 7)
				+ this._getIntegerBits(this._rand(8191), 8, 15) + this._getIntegerBits(this._rand(8191), 0, 7)
				+ this._getIntegerBits(this._rand(8191), 8, 15) + this._getIntegerBits(this._rand(8191), 0, 15); // this
		if (isLong){
			var h = "-";
			return tl + h + tm + h + thv + h + csar + csl + h + n;
		}else{
			return tl + tm + thv + csar + csl + n;
		}			
	}


	static _getIntegerBits(val, start, end) {
		var base16 = this._returnBase(val, 16);
		var quadArray = [];
		var quadString = '';
		var i = 0;
		for (i = 0; i < base16.length; i++) {
			quadArray.push(base16.substring(i, i + 1));
		}
		for (i = Math.floor(start / 4); i <= Math.floor(end / 4); i++) {
			if (!quadArray[i] || quadArray[i] === '')
				quadString += '0';
			else
				quadString += quadArray[i];
		}
		return quadString;
	}

	static _returnBase(number, base) {
		return (number).toString(base).toUpperCase();
	}

	static _rand(max) {
		return Math.floor(Math.random() * (max + 1));
	}
}


