import RestData from "../restData/restData";
import {isArray} from "../../lib/base/util";
import UUID from "../../lib/base/uuid";

export default class ViewData extends RestData {
	constructor(page, id, props, context){
		super(page, id, props, context);
	}

	getClass(){
		return ViewData;
	}
	
	init(){
		super.init();
	}
	
	_initDefinition() {
		super._initDefinition();
		//产生idCol相关定义
		if(!this.props.options.idColumn){
			this.props.options.idColumn = 'idCol';
			this.props.schema.items.props['idCol'] = {type:'String',label:'ID列',define:'EXPRESS'};
		}
	}
	
	saveData(option) {
		this.showError({message: this.page.i18n("不支持保存")});
	}
	
	deleteData(option) {
		this.showError({message:  this.page.i18n("不支持删除")});
	}
	
	beforeLoadData(data, options){
		if(isArray(data)){
			let idColumn = this.getIdColumn();
			for (let i=0; i<data.length; i++){
				 let row = data[i];
				 if(!row[idColumn]){
						let uuid = new UUID().toString();
						row[idColumn] = uuid.replace(/-/g,'').toUpperCase();
				 }
			 }
		}
		return super.beforeLoadData(data, options);
	}

}

wx.comp = wx.comp || {};
wx.comp.ViewData = ViewData
