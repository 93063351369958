import Component from "../../lib/base/component";
import GeoLocationImpl from "./GeoLocationImpl";
export default class Geolocation extends Component {
	constructor(page, id, props, context) {
		super(page, id, props, context);
		this.impl = new GeoLocationImpl(page);
	}

	dispatchEventFn =(eventName, data) =>{
		data.source = this;
		this.fireEvent(eventName, data);
	}

	getLocation(args) {
		return this.impl.getLocation(args, this.dispatchEventFn);
	}

	chooseLocation(args) {
		return this.impl.chooseLocation(args, this.dispatchEventFn);
	}

	openLocation(args) {
		return this.impl.openLocation(args)
	}

	initOperation() {
		super.initOperation();
		this.defineOperation('getLocation', {
			label: "获取当前的地理位置、速度",
			icon: '',
			init: function () { },
			argsDef: [],
			method: function (args) {
				return this.owner.getLocation(args);
			}
		});

		this.defineOperation('chooseLocation', {
			label: '打开地图选择位置',
			argsDef: [],
			method: function (args) {
				return this.owner.chooseLocation(args);
			}
		});
		this.defineOperation('openLocation', {
			label: '使用微信内置地图查看位置',
			argsDef: [],
			method: function (args) {
				return this.owner.openLocation(args);
			}
		});
	}
};

wx.comp = wx.comp || {};
wx.comp.Geolocation = Geolocation;
