/*! 
 * WeX5 v3 (http://www.justep.com) 
 * Copyright 2015 Justep, Inc.
 * Licensed under Apache License, Version 2.0 (http://www.apache.org/licenses/LICENSE-2.0) 
 */

import Component from "../../lib/base/component";
import {isArray, isObject} from "../../lib/base/util";
import _Date from "../../lib/base/date";
import Operational from "../../lib/base/operational";

export default class ServiceRequest extends Component {
    constructor(page, id, props, context) {
        super(page, id, props, context);
        this.microServiceName = props.microServiceName;
    }

    _getParamValue(def,userParams) {
    	if(userParams && userParams.hasOwnProperty(def.name)){
    		return userParams[def.name];
    	}else{
    		if (!this.props.$executeParamsBinding)
    			return this.props.params[def.name];
    		var items = this.page[this.props.$executeParamsBinding](this.context.vars) || {};
    		return items[def.name];
    	}
    }
    
    _processParams(ret,userParams) {
        var urlData = {};
        var params = {};
        var urlParams = {};
        if (this.props.defParams) {
            for (let k in this.props.defParams) {
                let def = this.props.defParams[k];
                var v = this._getParamValue(def,userParams);
                if (v !== null && v !== undefined) {
                	//时间统一按ISO格式转换
                	if (v instanceof Date){
                		var type = ((def && def.dataType)||"").toLowerCase();
                		if(type==='date') v = _Date.toString(v,_Date.STANDART_FORMAT_SHOT);
                		else if(type==='time') v = _Date.fromString(v,_Date.STANDART_TIME_FORMAT);
                		else v = v.toISOString();
        			}
                    if (def.kind === 'PathVariable') {
                        urlData[k] = v;
                    }else if(def.kind === 'RequestHeader'){
                    	ret.header[k] = v;
					} else if (def.kind === 'RequestParam') {
                    	urlParams[k] = v;
                    } else if (def.kind === 'RequestBody')
                        params = v;
                } else if (def.required) {
                    throw new Error('服务' + this.props.service + '的参数' + k + '是必须的');
                }
            }
        }
        // 处理参数在Url部分
        var actionUrl = this.props.actionUrl;
        if (actionUrl && urlData) {
            for (var n in urlData) {
                actionUrl = actionUrl.replace('{' + n + '}', urlData[n]);
            }
        }
        if(urlParams && actionUrl){
			var hasP = actionUrl.indexOf('?')>=0;
			var urlParam = "";
			var processData = 'true'===this.props.processData;
			for(let n in urlParams){
				urlParam += "&" + (processData?encodeURIComponent(n):n) + "="+ (processData?encodeURIComponent(urlParams[n]):urlParams[n]);
			}
			if(urlParam)
				actionUrl = actionUrl + (hasP?'&':'?') + urlParam.substring(1);
		}
        
        return {
            actionUrl: actionUrl,
            params: params
        };
    }
    _processCfg(params) {
        var ret = {
        	method: this.props.type,
            dataType: this.props.dataType,
            processData: this.props.processData,
        };
        ret.header = this.props.header || {};
        if (this.props.contentType)
            ret.header['content-type'] = this.props.contentType;
        var p = this._processParams(ret, params);
        var actionUrl = p.actionUrl;
        ret.url = this.page.getServiceUrl(actionUrl, this.microServiceName);
        ret.data = p.params;
        //支持批
        params && params.batch && (ret.batch=params.batch);

        return ret;
    }
    
    send(params,serviceDataOptions) {
        let options = this._processCfg(params);
		let dfd = {};
		let promise = new Promise(function(resolve, reject) {
			dfd.resolve = resolve;
			dfd.reject = reject;
		});
		//防止Uncaught (in promise)异常
		promise.catch(()=>{});
		
        let self = this;
        options.fail = function(result) {
        	result = result || {};
        	result.source = self;
        	result.serviceDataOptions = serviceDataOptions;
        	dfd.reject(result);
            self.fireEvent('error', result);
        };
        options.success = function(result) {
        	result = result || {};
        	result.source = self;
        	result.serviceDataOptions = serviceDataOptions;

            if(result?.data?.emit && result?.data?.on){
                result.data.on('message',(message)=>{
                    self.fireEvent('message', {
                        source:self,
                        message:message
                    });
                })
                result.data.on('done',(data)=>{
                    let successResult = {...result};
                    successResult.data = data;
                    dfd.resolve(successResult);
                    self.fireEvent('success', successResult);
                })
            }else {
                dfd.resolve(result);
                self.fireEvent('success', result);
            }
        };
        self.fireEvent('beforeRequest', 
            options
        );
        self.page.request(options);
        return promise;
    }

    initOperation() {
        super.initOperation();
        this.defineOperation('sendServiceRequest', {
            label: "发送服务请求",
            icon: '',
            init: function() {},
            argsDef: [{
				name : 'params',
				displayName : '参数'
			}],
            method: function(args,ctx) {
            	let batch = Operational.getBatch(ctx);
            	let params = args.params || {};
            	batch && (params.batch=batch);
                let ret = this.owner.send(params);
                return !batch?ret:null;
            }
        });
    }
    destroy() {
        super.destroy();
    }
}
wx.comp = wx.comp || {};
wx.comp.ServiceRequest = ServiceRequest;
