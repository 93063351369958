import fileApi from "../../../lib/base/fileApi";

var minioUtil = {
	getFileUrl : function(actionUrl,storeFileName){
		return new Promise(function(resolve, reject) {
			resolve(fileApi.getFileUrl({
				actionUrl:actionUrl,
				storeFileName:storeFileName
			}));
		});
	}
};

export default minioUtil;
