import Component from "../../lib/base/component";
//包装基础的react组件
export default class VueWrapper extends Component {
     constructor(page, id, props, context){
        super(page, id, props, context);
     }
}

wx.comp = wx.comp || {};
wx.comp.VueWrapper = VueWrapper;

