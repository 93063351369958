export default {
    //微信小程序下的mock api
    encode:function(url){
        return url;
    },

    decode:function(url){
        return url;
    }
}
