import React, {Component} from 'react'
import addEventListener from 'rc-util/lib/Dom/addEventListener';
//import WxComponentBase from "../base/WxComponentBase";

export default class WxPickerRing extends Component {
    constructor(...args) {
        super(...args);
        this.touch=false;
        this.state = {
            translateY: 0
        }
        this.setCurrent = this.setCurrent.bind(this);
        this.offsetHeight = 34;
        // 兼容移动端和手机端
        /*this.events="ontouchstart" in window ?{
            start:"touchstart",
            move:"touchmove",
            end:"touchend",
            leave:"touchcancel"
        }:{
            start:"mousedown",
            move:"mousemove",
            end:"mouseup",
            leave:"mouseleave"
        }*/
    }
    componentDidMount() {
        this.setCurrent(this.props.current);
    }
    setCurrent(current) {
        this.setState({
          translateY: (3-current) * this.offsetHeight
        });
    }
    componentWillReceiveProps(nextProps){
        nextProps.current!=this.props.current && this.setCurrent(nextProps.current);
    }
    
    /**
     * 兼容移动端和PC端的事件库
     * */
    //添加事件
    addEvents(type){
		let node = this.refs.ring;
    	if(type==="mouse"){
    		this.onMouseMoveListener=addEventListener(node,"mousemove",this.mouseMove.bind(this));
    		this.onMouseUpListener=addEventListener(node,"mouseup",this.mouseUp.bind(this));
    	}else if(type==="touch"){
    		this.onTouchMoveListener=addEventListener(node,"touchmove",this.touchMove.bind(this));
    		this.onTouchEndListener=addEventListener(node,"touchend",this.touchEnd.bind(this));
    	}
    }
    
    //移除事件：
    removeEvents(type){
    	if(type==="mouse"){
    		this.onMouseMoveListener.remove();
    		this.onMouseUpListener.remove();
    	}else if(type==="touch"){
    		this.onTouchMoveListener.remove();
    		this.onTouchEndListener.remove();
    	}
    }
    //mouse Event
    onMouseDown(e){
    	if(e.button!==0){return;}
    	this.startY =e.pageY;
        this.lastTranslateY = this.state.translateY || 0;
        this.touch = true;
        this.addEvents("mouse");
		//console.log("------onMouseDown");
    }
    
    //touch Event
    onTouchStart(e){
    	this.startY=e.touches[0].pageY;
    	this.lastTranslateY = this.state.translateY || 0;
        this.touch = true;
        this.offsetHeight = this.getItemHeight();
        this.addEvents("touch");
    }

    getItemHeight(){
        let ret = 34;
        let node = this.refs.ring;
        if(node){
            let content = node.querySelector('.wx-picker-content');
            if(content){
                let indicator = content.querySelector('.wx-picker-item')
                if(indicator){
                    /* 滑动前获取项高度，以真实高度为计算滚动依据 */
                    let computedStyle = getComputedStyle(indicator);
                    ret = parseFloat(computedStyle['height']||0, 10)
                        +parseFloat(computedStyle['padding-top']||0, 10)
                        +parseFloat(computedStyle['padding-bottom']||0, 10);
                    //console.log("------------wx-picker-item Height:",ret);
                }
            }
        }
        return ret;
    }
    
   mouseMove(e) {
	    e.stopPropagation();
	   	e.preventDefault();
        if(!this.touch){return;}
            let offset = e.pageY - this.startY + this.lastTranslateY;
            this.onMove(e,offset);
    }
   touchMove(e){
	   if(!this.touch){return;}
	   let offset=e.touches[0].pageY- this.startY + this.lastTranslateY;
	   this.onMove(e,offset);
   }
   //lib moveEvents
    onMove(e,offset){
    	e.stopPropagation();
    	e.preventDefault();
    	let position=offset;
    	position = Math.max((3-this.props.array.length +1) * this.offsetHeight, position);
    	position = Math.min(3*this.offsetHeight, position);
        this.setState({translateY: position});
    }
    
    mouseUp(e){
    	this.removeEvents("mouse");
    	this.end(e);
    }
    touchEnd(e){
    	this.removeEvents("touch");
        this.end(e);
    }
    //lib endEvents
    end(e){
    	this.touch = false,
        this.startY = 0,
        this.lastTranslateY = 0;
        let offset = this.state.translateY;
        offset = Math.max((3 - this.props.array.length + 1) * this.offsetHeight, offset),
        offset = Math.min(3 * this.offsetHeight, offset);
        const n = 3-Math.round(offset / this.offsetHeight);
        isNaN(n) || (this.current = n);
        offset = (3-this.current) * this.offsetHeight;
        this.setState({translateY: offset});
        setTimeout(()=>{
        	this.props.onPickerSelect && this.props.onPickerSelect(this.current)
        })
    }
    render() {
        const contentStyle = {
            transform: `translate3d(0,${this.state.translateY}px,0)`,
            transition: "all .1s ease"
        };
        const itemList = this.props.array.map((item, index) => {
			//console.log("----------",item,index,'this.props.current',this.props.current,'this.current',this.current)
			const clz = this.props.current==index?"wx-picker-item wx-picker-item-active":"wx-picker-item";
                return (
                    <div className={clz} value={index} key={index}>{item}</div>
                )
            });
			
								
            
			
        return (
            <div className="wx-picker-group"
				ref="ring"
                onTouchStart={this.onTouchStart.bind(this)}
                onMouseDown={this.onMouseDown.bind(this)}
            	style={{display:this.props.hidden?"none":""}}
            	>
			    <div className="wx-picker-mask2">
                </div>
			
                <div className="wx-picker-indicator">
                </div>
                <div className="wx-picker-content" style={contentStyle}>{itemList}</div>
            </div>
        )
    }
}