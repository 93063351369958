import {observable, autorun, toJS, untracked} from  "../../lib/mobx/mobx-2.6.2.umd";
import Component from "../../lib/base/component";

export default class ScrollView extends Component {
    constructor(page, id, props, context){
        super(page, id, props, context);
        this.reachBottom = observable(false);
        let self = this;
        this.on("scrolltobottom" , function(data){
        	self.reachBottom.set(data.hasMore === true?false:true);
        });
    }
    
    buildState(context) {
        let state = super.buildState(context);
        state.reachBottom = this.reachBottom.get();
        return state;
    }
    
     onLower(evt){ 
         this.fireEvent(ScrollView.EVENT_TOLOWER , {source: this, originalEvent: evt});
     }
     
     onUpper(evt){
         this.fireEvent(ScrollView.EVENT_TOUPPER, {source: this, originalEvent: evt});
     }
     
     onScroll(evt){
    	 this.fireEvent(ScrollView.EVENT_SCROLLING, {source: this, originalEvent: evt});
     }
     
}

ScrollView.EVENT_TOLOWER = "tolower";
ScrollView.EVENT_TOUPPER = "toupper";
ScrollView.EVENT_SCROLLING = "scrolling";

wx.comp = wx.comp || {};
wx.comp.ScrollView = ScrollView;
