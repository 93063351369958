import Component from "../../lib/base/component";
import minioUtil from "../image/util/minioUtil";
import {observable,toJS,autorun, untracked} from '../../lib/mobx/mobx-2.6.2.umd';
export default class Video extends Component{

	get videoCtx() {
		return wx.createVideoContext(this.id);
	}
    constructor(page, id, props, context){
        super(page, id, props, context);
        this.id = id;
        this.fileType = [".avi",".rmvb",".rm",".asf",".divx",".mpg",".mpeg",".mpe",".wmv",".mp4",".mkv",".vob",".ogg"];
        this.posterFileType = [".gif",".jpeg",".jpg",".png",".svg"];
		this.actionUrl = this.page.getServiceUrl(this.props.actionUrl || "/storage");
        if(this.props.src){
        	var path = "";
        	if(this.props.src.indexOf("$model/UI2")== 0 ){
        		path = this.props.src.replace("$model/UI2","$UI");
        		this.url = observable(this.page.getUIUrl(path));
        	}else if (this.props.src.indexOf("$UI")== 0){
        		path = this.props.src;
        		this.url =  observable(this.page.getUIUrl(path));
        	}else{
        		path = this.props.src;
        		this.url =  observable(path);
        	}
        	this.staticUrl = this.url.get();
        }else{        	
        	this.url = observable("");
        }
        this.poster = observable("");
        this.duration = observable("");
        this.danmulist = observable("");
        this.statics = (!this.props.statics || this.props.statics == "false") ? false : true;
        var self = this;
        autorun(() => {
        	if (self.props.$danmulistFn && (typeof self.page[self.props.$danmulistFn]==="function")){
        		var danmulistResult = self.page[self.props.$danmulistFn](self.context.vars);
        		let text = self.props.text.data;
        		let color = self.props.color.data;
        		let time = self.props.time.data;
        		 untracked(() => { 
        			 if(danmulistResult){
        				 try {	
        					 let danmuData = danmulistResult.$data;
        					 var danmuArr = [];
        					 danmuData.each(function(item){
        						 var obj = {}
        						 obj.text = item.row[text];
        						 obj.color = item.row[color];
        						 obj.time = item.row[time];
        						 danmuArr.push(obj);
        					 });
        					 console.log(danmuArr);
        					 self.danmulist.set(danmuArr);
        				 } catch (e) {
        					 // TODO: handle exception
        				 }
        			 }
        		 })
        	}
        	if (self.props.$durationFn && (typeof self.page[self.props.$durationFn]==="function")){
        		var durationResult = self.page[self.props.$durationFn](self.context.vars);
        		 untracked(() => {        			 
        			 if(durationResult){
        				 self.duration.set(durationResult);
        			 }
        		 })
        	}
         if (self.props.$posterFn && (typeof self.page[self.props.$posterFn]==="function")){
     		 var posterResult = self.page[self.props.$posterFn](self.context.vars);
     		 untracked(() => {     			 
     			 if(posterResult && posterResult.indexOf("[{\"storeFileName\"") == 0){
     				 posterResult = JSON.parse(posterResult);
     				 for(var i = 0 ; i < posterResult.length ; i ++){
     					 var posterName = posterResult[i].realFileName;
     					 var posterType = posterName.substring(posterName.lastIndexOf("."),posterName.length).toLocaleLowerCase();
     					 var posterStoreFileName = posterResult[i].storeFileName;
     					 if(this.posterFileType.indexOf(posterType) != -1){
     						 if(!self.statics && posterStoreFileName.indexOf("anoy_") == 0){
     							 minioUtil.getFileUrl(this.actionUrl,posterStoreFileName).then(function(data){
     								 self.poster.set(data);
     							 })
     						 }else{     	    					
     							 self.page.request({
     								 url: this.actionUrl + '/presignedGetObject',
     								 data: {
     									 objectName: posterStoreFileName
     								 },
     								 header: {
     									 'content-type': 'application/json'
     								 },
     								 success: function (res) {
     									 self.poster.set(res.data);
     								 }
     							 })
     						 }
     						 break;
     					 }
     				 }
     			 }else{
     				 self.poster.set(posterResult);
     			 }
     		 })
         }
    	 if (self.props.$urlFn && (typeof self.page[self.props.$urlFn]==="function")){
     		 var result = self.page[self.props.$urlFn](self.context.vars);
     		 untracked(() => {     			 
     			 if(result && result.indexOf("[{\"storeFileName\"") == 0){
     				 result = JSON.parse(result);
     				 for(var i = 0 ; i < result.length ; i ++){
     					 var name = result[i].realFileName;
     					 var type = name.substring(name.lastIndexOf("."),name.length).toLocaleLowerCase();
     					 var storeFileName = result[i].storeFileName;
     					 if(this.fileType.indexOf(type) != -1){
     						 if(!self.statics && storeFileName.indexOf("anoy_") == 0){
     							 minioUtil.getFileUrl(this.actionUrl,storeFileName).then(function(data){
     								 self.url.set(data);
     							 })
     						 }else{     	    					
     							 self.page.request({
     								 url: this.actionUrl + '/presignedGetObject',
     								 data: {
     									 objectName: result[i].storeFileName
     								 },
     								 header: {
     									 'content-type': 'application/json'
     								 },
     								 success: function (res) {
     									 self.url.set(res.data);
     								 }
     							 })
     						 }
     						 break;
     					 }
     				 }
     			 }else{
     				 self.url.set(result);
     			 }
     		 })
     	 	}
        });
     }
    
    buildState(context){
   	 var state = super.buildState(context);
   	 state.url = this.url.get();
   	 state.poster = this.poster.get();
   	 state.duration = this.duration.get();
   	 state.danmulist = this.danmulist.get();
   	 return state;
    }
    
     play(){
    	 setTimeout(()=>{
    		 this.videoCtx.play();
    	 },500);
     }
     
     pause(){
    	 this.videoCtx.pause();
     }
     
     seek(position){
    	 this.videoCtx.seek((position || 0)*1);
     }
     getRandomColor() {
         let rgb = []
         for (let i = 0; i < 3; ++i) {
           let color = Math.floor(Math.random() * 256).toString(16)
           color = color.length == 1 ? '0' + color : color
           rgb.push(color)
         }
         return '#' + rgb.join('')
       }

       sendDanmu(text, color){
  		 if (text){
     	     this.videoCtx.sendDanmu({text: text || "", color: this.getRandomColor()});
  		 }
       }
     
     playbackRate(rate){
    	 this.videoCtx.playbackRate((rate || 1) * 1);
     }
     
     requestFullScreen(){
   	 	this.videoCtx.requestFullScreen();
   	 }
   	 
     exitFullScreen(){
   		this.videoCtx.exitFullScreen();
   	 }
     
    
     initOperation(){
    	 super.initOperation();
	   	 this.defineOperation('play', {
	   		 label : "播放",
	   		 icon : '',
	   		 init : function() {},
	   		 method : function(args) {
	   			 this.owner.play();
	   		 }
	   	 });
	   	 this.defineOperation('pause', {
	   		 label : "暂停",
	   		 icon : '',
	   		 init : function() {},
	   		 method : function(args) {
	   			 this.owner.pause();
	   		 }
	   	 });
	   	 this.defineOperation('seek', {
	   		 label : "跳转到指定位置",
	   		 icon : '',
	   		 init : function() {},
	   		 argsDef : [ {
	   			 name : 'position',
	   			 displayName : "位置"
	   		 }],
	   		 method : function(args) {
	   			 this.owner.seek(args.position);
	   		 }
	   	 });
	   	 this.defineOperation('sendDanmu', {
	   		 label : "发送弹幕",
	   		 icon : '',
	   		 init : function() {},
	   		 argsDef : [{
	   			 name : 'text',
	   			 displayName : "弹幕内容"
	   		 }],
	   		 method : function(args) {
	   			 this.owner.sendDanmu(args.text);
	   		 }
	   	 });
	   	 this.defineOperation('playbackRate', {
	   		 label : "设置倍速",
	   		 icon : '',
	   		 init : function() {},
	   		 argsDef : [ {
	   			 name : 'rate',
	   			 displayName : "倍速"
	   		 }],
	   		 method : function(args) {
	   			 this.owner.playbackRate(args.rate);
	   		 }
	   	 });
	   	 this.defineOperation('requestFullScreen', {
	   		 label : "进入全屏",
	   		 icon : '',
	   		 init : function() {},
	   		 argsDef : [],
	   		 method : function(args) {
	   			 this.owner.requestFullScreen();
	   		 }
	   	 });
	   	 this.defineOperation('exitFullScreen', {
	   		 label : "退出全屏",
	   		 icon : '',
	   		 init : function() {},
	   		 argsDef : [],
	   		 method : function(args) {
	   			 this.owner.exitFullScreen();
	   		 }
	   	 });
     }
};
wx.comp = wx.comp || {};
wx.comp.Video = Video;
