import PageComponent from  '../../../../../core/framework/PageComponent'; 
import componentRenderWrapper from  '../../../../../core/render/ComponentRenderWrapper'; 
import {propWrapper,childPropWrapper,__expression,__getPopupContainer,_exRun,__exRun,__toUrl,__renderBlock,__toArraySlot,__toJSONObjectSlot,__toJSONArraySlot,__styleToJSON,_toArray,} from  '../../../../../core/utils'; 
import _WxLabel from  '../../../../../components/wx/WxLabel/WxLabel'; 
import _WxButton from  '../../../../../components/wx/WxButton/WxButton'; 
import _WxIcon from  '../../../../../components/wx/WxIcon/WxIcon'; 
import _WxInput from  '../../../../../components/wx/WxInput/WxInput'; 
import _WxView from  '../../../../../components/wx/WxView/WxView'; 
import React from 'react';
const WxLabel =  componentRenderWrapper(_WxLabel);
const WxButton =  componentRenderWrapper(_WxButton);
const WxIcon =  componentRenderWrapper(_WxIcon);
const WxInput =  componentRenderWrapper(_WxInput);
const WxView =  componentRenderWrapper(_WxView);
let wxPagePathResolver = (function(currentPath){
return function(path,isCodePath){return __toUrl(currentPath,path,isCodePath)}}
)('/wxsys/comps/user/userDialog/changePassword.w');
import wxPageDeclaration from './changePassword';
import wxPageStyle from './changePassword.css'; 
import wxPageConfig from './changePassword.json'; 
		let configContext = {};
export default class __changePasswordPage__ extends PageComponent {
	constructor(props,context){
		super(props,{...context,configContext});
		this.wxPagePathResolve = wxPagePathResolver;
		this.wxPageDeclaration = wxPageDeclaration();
		this.wxPageStyle = wxPageStyle;
		this.wxPageConfig = wxPageConfig;
		this.constructed();
		this.onLoad();
	}
	render(){
		let $page = this.wxPageDeclaration.$page;
	if(!$page){return <React.Fragment></React.Fragment>}		var $state = this.state.data || {data:{}};
		var __pageid__=$state.__pageid__;
		var data=$state.data;
		var hidden=$state.hidden;
		var input3=$state.input3;
		var input2=$state.input2;
		var input1=$state.input1;
		var show=$state.show;
		var required=$state.required;
		var toptips=$state.toptips;
		var debounce=$state.debounce;
		var current=$state.current;
		var readonly=$state.readonly;
		var compid=$state.compid;
		var confirmPasswd=$state.confirmPasswd;
		var button1=$state.button1;
		var _userdata=$state._userdata;
		var val=$state.val;
		var __toptips__=$state.__toptips__;
		var msgs=$state.msgs;
		var oldPassword=$state.oldPassword;
		var newPassword=$state.newPassword;
		var message=$state.message;
		var x=$state.x;
		var typeClass=$state.typeClass;
		var page=$state.page;
		return (
<WxView navigationBarTitleText="修改密码" id="page" data-compid={((_exRun('page.compid','page'))(page))} data-pageid={(__pageid__)} className={this.getModuledCss('null x-page-wx')}>  
    
  <WxView id="inVisibleCompContainer1"> 
  </WxView>  
  <WxView id="form" className={this.getModuledCss('weui-cells weui-cells_form weui-cell-full')}> 
    <WxView id="cell1" className={this.getModuledCss('weui-cell weui-cell_input')}> 
      <WxView id="hd1" className={this.getModuledCss('weui-cell__hd')}> 
        <WxView id="label1" className={this.getModuledCss('weui-label')}><WxLabel id="label1_1">{(_exRun('('+(__exRun(''+(__exRun(''+(__exRun(''+(__exRun('("旧密码")'))()+'===null'))()+'||'+(__exRun(''+(__exRun('("旧密码")'))()+'===undefined'))()+''))()+'||'+(__exRun(''+(__exRun('("旧密码")'))()+'===""'))()+''))()+')?"":("旧密码")'))()}</WxLabel></WxView> 
      </WxView>  
      <WxView id="bd1" className={this.getModuledCss('weui-cell__bd')}> 
		<WxInput id="input1" placeholder="旧密码" password={(true)} value={((_exRun('data.current.oldPassword','data'))(data))} type="text" data-compid={((_exRun('input1.compid','input1'))(input1))} bindblur="dispatchBind" data-pageid={(__pageid__)} bindinput="dispatchBind" data-bindblur={((_exRun('input1.compid','input1'))(input1)) + ':onChange'} data-bindinput={((_exRun('input1.compid','input1'))(input1)) + ':onInput'} hidden={((_exRun('('+(__exRun(''+(__exRun(''+(__exRun('data.current._userdata','data'))(data)+'&&'+(__exRun('data.current._userdata.oldPassword','data'))(data)+'','data'))(data)+'&&'+(__exRun('data.current._userdata.oldPassword.hidden','data'))(data)+'','data'))(data)+')','data'))(data))} disabled={((_exRun('!'+(__exRun('!'+(__exRun('('+(__exRun(''+(__exRun('!'+(__exRun('data.current','data'))(data)+'','data'))(data)+'||'+(__exRun('('+(__exRun(''+(__exRun(''+(__exRun('data.current._userdata','data'))(data)+'&&'+(__exRun('data.current._userdata.oldPassword','data'))(data)+'','data'))(data)+'&&'+(__exRun('data.current._userdata.oldPassword.readonly','data'))(data)+'','data'))(data)+')','data'))(data)+'','data'))(data)+')','data'))(data)+'','data'))(data)+'','data'))(data))} className={this.getModuledCss(((_exRun('data.current._userdata.oldPassword.required.val?"x-wx-required":""','data'))(data)) + ' weui-input c-input ' + ((_exRun('('+(__exRun(''+(__exRun(''+(__exRun('data.current._userdata','data'))(data)+'&&'+(__exRun('data.current._userdata.oldPassword','data'))(data)+'','data'))(data)+'&&'+(__exRun('data.current._userdata.oldPassword.readonly','data'))(data)+'','data'))(data)+')?"readonly":""','data'))(data)))}/>
      </WxView> 
    </WxView>  
    <WxView id="cell2" className={this.getModuledCss('weui-cell weui-cell_input')}> 
      <WxView id="hd21" className={this.getModuledCss('weui-cell__hd')}>
        <WxView id="label2" className={this.getModuledCss('weui-label')}><WxLabel id="label2_1">{(_exRun('('+(__exRun(''+(__exRun(''+(__exRun(''+(__exRun('("新密码")'))()+'===null'))()+'||'+(__exRun(''+(__exRun('("新密码")'))()+'===undefined'))()+''))()+'||'+(__exRun(''+(__exRun('("新密码")'))()+'===""'))()+''))()+')?"":("新密码")'))()}</WxLabel></WxView>
      </WxView>  
      <WxView id="bd2" className={this.getModuledCss('weui-cell__bd')}> 
		<WxInput id="input2" placeholder="6-16位字母数字组合" password={(true)} value={((_exRun('data.current.newPassword','data'))(data))} type="text" data-compid={((_exRun('input2.compid','input2'))(input2))} bindblur="dispatchBind" data-pageid={(__pageid__)} bindinput="dispatchBind" data-bindblur={((_exRun('input2.compid','input2'))(input2)) + ':onChange'} data-bindinput={((_exRun('input2.compid','input2'))(input2)) + ':onInput'} hidden={((_exRun('('+(__exRun(''+(__exRun(''+(__exRun('data.current._userdata','data'))(data)+'&&'+(__exRun('data.current._userdata.newPassword','data'))(data)+'','data'))(data)+'&&'+(__exRun('data.current._userdata.newPassword.hidden','data'))(data)+'','data'))(data)+')','data'))(data))} disabled={((_exRun('!'+(__exRun('!'+(__exRun('('+(__exRun(''+(__exRun('!'+(__exRun('data.current','data'))(data)+'','data'))(data)+'||'+(__exRun('('+(__exRun(''+(__exRun(''+(__exRun('data.current._userdata','data'))(data)+'&&'+(__exRun('data.current._userdata.newPassword','data'))(data)+'','data'))(data)+'&&'+(__exRun('data.current._userdata.newPassword.readonly','data'))(data)+'','data'))(data)+')','data'))(data)+'','data'))(data)+')','data'))(data)+'','data'))(data)+'','data'))(data))} className={this.getModuledCss(((_exRun('data.current._userdata.newPassword.required.val?"x-wx-required":""','data'))(data)) + ' weui-input c-input ' + ((_exRun('('+(__exRun(''+(__exRun(''+(__exRun('data.current._userdata','data'))(data)+'&&'+(__exRun('data.current._userdata.newPassword','data'))(data)+'','data'))(data)+'&&'+(__exRun('data.current._userdata.newPassword.readonly','data'))(data)+'','data'))(data)+')?"readonly":""','data'))(data)))}/>
      </WxView> 
    </WxView> 
    <WxView id="cell3" className={this.getModuledCss('weui-cell weui-cell_input')}> 
      <WxView id="hd3" className={this.getModuledCss('weui-cell__hd')}>
        <WxView id="label3" className={this.getModuledCss('weui-label')}><WxLabel id="label3_1">{(_exRun('('+(__exRun(''+(__exRun(''+(__exRun(''+(__exRun('("确认密码")'))()+'===null'))()+'||'+(__exRun(''+(__exRun('("确认密码")'))()+'===undefined'))()+''))()+'||'+(__exRun(''+(__exRun('("确认密码")'))()+'===""'))()+''))()+')?"":("确认密码")'))()}</WxLabel></WxView>
      </WxView>  
      <WxView id="bd3" className={this.getModuledCss('weui-cell__bd')}> 
		<WxInput id="input3" placeholder="6-16位字母数字组合" password={(true)} value={((_exRun('data.current.confirmPasswd','data'))(data))} type="text" data-compid={((_exRun('input3.compid','input3'))(input3))} bindblur="dispatchBind" data-pageid={(__pageid__)} bindinput="dispatchBind" data-bindblur={((_exRun('input3.compid','input3'))(input3)) + ':onChange'} data-bindinput={((_exRun('input3.compid','input3'))(input3)) + ':onInput'} hidden={((_exRun('('+(__exRun(''+(__exRun(''+(__exRun('data.current._userdata','data'))(data)+'&&'+(__exRun('data.current._userdata.confirmPasswd','data'))(data)+'','data'))(data)+'&&'+(__exRun('data.current._userdata.confirmPasswd.hidden','data'))(data)+'','data'))(data)+')','data'))(data))} disabled={((_exRun('!'+(__exRun('!'+(__exRun('('+(__exRun(''+(__exRun('!'+(__exRun('data.current','data'))(data)+'','data'))(data)+'||'+(__exRun('('+(__exRun(''+(__exRun(''+(__exRun('data.current._userdata','data'))(data)+'&&'+(__exRun('data.current._userdata.confirmPasswd','data'))(data)+'','data'))(data)+'&&'+(__exRun('data.current._userdata.confirmPasswd.readonly','data'))(data)+'','data'))(data)+')','data'))(data)+'','data'))(data)+')','data'))(data)+'','data'))(data)+'','data'))(data))} className={this.getModuledCss(((_exRun('data.current._userdata.confirmPasswd.required.val?"x-wx-required":""','data'))(data)) + ' weui-input c-input ' + ((_exRun('('+(__exRun(''+(__exRun(''+(__exRun('data.current._userdata','data'))(data)+'&&'+(__exRun('data.current._userdata.confirmPasswd','data'))(data)+'','data'))(data)+'&&'+(__exRun('data.current._userdata.confirmPasswd.readonly','data'))(data)+'','data'))(data)+')?"readonly":""','data'))(data)))}/>
      </WxView> 
    </WxView> 
  </WxView>  
  <WxView id="row7" className={this.getModuledCss('x-row c-row')}>  
    <WxView id="col19" className={this.getModuledCss('x-col x-col-10')}/>  
    <WxView id="col20" className={this.getModuledCss('x-col')}>
      <WxButton id="button1" type="primary" loading={((_exRun('button1.debounce','button1'))(button1))} data-compid={((_exRun('button1.compid','button1'))(button1))} data-bindtap="passwordSaveBtnClick" data-pageid={(__pageid__)} bindtap="debounceDispatchBind" className={this.getModuledCss('wx-btn btn-ok')}><WxIcon id="button1-icon" type="wx-btn-icon" className={this.getModuledCss(' wx-icon')}/><WxView id="button1-label" className={this.getModuledCss('wx-btn-label')}>{(_exRun('('+(__exRun(''+(__exRun(''+(__exRun(''+(__exRun('("确定")'))()+'===null'))()+'||'+(__exRun(''+(__exRun('("确定")'))()+'===undefined'))()+''))()+'||'+(__exRun(''+(__exRun('("确定")'))()+'===""'))()+''))()+')?"":("确定")'))()}</WxView></WxButton>
    </WxView>  
    <WxView id="col21" className={this.getModuledCss('x-col x-col-10')}/> 
  </WxView>
<WxView id="__toptips__" hidden={((_exRun('!'+(__exRun('__toptips__.show','__toptips__'))(__toptips__)+'','__toptips__'))(__toptips__))} data-compid={((_exRun('__toptips__.compid','__toptips__'))(__toptips__))} className={this.getModuledCss('wx-toptips')}>{(_toArray(((_exRun('__toptips__.msgs','__toptips__'))(__toptips__)))).map((item, index)  => (<WxView id="__auto1" key={item['_key'] || _.random(0, 1000,true)} className={this.getModuledCss('wx-toptips-item ' + ((_exRun('item.typeClass','item'))(item)) + ' ' + ((_exRun('item.show?"wx-toptips--show":""','item'))(item)))}>{(_exRun('item.message','item'))(item)}</WxView>))}
</WxView></WxView>
		);
	}
}
