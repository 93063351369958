import BindComponent from "../../lib/base/bindComponent";
import {observable} from '../../lib/mobx/mobx-2.6.2.umd';

export default class Password extends BindComponent {
     constructor(page, id, props, context){
        super(page, id, props, context);
        this.displayPassword = observable(false);
     }
     
     buildState(context){
      	var state = super.buildState(context);
      	state.displayPassword = this.displayPassword.get();

      	return state;
      }
     
     _handleDisplayPassword(){
    	 this.displayPassword.set(!this.displayPassword.get());
     }
}



wx.comp = wx.comp || {};
wx.comp.Password = Password;

