import PageImpl from "../../../lib/base/pageImpl";
import {observable, extendObservable, autorun, toJS, isObservableArray, isObservableObject, isObservable } from  "../../../lib/mobx/mobx-2.6.2.umd";
import {cloneJSON} from "../../../lib/base/util";

export default class IndexPage extends PageImpl {
	constructor(...args){
		super(...args);
	}

    onListTap(event){
    	let optionData = this.comp('optionData');
		if(optionData){
			let selectedCol = "selected";
			let idCol = optionData.getIdColumn();
			optionData.each((p)=>{
				let id = p.row[idCol];
				p.row[selectedCol]=(id==event.currentTarget.dataset.idcolumn?"1":"");
			});
		}
    }

	_getNow() {
		if (!this._now) {
			this._now = new Date();
		}
		return this._now;
	}
	
	_getToday() {
		if (!this._today) {
			var now = this._getNow();
			this._today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
		}
		return this._today;
	}

    onPageLoaded(event){
    	let options = this.params.options;
    	let selectValue = this.params.selectValue || "all";
    	
    	let today = this._getToday();
    	let beginDate = this.params.beginDate||today;
    	let endDate = this.params.endDate||today;
    	this._dateOptions = {
				"all" : {
					"name": "all",
					"label" : "全部",
					"begin" : null,
					"end" : null
				},
				"today" : {
					"name": "today",
					"label" : "今天",
					"begin" : new Date(today),
					"end" : new Date(today)
				},
				"yesterday" : {
					"name": "yesterday",
					"label" : "昨天",
					"begin" : new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1),
					"end" : new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1)
				},
				"thisWeek" : {
					"name": "thisWeek",
					"label" : "本周",
					"begin" : new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay() + 1),
					"end" : new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay() + 7)
				},
				"lastWeek" : {
					"name": "lastWeek",
					"label" : "上周",
					"begin" : new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay() - 6),
					"end" : new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay())
				},
				"thisMonth" : {
					"name": "thisMonth",
					"label" : "本月",
					"begin" : new Date(today.getFullYear(), today.getMonth(), 1),
					"end" : new Date(today.getFullYear(), today.getMonth() + 1, 0)
				},
				"lastMonth" : {
					"name": "lastMonth",
					"label" : "上月",
					"begin" : new Date(today.getFullYear(), today.getMonth() - 1, 1),
					"end" : new Date(today.getFullYear(), today.getMonth(), 0)
				},
				"thisYear" : {
					"name": "thisYear",
					"label" : "本年",
					"begin" : new Date(today.getFullYear(), 0, 1),
					"end" : new Date(today.getFullYear() + 1, 0, 0)
				},
				"lastYear" : {
					"name": "lastYear",
					"label" : "去年",
					"begin" : new Date(today.getFullYear() - 1, 0, 1),
					"end" : new Date(today.getFullYear(), 0, 0)
				},
				"custom" : {
					"name": "custom",
					"label" : "自定义",
					"begin" : new Date(beginDate),
					"end" : new Date(endDate)
				}
			};
    	let allOptions = false;
    	options?(options=options.split(',')):(allOptions = true);
    	let rows = [];
    	for(let [key,value] of Object.entries(this._dateOptions)){
    		(allOptions || options.indexOf(key)>=0) && rows.push(value); 
    		key==selectValue && (value.selected = '1');
    	}
    	this.comp('optionData').loadData(rows);
    }
    
    getSelectedRow(){
    	let ret;
    	let optionData = this.comp('optionData');
    	optionData.each((p)=>{
    		if(p.row.selected=='1'){
    			ret = p.row;
    			return false;
    		}
    	});
    	return ret;
    }

    onOkTap(event){
    	let row = this.getSelectedRow()||{};
        this.navigateBack({
            params: {
              isOk: true,
              label: row.label,
              beginDate: row.begin,
              endDate: row.end,
              selectValue: row.name
            }
        });
	}
}
