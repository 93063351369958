import component from "../../lib/base/component";
import {observable,autorun} from  "../../lib/mobx/mobx-2.6.2.umd";
import minioUtil from "../image/util/minioUtil";
export default class Map extends component {
     constructor(page, id, props, context){
        super(page, id, props, context);
        this.markers = observable("");
        this.polyline = observable("");
        this.longitude = observable("");
        this.latitude = observable("");
        this.controls = observable("");
        this.circles = observable("");
        this.actionUrl = wx.App.baseUrl + "/storage";
        var self = this;
        this.markersObj = "";
        autorun(() => {
        	self.attributeList();
        	if (self.props.$latitudeAttrFn && (typeof self.page[self.props.$latitudeAttrFn]==="function")){
        		var nameResult = self.page[self.props.$latitudeAttrFn](self.context.vars);
        		if(nameResult){
        			self.latitude.set(nameResult);
        		}
        	}
        	if (self.props.$longitudeAttrFn && (typeof self.page[self.props.$longitudeAttrFn]==="function")){
        		var longitudeResult = self.page[self.props.$longitudeAttrFn](self.context.vars);
        		if(longitudeResult){
        			self.longitude.set(longitudeResult);
        		}
        	}
        });
     }
     buildState(context){
    	 var state = super.buildState(context);
    	 state.latitude = this.latitude.get(); 
      	 state.longitude = this.longitude.get();
    	 state.markers = this.markers.get();
    	 state.polyline = this.polyline.get();
    	 state.controls = this.controls.get();
    	 state.circles = this.circles.get();
    	 return state;
     }
     attributeList(){
       var self = this, itemData, name, polylineData, circlesData;
       var hasIcon = false;
    	 var items = [];
    	 if(this.props.markersData){
        	 var mapData = JSON.parse(this.props.markersData);
    		 var dataName = mapData.mapData[0].markersData;
    		 var polylineName = mapData.mapData[0].polylineData;
    		 polylineData = self.context.vars[polylineName];
        	 itemData = self.context.vars[dataName];
        	 var circlesName = mapData.mapData[0].circlesData
        	 circlesData = self.context.vars[circlesName];
        	 var dataJson = mapData.dataJson[0];
        	 var polyJson = mapData.polylineData[0];
        	 var circlesJson = mapData.circlesData[0];
        	 if(itemData){
        		 itemData.each(function(item){
                     var res = {};
                		 if(dataJson){ 
                			 for(var i in dataJson){
                				 if(i != "userdata"){
                					 if(dataJson[i] && dataJson[i].indexOf(dataName)>=0){
                						 if(i == "callout"){
                							 var callout = JSON.parse(dataJson[i]);
                							 callout.borderRadius = callout.borderRadius * 1;
                							 callout.fontSize =  callout.fontSize*1 || 13;
                							 callout.padding = callout.padding*1;
                							 if(callout.content.indexOf(dataName) >= 0){
                								 name = callout.content.split(".")[2];
                								 callout.content = item.row[name] || "";
                							 }
                							 res[i] = callout;
                						 }else if(i == "label"){
                							 var label = JSON.parse(dataJson[i]);
                							 label.borderRadius = label.borderRadius * 1;
                							 label.borderWidth = label.borderWidth *1;
                							 label.fontSize =  label.fontSize*1 || 13;
                							 label.padding = label.padding*1;
                							 label.x = label.x*1;
                							 label.y = label.y*1;
                							 if(label.content.indexOf(dataName) >= 0){
                								 name = label.content.split(".")[2];
                								 label.content = item.row[name] || "";
                							 }
                							 res[i] = label;
                						 }else{
                							 name = dataJson[i].split(".")[2];
				                               if (i == "iconPath") {
				                                  var markerIconPath = item.row[name];
				                                  if (markerIconPath && markerIconPath.indexOf("[{\"storeFileName\"") == 0){
				                                      markerIconPath = JSON.parse(markerIconPath);
				                                      for (var i = 0; i < markerIconPath.length; i++) {
				                                        var name = markerIconPath[i].realFileName;
				                                        var pathType = name.substring(name.lastIndexOf("."), name.length).toLocaleLowerCase();
				                                        var storeFileName = markerIconPath[i].storeFileName;
				                                        if (storeFileName.indexOf("anoy_") == 0) {
				                                          hasIcon = true;
				                                          minioUtil.getFileUrl(self.actionUrl, storeFileName).then(function (data) {
				                                            var url = data;
				                                            wx.downloadFile({
				                                              url: url,
				                                              success: function (data) {
				                                                res["iconPath"] = data.tempFilePath;
				                                                if (items)
				                                                  self.markers.set(items);
				                                              }
				                                            })
				                                          })
				                                        }else{
						                            	  hasIcon = true;
				                                          this.page.request({
				                                            url: this.actionUrl + '/presignedGetObject',
				                                            data: {
				                                              objectName: storeFileName
				                                            },
				                                            header: {
				                                              'content-type': 'application/json'
				                                            },
				                                            success: function (res) {
				                                              wx.downloadFile({
				                                                url: res.data,
				                                                success: function (data) {
				                                                  res["iconPath"] = data.tempFilePath;
				                                                  if (items)
				                                                    self.markers.set(items);
				                                                }
				                                              })
				                                            }
				                                          })
				                                        }
				                                      }
				                                  }
				                               }else{
				                            	 
				                                 res[i] = item.row[name];
				                               }
                						 }
                        			 } 
                				 }
                    		 }
                       items.push(res);
                		 } 		 
                	 }); 
        	 }
        	 	if(!hasIcon){
        	 		this.markers.set(items);
        	 	}
        		 this.markersObj = items; 
        	var points = [];
        	if(polylineData){
        		polylineData.each(function(item){
            		if(polyJson){
            			var latitudeName = mapData.polylineData[0].pointsLatitude.split(".")[2];
            			var longitudeName = mapData.polylineData[0].pointsLongitude.split(".")[2];
            			points.push({latitude:item.row[latitudeName] * 1,longitude:item.row[longitudeName] * 1});
            		}
            	});
        	}
        	 polyJson.points = points;
        	 polyJson["dottedLine"] == 1 ? polyJson["dottedLine"] = true : polyJson["dottedLine"] = false;
             polyJson["arrowLine"] == 1 ? polyJson["arrowLine"] = true : polyJson["arrowLine"] = false;
             polyJson["borderWidth"] = polyJson["borderWidth"]*1;
             polyJson["width"] = polyJson["width"] * 1;
             polyJson["arrowIconPath"] = polyJson["arrowIconPath"] ? wx.Util.toResUrl(polyJson["arrowIconPath"]):"";
             wx.downloadFile({
               url: polyJson["arrowIconPath"],
               success: function (res) {
                 var tempFilePaths = res.tempFilePath;
                 polyJson["iconPath"] = tempFilePaths
               }
             });
             if(polyJson.points.length > 0){
            	 this.polyline.set([polyJson]);
             }
           	var controlsJson = mapData.controlsData;
           	var mapControls = [];
           	controlsJson.forEach(function(item,index){
               	item["id"] = parseInt(item["id"]);
               	item["clickable"] == 1 ? item["clickable"] = true : item["clickable"] = false;
               	var controlsPosition = JSON.parse(item["position"]);
               	for(var i in controlsPosition){
               		controlsPosition[i] = parseInt(controlsPosition[i]);
               	}
               	item["position"] = controlsPosition;
                 item["iconPath"] = item["iconPath"] ? wx.Util.toResUrl(item["iconPath"]) : "";
                wx.downloadFile({
                  url: item["iconPath"],
                  success : function(res){
                    var tempFilePaths = res.tempFilePath;
                    item["iconPath"] = tempFilePaths;
                  }
                });
               	mapControls.push(item);
           	});
           	if(mapControls.length > 0){
           		this.controls.set(mapControls);
           	}
              var circles = [];
              if(circlesData){
            	  circlesData.each(function(item){
                      if (circlesJson){
                        var res = {};
                        delete circlesJson["userdata"];
                        for (var i in circlesJson){
                          console.log(circlesJson[i]);
                          res[i] = item.row[circlesJson[i].split(".")[2]];
                        }
                        res.latitude = res.latitude * 1;
                        res.longitude = res.longitude * 1;
                        res.radius = res.radius*1;
                        res.strokeWidth = res.strokeWidth * 3;
                        res.fillColor = res.fillColor || "#ffffff00";
                        circles.push(res);
                      }
                    })
              }
            this.circles.set(circles);
    	 }
     }
     setMarkers(args){
    	 this.markers.set(this.markersObj);
     }
     clearMarkers(args){
    	 this.markers.set();
     }
     initOperation(){
     	super.initOperation();
 	   	this.defineOperation('setMarkers', {
 	   		 label : "设置地图标记点",
 	   		 icon : '',
 	   		 init : function() {},
 	   		 argsDef : [],
 	   		 method : function(args) {
 	   			 this.owner.setMarkers(args);
 	   		 }
 	   	 });
 	   this.defineOperation('clearMarkers', {
	   		 label : "隐藏地图标记点",
	   		 icon : '',
	   		 init : function() {},
	   		 argsDef : [],
	   		 method : function(args) {
	   			 this.owner.clearMarkers(args);
	   		 }
	   	 });
     }
     
}
	
	
wx.comp = wx.comp || {};
wx.comp.Map = Map;

