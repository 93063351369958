
function _getPlaceholderClass(placeholderClz){
		placeholderClz=placeholderClz?placeholderClz:""
		return `input-placeholder ${placeholderClz?placeholderClz:"" }`;
};

function _getPlaceholderStyle(placeholderStyle){
	return Object.assign({},placeholderStyle,{
			zIndex:1,
			position:'absolute'
	})
};


export {_getPlaceholderClass,_getPlaceholderStyle}