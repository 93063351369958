export default {
    //获取间隔天数
    getDaysNumberBetween(date1, date2) {
        var startDate = Date.parse(date1);
        var endDate = Date.parse(date2);
        if (startDate > endDate) {
            return 0;
        }
        if (startDate == endDate) {
            return 1;
        }
        var days = (endDate - startDate) / (1 * 24 * 60 * 60 * 1000);
        return days;
    },

    //判断一年多少天
    getYearDays(year) {
        return 365 + this.isLeap(year);
    },

    getYear(date){
        return date.split("-")[0];
    },

    //判断是否闰年
    isLeap(year) {
        let res = 0;
        return (year % 100 == 0 ? res = (year % 400 == 0 ? 1 : 0) : res = (year % 4 == 0 ? 1 : 0));
    },
    //获取日期对应的天数
    getDayNumByDate(date) {
        var arr = date.split('-');
        var year = new Date(arr[0], 0, 0);
        var mouth = new Date(arr[0], arr[1] - 1, arr[2]);
        var day = parseInt((year - mouth) / 24 / 60 / 60 / 1000);
        return -day;
    },
    //获取间隔日期数组
    getBetweenDays(begin, end){
        var dateAllArr = new Array();
        var ab = begin.split("-");
        var ae = end.split("-");
        var db = new Date();
        db.setUTCFullYear(ab[0], ab[1] - 1, ab[2]);
        var de = new Date();
        de.setUTCFullYear(ae[0], ae[1] - 1, ae[2]);
        var unixDb = db.getTime();
        var unixDe = de.getTime();
        for (var k = unixDb; k <= unixDe;) {
            dateAllArr.push(this.formatDate(new Date(parseInt(k)), 'yyyy-MM-dd').toString());
            k = k + 24 * 60 * 60 * 1000;
        }
        return dateAllArr;
    },
    //格式化日期
    formatDate(date, format) {
        var o = {
            "M+": date.getMonth() + 1, //month  
            "d+": date.getDate(), //day  
            "h+": date.getHours(), //hour  
            "m+": date.getMinutes(), //minute  
            "s+": date.getSeconds(), //second  
            "q+": Math.floor((date.getMonth() + 3) / 3), //quarter  
            "S": date.getMilliseconds() //millisecond  
        }
        if (/(y+)/.test(format)) format = format.replace(RegExp.$1,
            (date.getFullYear() + "").substr(4 - RegExp.$1.length));
        for (var k in o)
            if (new RegExp("(" + k + ")").test(format))
                format = format.replace(RegExp.$1,
                    RegExp.$1.length == 1 ? o[k] :
                        ("00" + o[k]).substr(("" + o[k]).length));
        return format;
    },

    addDay(date, dayNumber) {
        date = typeof(date) == 'string'? new Date(date):date;
        var ms = dayNumber * (1000 * 60 * 60 * 24)
        var newDate = new Date(date.getTime() + ms);
        return this.formatDate(newDate, "yyyy-MM-dd");
    }     

}
