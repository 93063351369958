
export default class TimerImpl {
	constructor(page) {
		this.page = page;
		this.times = -1;
		this._pause = false;
		this.interval = 1000;
		this._timerHandle = null;
		this.dispatchEventFn = ()=>{}
	}

	start(params) {
		this.times = params.times;
		this._pause = params.pause;
		this.interval = params.interval;
		this.dispatchEventFn = params.dispatchEventFn

		if (!this._timerHandle || this._pause) {
			var runTimes = 0;
			var self = this;
			this._timerHandle = setInterval(function () {
				if (self._pause) return;
				runTimes++;
				self.dispatchEventFn(TimerImpl.EVENT_TIMER, { source: self, times: runTimes })
				if (self.times > 0 && runTimes >= self.times) self.stop();
			}, this.interval);
		}
		this._pause = false;
	}

	_clearInterval() {
		if (this._timerHandle) {
			clearInterval(this._timerHandle);
			this._timerHandle = null;
		}
	}

	stop() {
		this._pause = false;
		//this.enabled = false;
		this._clearInterval();
		this.dispatchEventFn(TimerImpl.EVENT_STOP, {})
	}

	pause() {
		this._pause = true;
		this._clearInterval();
	}
}
TimerImpl.EVENT_TIMER = "timer";
TimerImpl.EVENT_STOP = "stop"
