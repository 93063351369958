import _createPageConfig from '../../../lib/base/createPageConfig';
import PageClass from './twofactorlogin.user';

 var $g_fns_loginData = {
		get _userdata(){
			return wx.Util.prepareUserData(this, {

			}, ['id','name','password','type','_key']);
		}
}; 


 var $g_fns_ctrl = {
		get _userdata(){
			return wx.Util.prepareUserData(this, {

			}, ['id','btnLabel','btnDisabled','_key']);
		}
}; 

import '../../commonOperation/commonOperation'; 
import '../../tableData/tableData'; 
import '../../icon/css/icon.css'; 
import '../../input/input'; 
import '../../input/css/input.css'; 
import '../../button/button'; 
import '../../button/css/button.css'; 
import '../../row/css/row.css'; 
import '../../page/page'; 
import '../../page/css/page.css'; 
import '../../wrapper/wrapper'; 
import '../../toptips/toptips'; 
import '../../toptips/css/toptips.css'; 
import '../../wxApi/wxApi'; 
var methods = {

 $refPathFn_input3_1: function({$row,ctrl,$comp,params,$page,loginData,props}){
 return loginData.current._path ;
}

,
 $refFn_input3_1: function({$row,ctrl,$comp,params,$page,loginData,props}){
 return loginData.current.name ;
}

,
 $refPathFn_input3_2: function({$row,ctrl,$comp,params,$page,loginData,props}){
 return loginData.current._path ;
}

,
 $refFn_input3_2: function({$row,ctrl,$comp,params,$page,loginData,props}){
 return loginData.current.password ;
}

}
var template = [
	[
		{
			"cls":wx.compClass('$UI/wxsys/comps/tableData/tableData'),
			"props":{
				"schema":{
					"limit":20,
					"orderBy":[],
					"type":"array",
					"items":{
						"type":"object",
						"props":{
							"id":{
								"define":"id",
								"label":"ID",
								"type":"string",
								"extType":"String"
							},
							"name":{
								"define":"name",
								"label":"登录账号",
								"type":"string",
								"extType":"String"
							},
							"password":{
								"define":"password",
								"label":"密码",
								"type":"string",
								"extType":"String"
							},
							"type":{
								"define":"type",
								"label":"登录方式",
								"type":"string",
								"extType":"String"
							},
							"_key":{
								"type":"string"
							}
						},
						"key":"_key",
						"fns":$g_fns_loginData
					},
					"id":"loginData",
					"keyItems":"_key"
				},
				"initData":[
					{
						"id":"login",
						"type":"password"
					}
				],
				"options":{
					"calcTotal":"",
					"isMain":false,
					"autoMode":"load",
					"directDelete":true,
					"checkRange":"",
					"confirmDelete":true,
					"enableContextReadonly":true,
					"confirmRefreshText":"",
					"allowEmpty":false,
					"checkMode":"",
					"confirmDeleteText":"",
					"confirmRefresh":true,
					"idColumn":"id"
				},
				"id":"loginData",
				"filters":{}
			}
		},
		{
			"cls":wx.compClass('$UI/wxsys/comps/tableData/tableData'),
			"props":{
				"schema":{
					"limit":20,
					"orderBy":[],
					"type":"array",
					"items":{
						"type":"object",
						"props":{
							"id":{
								"define":"id",
								"label":"ID",
								"type":"string",
								"extType":"String"
							},
							"btnLabel":{
								"define":"btnLabel",
								"label":"获取验证码按钮文字",
								"type":"string",
								"extType":"String"
							},
							"btnDisabled":{
								"define":"btnDisabled",
								"label":"获取验证码按钮可用",
								"type":"boolean",
								"extType":"Boolean"
							},
							"_key":{
								"type":"string"
							}
						},
						"key":"_key",
						"fns":$g_fns_ctrl
					},
					"id":"ctrl",
					"keyItems":"_key"
				},
				"initData":[
					{
						"btnDisabled":false,
						"id":"data",
						"btnLabel":"获取验证码"
					}
				],
				"options":{
					"calcTotal":"",
					"isMain":false,
					"autoMode":"",
					"directDelete":true,
					"checkRange":"",
					"confirmDelete":true,
					"enableContextReadonly":true,
					"confirmRefreshText":"",
					"allowEmpty":false,
					"checkMode":"",
					"confirmDeleteText":"",
					"confirmRefresh":true,
					"idColumn":"id"
				},
				"id":"ctrl",
				"filters":{}
			}
		}
	],
	{
		"cls":wx.compClass('$UI/wxsys/comps/page/page'),
		"props":{
			"$events":{
				"load":"pageLoad"
			},
			"id":"page"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/wxApi/wxApi'),
		"props":{
			"id":"wxapi"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/commonOperation/commonOperation'),
		"props":{
			"id":"commonOperation1"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/input/input'),
		"props":{
			"$propName":"name",
			"$refFn":"$refFn_input3_1",
			"id":"input3_1",
			"$refPathFn":"$refPathFn_input3_1"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/input/input'),
		"props":{
			"$propName":"password",
			"$refFn":"$refFn_input3_2",
			"id":"input3_2",
			"$refPathFn":"$refPathFn_input3_2"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/wrapper/wrapper'),
		"props":{
			"id":"button2"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/button/button'),
		"props":{
			"id":"button1"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/toptips/toptips'),
		"props":{
			"id":"__toptips__"
		}
	}
];
var dependTree = {
	"page":{
		"__toptips__":{
			"depends":["__toptips__.show","__toptips__.compid"],
			"__auto1":{
				"depends":["__toptips__.msgs","__toptips__.items[].typeClass","__toptips__.items[].show","__toptips__.items[].message"]
			}
		},
		"inVisibleCompContainer1":{
			"depends":[]
		},
		"depends":["page.compid","__pageid__"],
		"title":{
			"depends":[],
			"icon3":{
				"depends":[]
			}
		},
		"form3":{
			"cell3_2":{
				"depends":[],
				"hd3_21":{
					"label3_2":{
						"depends":[],
						"label3_2_1":{
							"depends":[]
						}
					},
					"depends":[]
				},
				"bd3_2":{
					"input3_2":{
						"depends":["loginData.current._userdata.password.required.val","loginData.current._userdata","loginData.current._userdata.password","loginData.current._userdata.password.readonly","true","loginData.current.password","input3_2.compid","__pageid__","loginData.current._userdata.password.hidden","loginData.current"]
					},
					"depends":[]
				},
				"ft4":{
					"button2":{
						"label_vcode-btn":{
							"depends":["ctrl.current.btnLabel"]
						},
						"depends":["button2.compid","__pageid__","ctrl.current.btnDisabled"]
					},
					"depends":[]
				}
			},
			"cell3_1":{
				"bd3_1":{
					"depends":[],
					"input3_1":{
						"depends":["loginData.current._userdata.name.required.val","loginData.current._userdata","loginData.current._userdata.name","loginData.current._userdata.name.readonly","loginData.current.name","input3_1.compid","__pageid__","loginData.current._userdata.name.hidden","true","loginData.current"]
					}
				},
				"depends":[],
				"hd3_1":{
					"depends":[],
					"label3_1":{
						"depends":[],
						"label3_1_1":{
							"depends":[]
						}
					}
				}
			},
			"depends":[]
		},
		"row4":{
			"depends":[],
			"col2":{
				"depends":[],
				"button1":{
					"depends":["button1.debounce","button1.compid","__pageid__"],
					"button1-icon":{
						"depends":[]
					},
					"button1-label":{
						"depends":[]
					}
				}
			},
			"col3":{
				"depends":[]
			},
			"col1":{
				"depends":[]
			}
		}
	}
}
export function createPageConfig(){
	return _createPageConfig(PageClass, template, methods, {"navigationBarTitleText":"双重认证"},{}, dependTree)
}
export function createPage(owner, pageid, props){
	var page = new PageClass(owner, props);
	page.$init(template, methods, pageid);
	return page;
}
