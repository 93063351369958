import _createPageConfig from '../../../lib/base/createPageConfig';
import PageClass from './selectDate.user';

 var $g_fns_optionData = {
		get _userdata(){
			return wx.Util.prepareUserData(this, {

			}, ['name','label','begin','end','selected','_key']);
		}
}; 

import '../../commonOperation/commonOperation'; 
import '../../labelInput/css/labelInput.css'; 
import '../../tableData/tableData'; 
import '../../icon/css/icon.css'; 
import '../../list/list'; 
import '../../input/input'; 
import '../../input/css/input.css'; 
import '../../button/button'; 
import '../../button/css/button.css'; 
import '../../row/css/row.css'; 
import '../../buttonGroup/css/buttonGroup.css'; 
import '../../page/page'; 
import '../../page/css/page.css'; 
import '../../wrapper/wrapper'; 
import '../../text/text'; 
import '../../toptips/toptips'; 
import '../../toptips/css/toptips.css'; 
import '../../checkbox/checkbox'; 
import '../../checkbox/css/checkbox.css'; 
import '../../panel/css/panel.css'; 
import '../../loading/loading'; 
import '../../loading/css/loading.css'; 
import '../../wxApi/wxApi'; 
var methods = {

 $attrBindFn_hidden_labelInput1input_invalid_view: function({listindex,$row,optionData,$comp,$item,params,$page,props,listitem}){
 try{return $page.comp('labelInput1input', $comp) && $page.comp('labelInput1input', $comp).isValid()}catch(e){return ''} ;
}

,
 $items_list: function({listindex,$row,optionData,$comp,params,$page,props,listitem}){
 try{return optionData.value}catch(_e){} ;
}

,
 $refFn_checkbox: function({listindex,$row,optionData,$comp,$item,params,$page,props,listitem}){
 return listitem.selected ;
}

,
 $refPathFn_labelInput1input: function({listindex,$row,optionData,$comp,$item,params,$page,props,listitem}){
 return listitem._path ;
}

,
 $attrBindFn_text_labelInput2input_invalid_text: function({listindex,$row,optionData,$comp,$item,params,$page,props,listitem}){
 try{return $page.comp('labelInput2input', $comp) && $page.comp('labelInput2input', $comp).invalidInfo()}catch(e){return ''} ;
}

,
 $attrBindFn_hidden_labelInput2input_invalid_view: function({listindex,$row,optionData,$comp,$item,params,$page,props,listitem}){
 try{return $page.comp('labelInput2input', $comp) && $page.comp('labelInput2input', $comp).isValid()}catch(e){return ''} ;
}

,
 $refFn_labelInput2input: function({listindex,$row,optionData,$comp,$item,params,$page,props,listitem}){
 return listitem.end ;
}

,
 $attrBindFn_text_labelInput1input_invalid_text: function({listindex,$row,optionData,$comp,$item,params,$page,props,listitem}){
 try{return $page.comp('labelInput1input', $comp) && $page.comp('labelInput1input', $comp).invalidInfo()}catch(e){return ''} ;
}

,
 $refPathFn_labelInput2input: function({listindex,$row,optionData,$comp,$item,params,$page,props,listitem}){
 return listitem._path ;
}

,
 $refFn_labelInput1input: function({listindex,$row,optionData,$comp,$item,params,$page,props,listitem}){
 return listitem.begin ;
}

,
 $refPathFn_checkbox: function({listindex,$row,optionData,$comp,$item,params,$page,props,listitem}){
 return listitem._path ;
}

,$evtH_cancel_tap: function({$event,$row,$data,optionData,$item,params,$page,props}){
let $$$args = arguments[0];
	let args={};
	 $page.$compByCtx('commonOperation',$event.source).executeOperation('close', args, $$$args);

}

}
var template = [
	[
		{
			"cls":wx.compClass('$UI/wxsys/comps/tableData/tableData'),
			"props":{
				"schema":{
					"limit":20,
					"orderBy":[],
					"type":"array",
					"items":{
						"type":"object",
						"props":{
							"name":{
								"define":"name",
								"label":"name",
								"type":"string",
								"extType":"String"
							},
							"label":{
								"define":"label",
								"label":"label",
								"type":"string",
								"extType":"String"
							},
							"begin":{
								"define":"begin",
								"label":"begin",
								"type":"date",
								"extType":"Date"
							},
							"end":{
								"define":"end",
								"label":"end",
								"type":"date",
								"extType":"Date"
							},
							"selected":{
								"define":"selected",
								"label":"selected",
								"type":"string",
								"extType":"String"
							},
							"_key":{
								"type":"string"
							}
						},
						"key":"_key",
						"fns":$g_fns_optionData
					},
					"id":"optionData",
					"keyItems":"_key"
				},
				"options":{
					"calcTotal":"",
					"isMain":false,
					"autoMode":"",
					"directDelete":true,
					"checkRange":"",
					"confirmDelete":true,
					"enableContextReadonly":true,
					"confirmRefreshText":"",
					"allowEmpty":false,
					"checkMode":"",
					"confirmDeleteText":"",
					"confirmRefresh":true,
					"idColumn":"name"
				},
				"id":"optionData",
				"filters":{}
			}
		}
	],
	{
		"cls":wx.compClass('$UI/wxsys/comps/page/page'),
		"props":{
			"$events":{
				"loaded":"onPageLoaded"
			},
			"id":"page"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/wxApi/wxApi'),
		"props":{
			"id":"wxapi"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/commonOperation/commonOperation'),
		"props":{
			"id":"commonOperation"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/loading/loading'),
		"props":{
			"loadingNum":0,
			"id":"__auto1"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/list/list'),
		"props":{
			"$items":"$items_list",
			"item":"listitem",
			"autoRefresh":true,
			"$template":[
				{
					"cls":wx.compClass('$UI/wxsys/comps/checkbox/checkbox'),
					"props":{
						"$propName":"selected",
						"$refFn":"$refFn_checkbox",
						"id":"checkbox",
						"$refPathFn":"$refPathFn_checkbox",
						"value":"1"
					}
				},
				{
					"cls":wx.compClass('$UI/wxsys/comps/text/text'),
					"props":{
						"id":"text1"
					}
				},
				{
					"cls":wx.compClass('$UI/wxsys/comps/input/input'),
					"props":{
						"$propName":"begin",
						"$refFn":"$refFn_labelInput1input",
						"id":"labelInput1input",
						"$refPathFn":"$refPathFn_labelInput1input"
					}
				},
				{
					"cls":wx.compClass('$UI/wxsys/comps/wrapper/wrapper'),
					"props":{
						"id":"labelInput1input_invalid_view",
						"$attrBindFns":{
							"hidden":"$attrBindFn_hidden_labelInput1input_invalid_view"
						}
					}
				},
				{
					"cls":wx.compClass('$UI/wxsys/comps/text/text'),
					"props":{
						"id":"labelInput1input_invalid_text",
						"$attrBindFns":{
							"text":"$attrBindFn_text_labelInput1input_invalid_text"
						}
					}
				},
				{
					"cls":wx.compClass('$UI/wxsys/comps/input/input'),
					"props":{
						"$propName":"end",
						"$refFn":"$refFn_labelInput2input",
						"id":"labelInput2input",
						"$refPathFn":"$refPathFn_labelInput2input"
					}
				},
				{
					"cls":wx.compClass('$UI/wxsys/comps/wrapper/wrapper'),
					"props":{
						"id":"labelInput2input_invalid_view",
						"$attrBindFns":{
							"hidden":"$attrBindFn_hidden_labelInput2input_invalid_view"
						}
					}
				},
				{
					"cls":wx.compClass('$UI/wxsys/comps/text/text'),
					"props":{
						"id":"labelInput2input_invalid_text",
						"$attrBindFns":{
							"text":"$attrBindFn_text_labelInput2input_invalid_text"
						}
					}
				}
			],
			"autoLoadNextPage":true,
			"index":"listindex",
			"id":"list",
			"items":"optionData.value",
			"key":"_key"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/button/button'),
		"props":{
			"id":"cancel"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/button/button'),
		"props":{
			"id":"ok"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/toptips/toptips'),
		"props":{
			"id":"__toptips__"
		}
	}
];
var dependTree = {
	"page":{
		"__toptips__":{
			"__auto8":{
				"depends":["__toptips__.msgs","__toptips__.items[].typeClass","__toptips__.items[].show","__toptips__.items[].message"]
			},
			"depends":["__toptips__.show","__toptips__.compid"]
		},
		"inVisibleCompContainer":{
			"depends":[]
		},
		"depends":["page.compid","__pageid__"],
		"__auto1":{
			"depends":["__auto1.loadingAfterShow","__auto1.loadingNum","__auto1.compid"],
			"__auto2":{
				"__auto7":{
					"depends":[]
				},
				"depends":[],
				"__auto5":{
					"depends":[]
				},
				"__auto6":{
					"depends":[]
				},
				"__auto3":{
					"depends":[]
				},
				"__auto4":{
					"depends":[]
				}
			}
		},
		"panel":{
			"depends":[],
			"bottom":{
				"depends":[],
				"buttonGroup1":{
					"cancel":{
						"depends":["cancel.debounce","cancel.compid","__pageid__"],
						"icon3":{
							"depends":[]
						},
						"view3":{
							"depends":[]
						}
					},
					"depends":[],
					"ok":{
						"depends":["ok.debounce","ok.compid","__pageid__"],
						"view2":{
							"depends":[]
						},
						"icon2":{
							"depends":[]
						}
					}
				}
			},
			"content":{
				"depends":[],
				"list":{
					"depends":["list.items","list.items[]","list.items[].name","list.compid","__pageid__"],
					"row":{
						"col":{
							"depends":[],
							"checkbox":{
								"depends":["list.items[]._userdata.selected.required.val","list.items[]._userdata","list.items[]._userdata.selected","list.items[]._userdata.selected.readonly","list.items[].$children.checkbox.checked","list.items[].$children.checkbox.compid","__pageid__","list.items[]._userdata.selected.hidden","list.items[]"]
							}
						},
						"depends":[],
						"col1":{
							"depends":[],
							"text1":{
								"depends":["list.items[].$children.text1.compid","list.items[].label"]
							}
						}
					},
					"view1":{
						"labelInput2":{
							"labelInput2input_invalid_view":{
								"depends":["list.items[].$children.labelInput2input_invalid_view.compid","list.items[].$children.labelInput2input_invalid_view.hidden"],
								"labelInput2input_invalid_text":{
									"depends":["list.items[].$children.labelInput2input_invalid_text.compid","list.items[].$children.labelInput2input_invalid_text.text"]
								}
							},
							"labelInput2label":{
								"depends":[]
							},
							"depends":[],
							"labelInput2input":{
								"depends":["list.items[]._userdata.end.required.val","list.items[]._userdata","list.items[]._userdata.end","list.items[]._userdata.end.readonly","list.items[].$children.labelInput2input.displayText","list.items[].end","list.items[].$children.labelInput2input.compid","__pageid__","list.items[]._userdata.end.hidden","list.items[]"]
							}
						},
						"labelInput1":{
							"depends":[],
							"labelInput1input_invalid_view":{
								"depends":["list.items[].$children.labelInput1input_invalid_view.compid","list.items[].$children.labelInput1input_invalid_view.hidden"],
								"labelInput1input_invalid_text":{
									"depends":["list.items[].$children.labelInput1input_invalid_text.compid","list.items[].$children.labelInput1input_invalid_text.text"]
								}
							},
							"labelInput1input":{
								"depends":["list.items[]._userdata.begin.required.val","list.items[]._userdata","list.items[]._userdata.begin","list.items[]._userdata.begin.readonly","list.items[].$children.labelInput1input.displayText","list.items[].begin","list.items[].$children.labelInput1input.compid","__pageid__","list.items[]._userdata.begin.hidden","list.items[]"]
							},
							"labelInput1label":{
								"depends":[]
							}
						},
						"depends":["list.items[].name","list.items[].selected"]
					}
				}
			}
		}
	}
}
export function createPageConfig(){
	return _createPageConfig(PageClass, template, methods, {"navigationBarTitleText":"请选择..."},{}, dependTree)
}
export function createPage(owner, pageid, props){
	var page = new PageClass(owner, props);
	page.$init(template, methods, pageid);
	return page;
}
