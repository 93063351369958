var fileApi = {
	download: function(url){
		if(window && document && url){
			var iframe = document.createElement('iframe');
			document.body.appendChild(iframe);
			iframe.style.cssText = 'display: none;';
			iframe.src = url;
			setTimeout(function(){
				document.body.removeChild(iframe);
			},30000);
		}
	}	
};

export default fileApi;
