
import {observable, autorun, toJS, untracked} from  "../../lib/mobx/mobx-2.6.2.umd";
import Component from "../../lib/base/component";


function copyObject(dest, src){
	dest = dest || {};
	src = src || {};
	for (var name in src){
		if (src.hasOwnProperty(name)){
			dest[name] = src[name];
		}
	}
}

/**
 * 当前没有考虑statepath改变的情况, 即在list中
 */
export default class PageComp extends Component {
     constructor(page, id, props, context){
        super(page, id, props, context);
        
        var pageProps = this.calProps(context.vars||{});
        this.innerPage = this.props.$createPage(this, this.getPageid(), pageProps || {});
        this.updatePageidDispose = autorun(() => {
        	this.innerPage.setData({__pageid__: this.getPageid()});
        });
        
        this.innerPage.fireEvent("load", {source: this.innerPage, options:{}});
        this.innerPage.onLoad && this.innerPage.onLoad({});
        this.innerPage.fireEvent("ready", {source: this.innerPage});
        this.innerPage.onReady && this.innerPage.onReady();
        this.innerPage.fireEvent("show", {source: this.innerPage});
        this.innerPage.onShow && this.innerPage.onShow();
     }
     
     calProps(vars){
    	var props = {}; 
     	var bindProps = {};
    	var onProps = {};
    	for (var name in this.props){
    		if (this.props.hasOwnProperty(name) && this.isPageProp(name)){
    			if (name.indexOf("bind:") !== -1){
    				let expr = this.props[name];
    				name = name.substr(5);
    				let curVars = {};
    				copyObject(curVars, vars);
    				curVars.$expr = expr;
    				bindProps[name] = this.page[this.props.$executeBinding](curVars);
    			}else if (name.indexOf("on:") !== -1){
    				let expr = this.props[name];
    				name = name.substr(3);
    				if (this.page[expr] && (typeof this.page[expr] === 'function')){
    					onProps[name] = this.page[expr].bind(this.page); //TODO page-comp的on-属性当前只支持绑定page上的方法, 不支持绑定组件上的方法	
    				}else{
    					throw Error("page is not define '" + expr + "' function");
    				}
    				
    			}else{
    				props[name] = this.props[name];
    			}
    		}
    	}
    	
    	copyObject(props, bindProps);
    	copyObject(props, onProps);
    	return props;
     }
     
     isPageProp(name){
    	 return (name!=='$executeBinding') && (name!=='id') && (name!=='$createPage');
     }
     
     buildState(context){
    	 var state = super.buildState(context);
    	 state.data = this.innerPage.data;
    	 return state;
     }
     
     didBuildState(nextState, context){
    	var pageProps = this.calProps(context.vars||{});
    	if (this.shouldUpdatePageProps){
    		untracked(() => {
    			this.innerPage.$updateProps(pageProps);
        	})
    	}else{
    		this.shouldUpdatePageProps = true;
    	}
     }
     
     updateData(data){
 		var options = {};
		options[this.getStatePath() + ".data"] = data;
		this.page.setData(options);
     }

     getPageid(){
         var parentPage = this.page.data.__pageid__ || "";
         parentPage += parentPage ? ";" : "";
         return parentPage + this.getCompid();  
     }
     
     destroy(){
    	 this.innerPage.onUnload && this.innerPage.onUnload();
    	 this.updatePageidDispose();
    	 this.innerPage = null;
    	 super.destroy();
     }
     
     initOperation(){
	 	super.initOperation();
	 	this.defineOperation('refresh',{
		label : '页面刷新',
 			argsDef : [],
 			method : function(args) {
 				return this.owner.refresh();
 			}
	 	});
     }
}

wx.comp = wx.comp || {};
wx.comp.PageComp = PageComp;

