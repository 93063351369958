import Component from "../../lib/base/component";
var interval;
export default class Voice extends Component {
  constructor(page, id, props, context) {
    super(page, id, props, context);
    this.formateRecordTime = '00:00:00';
    this.formatePlayTime = '00:00:00';
    this.recordTime = 0;
    this.playTime = 0;
    this.playing = false;
    this.recording = false;
    this.hasRecord = false;

    //this.tempFilePath 临时音频地址 
  }
  voiceStart(e) {
    if (this.recording){
      return ;
    }
    this.recording = true;
    interval = setInterval(() => {
      this.recordTime += 1;
      this.formateRecordTime = this.formatTime(this.recordTime);
      this.playTime = this.recordTime;
      this._update(this.formateRecordTime)
    }, 1000);
    
    wx.startRecord({
      success:(res)=>{
        this.tempFilePath = res.tempFilePath;
        clearInterval(interval)
      },
      complete: ()=>{
        this.recording = false;
        clearInterval(interval)
      }
    })

    this._update(this.recording)
  }
  playVoice(e) {
    this.stop(e)
    interval = setInterval(() => {
      this.recordTime += 1;
      this.formateRecordTime = this.formatTime(this.recordTime);
      if (this.recordTime >= this.playTime){
        clearInterval(interval);
      }
      this._update(this.formateRecordTime)
    }, 1000);
    
    var tempPath = this.tempFilePath;
    wx.playVoice({
      filePath: tempPath,
      complete:()=>{
        console.log("complete")
      }
    })
  }
  stop(e) {
    wx.stopRecord();
    this.recording = false;
    this.recordTime = 0;
    this.formateRecordTime = "00:00:00"
    clearInterval(interval);
    this._update(this.context)
  }
  remove(e) {
    this.playTime = 0;
    this.tempFilePath ="";
    this.stop(e);
  }

  buildState(context) {
    var state = super.buildState(context);

    var bindStat = ['formateRecordTime', 'formatePlayTime', 'recordTime', 'playTime', 'playing', 'recording', 'hasRecord'];

    bindStat.forEach((val) => {
      state[val] = this[val];
    })

    return state;
  }


  formatTime(time) {
    if (typeof time !== 'number' || time < 0) {
      return time
    }

    var hour = parseInt(time / 3600)
    time = time % 3600
    var minute = parseInt(time / 60)
    time = time % 60
    // 这里秒钟也取整
    var second = parseInt(time)

    return ([hour, minute, second]).map(function (n) {
      n = n.toString()
      return n[1] ? n : '0' + n
    }).join(':')
  }

}

wx.comp = wx.comp || {};
wx.comp.Voice = Voice;
