import React, { Component } from "react";
import WxComponentBase from "../base/WxComponentBase";

export default class WxText extends WxComponentBase {
  constructor(...args) {
    super(...args);
  }

  _htmlEncode(value){
    return value.replace(/&/g,"&amp;").replace(/</g,"&lt;").replace(/>/g,"&gt;").replace(/\"/g,"&quot;").replace(/\'/g, "&apos;").replace(/\n/g, '<br/>');
  }

  htmlEncode(value){
    return _.map(value,(_value,index) => {
      if(_.isString(_value)){
        _value = this._htmlEncode(_value);
        return React.createElement("span", {key: index, dangerouslySetInnerHTML: { __html: _value } });
      }
      return _value;
    });
  }

  doRender() {
    var value = React.Children.toArray(this.props.children);
    var children = this.htmlEncode(value);
    return (
      <output {...this.toDOMAttrs(this.props)} {...this.htmlProps}>
        {children}
      </output>
    );
  }
}
