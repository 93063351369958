import BindComponent from "../../lib/base/bindComponent";

export default class Switch extends BindComponent {
     constructor(page, id, props, context){
        super(page, id, props, context);
     }
     
     //强制将值变成1/0, 因为底层不支持bool类型
     onChange(evt) {
         this.doChange({ value: evt.detail.value ? 1: 0});
     }
}

Switch.EVENT_VALUE_CHANGE = "valuechange";

wx.comp = wx.comp || {};
wx.comp.Switch = Switch;

