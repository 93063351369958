import Component from "../../lib/base/component";
import {observable, autorun, toJS, untracked, transaction} from  "../../lib/mobx/mobx-2.6.2.umd";
import {isArray, isObject, cloneJSON} from "../../lib/base/util";
import fileApi from "./fileapi";
import uaa from "../user/js/uaa";
import ExportExcelImpl from "./ExportExcelImpl"

export default class ExportExcel extends Component{
	constructor(page, id, props, context){
		super(page, id, props, context);
		this.data = props.data;

		let params = {
			data: props.data,
			from: props.from,
			columns: props.columns,
			beforeExportUrl: props.beforeExportUrl,
			forceRefreshOnOpen: props.forceRefreshOnOpen,
			option: props.option,
			templateUrl: props.templateUrl,
			$valFn: props.$valFn,
			$downloadFilenameFn: props.$downloadFilenameFn,
			context: this.context,
			dispatchEventFn: (eventName, data) => {
				data.source = this;
				this.fireEvent(eventName, data);
			}
		}
		this.impl = new ExportExcelImpl(page,params);
	}

	exportExcel(from,downloadFilename,columns,userConfig) {
		if(isArray(columns) && columns.length>0){
			columns = columns.join(',');
		}
		var evtData = {source:this,cancel:false};
		this.fireEvent('beforeExport',evtData);
		if (evtData.cancel)
			return;
		return this.impl._doExport(from,downloadFilename,columns,userConfig);
	}

	setupAndExport(from,downloadFilename) {
		let cuser = uaa.getCurrentUser(true);
		var itemKey = "/" + this.page.getServiceName() + this.page.owner.route + "/" + cuser;
		var itemData = localStorage.getItem(itemKey);
		if (itemData) {
			itemData = JSON.parse(itemData);
			if (itemData.showDlg != 1 && itemData.data && itemData.data.length > 0) {
				return this.impl._doExport(from,downloadFilename,undefined,itemData.data);
			}
		}
		var params = {from: from, downloadFilename: downloadFilename, itemKey: itemKey};
		this.impl.showSetupDialog(params);
	}



	initOperation(){
		super.initOperation();
		this.defineOperation('exportExcel', {
			label : "导出",
			icon : '',
			init : function() {},
			argsDef: [{name:'from',displayName:'数据来源'},{name:'downloadFilename',displayName:'导出文件名'},{name:'columns',displayName:'导出列'}],
			method : function(args) {
				this.owner.exportExcel(args.from,args.downloadFilename,args.columns);
			}
		});
		this.defineOperation('setupAndExport', {
			label : "设置并导出",
			icon : '',
			init : function() {},
			argsDef: [{name:'from',displayName:'数据来源'},{name:'downloadFilename',displayName:'导出文件名'}],
			method : function(args) {
				this.owner.setupAndExport(args.from,args.downloadFilename);
			}
		});
	}
}

wx.comp = wx.comp || {};
wx.comp.ExportExcel = ExportExcel;
