import React, {Component} from "react";
import WxComponentBase from "../base/WxComponentBase";
import cx from "classnames";
import _ from 'lodash';

export default class WxCheckbox extends WxComponentBase{
	constructor(...args){
		super(...args);
		this.state={
				checkBoxChecked:this.toBoolean(this.props.checked)||false
		}
	}

	componentWillReceiveProps(nextProps) {
		if ((nextProps.checked != this.props.checked) ){
			this.setState({
				checkBoxChecked : nextProps.checked
			});
		}
	}

	hasDisabledAttr(){
		return true;
	}

	handleChange(e){
		if(!this.toBoolean(this.props.disabled)){
			const {color}=this.props;
			this.setState({
				checkBoxChecked : !this.state.checkBoxChecked
			});
		}
	}

	doRender(){
		const {color}=this.props;
		const {checkboxBackgorund} = this.props;
		const {shape} = this.props;
		var {checkBoxChecked} = this.state;
		let checkBoxStyle={};
		if(checkBoxChecked && color) {
			checkBoxStyle.color = color ? color : "#09BB07";
		};
		checkboxBackgorund && (checkBoxStyle.background = checkboxBackgorund ? checkboxBackgorund : "#fff");
		shape == "circle" ? checkBoxStyle["border-radius"] = "50%" : checkBoxStyle["border-radius"] = "";
		const disabled=this.toBoolean(this.props.disabled);
		var newProps = _.omit(this.props, ["checked","children"]); //需要排除checked属性
		let forId={};
		if(this.props.id){
			if(this.props.id==this.htmlProps.id){
				forId={
					id:`${this.props.id}-checkbox`
				}
			}else {
				forId={
						id:`${this.htmlProps.id}-checkbox`
				}
			}
		}else {
			forId={
				id:undefined
			}
		}
		var result = (
			<div {...this.toDOMAttrs(this.props)} {...this.htmlProps} {...forId} >
				<div className="wx-checkbox-wrapper" >
					<input  {...this.toDOMAttrs(newProps)}  type="checkbox" checked={checkBoxChecked}
						data-form-type="checkbox"  onChange={this.handleChange.bind(this)} style={{display:"none"}} ref="mockInput" />
					<div className={cx("wx-checkbox-input",{"wx-checkbox-input-checked":(checkBoxChecked)},{"wx-checkbox-input-disabled":disabled})}
					style={checkBoxStyle} ref="checkBoxContainer">
					</div>
					<div>{this.props.children}</div>
				</div>
			</div>
		);
		return result;
	}
}
