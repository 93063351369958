import BindComponent from "../../lib/base/bindComponent";
import {observable,autorun,untracked,toJS} from '../../lib/mobx/mobx-2.6.2.umd';

export default class Breadcrumb extends BindComponent {
     constructor(page, id, props, context){
        super(page, id, props, context);
        
		this.labelList = observable([]);
        this.list = [];
        this.compDisabled = observable(!!props.compDisabled);
     }
     
     buildState(context){
     	var state = super.buildState(context);
     	state.list = toJS(this.labelList);
     	state.length = this.labelList.length;
     	state.disabled = this.compDisabled.get();

     	return state;
     }
     
     setLabel(index, label){
    	 if(index >= 0 && this.labelList.length>index){
    		 this.labelList[index] = label || '';
    	 } 
     }
     
	 push(label,data){
		 this.list.push(data);
		 this.labelList.push(label);
	 }
	 
	 popTo(idx){
		 if(idx>-1){
			 let len = this.list.length-idx;
			 this.list.splice(idx+1,len);
			 this.labelList.splice(idx+1,len);
		 }
	 }
	 
	 getData(idx){
		 if(idx>-1 && idx<this.list.length){
			 return this.list[idx];
		 }
	 }
	 
	 _tapHandle(evt){
		 let idx = evt.currentTarget.dataset.index-0;
		 if(this.labelList.length>idx-1){
			 let oldData = this.getData(this.labelList.length-1);
			 let data = this.getData(idx);
			 this.popTo(idx);
			 this.fireEvent(Breadcrumb.EVENT_POP,{source:this,index:idx,data:data,oldData:oldData});
		 }
	 }
}

Breadcrumb.EVENT_POP = "pop";
wx.comp = wx.comp || {};
wx.comp.Breadcrumb = Breadcrumb;
