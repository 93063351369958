import {isObservableArray, transactionEnd, transactionStart} from "../mobx/mobx-2.6.2.umd";
import _Date from  "./date";
import String from "./string";
import CryptoRequest from 'core/api/network/crypto-request';
var toString = Object.prototype.toString;



/**
 * slm
 为性能优化 参考mobx的BaseAtom  默认不开启单api的transition 开启平台批量transition
 1. 优化点1 修改数据后 连带刺激的计算列 计算schema信息 以及只读 约束 偏移量等计算字段的修改
 2. 优化点2 多次修改造成的多次序列化data信息到渲染层的问题
 3. 优化点3 多次修改造成操作逻辑依赖计算多次问题 包括vue中监听data变化多次问题
 transactionStart("propagatingAtomChange", null, false);
 propagateChanged(this);
 transactionEnd(false);
 */
export function requireBatchTransition(){
	let current;
	requireBatchTransition = ()=>{
		if(!current){
			current = "BatchTransition@" + new Date().getTime();
			transactionStart(current,null,true);
			(window.requestIdleCallback || window.setTimeout)(()=>{
				transactionEnd(current,null,true);
				current = null;
			})
		}
	}
	requireBatchTransition();
}

export function isObject(value){
	var type = typeof value;
    return value != null && (type == 'object' || type == 'function');
}



export function isArray(obj){
	return (toString.call(obj) === '[object Array]') 
		|| (obj instanceof Array)
		|| isObservableArray(obj);
}

export function isBoolean(value) {
	return typeof value === 'boolean';
}

export function isString(value){
	return toString.call(value) === '[object String]';
}

export function isFunction(value){
	return toString.call(value) === '[object Function]';
}

export function toArray(iterable, start, end) {
	if (!iterable || !iterable.length) {
		return [];
	}

	if (typeof iterable === 'string') {
		iterable = iterable.split('');
	}

	return Array.prototype.slice.call(iterable, start || 0, end || iterable.length);
}

export  function iif(condition,trueValue,falseValue){
	return condition?trueValue:falseValue;
}

export function arrayPushObj(obj){
	try
	{
		if(!obj){
			return [];
		}
		var _arr = [];
		_arr.push(obj);
		return _arr;
	}catch(e){
		console.log(e);
		return [];
	}
}

export function arrayPush(array,obj){
	try
	{
		if(!obj){
			return array;
		}
		if(Object.prototype.toString.call(array)!='[object Array]'){
			return array;
		}
		array.push(obj);
		return array;
	}catch(e){
		console.log(e);
		return array;
	}
}

export function arraySplice(array,obj){
	try
	{
		if(!obj){
			return array;
		}
		if(Object.prototype.toString.call(array)!='[object Array]'){
			return array;
		}
		for(var i=0,count=array.length;i<count;i++){
			if(array[i]==obj){
				array.splice(i,1);
				return array;
			}
		}
	}catch(e){
		console.log(e);
		return array;
	}
}

function isWindowRes(url){
	if (url.indexOf("?") !== -1){
		url = url.substr(0, url.indexOf("?"));
	}
	return (url.indexOf(".w") == (url.length-2));
}

function prepareWindowUrl(url){
	var index = url.indexOf("?");
	var search = "";
	if (index !== -1){
		search = url.substr(index);
		url = url.substr(0, index);
	}
	url = url.substr(0, url.length-2) + search;
	return url;
}
//这个函数 为toResUrl服务 resUrl只支持$UI
function prepareBaseUrl(url, isCrossServiceUrl, serviceName, contextPath){
	var ret = "";
	if (serviceName && contextPath){
		//wxsys资源在entry下
		if (url.indexOf("/wxsys/") == 0){
			ret = "/entry/mobileapp";
		}else{
			ret = "/" + serviceName + "/" + contextPath;
		}
	}else if (wx.App.isMicroService && !isCrossServiceUrl){
		//wxsys资源在entry下
		if (url.indexOf("/wxsys/") == 0){
			ret = "/entry/mobileapp";
		}else{
			var page = getCurrentPages()[getCurrentPages().length-1].$page;
			ret = "/" + page.basePath + "/" + page.contextPath;
		}
	}else if ( contextPath){
		ret = "/" + contextPath;
	}
	return ret;
}

// TODO url必须以$UI开头
export function toResUrl(url, isCrossServiceUrl, serviceName, contextPath,parentPath=""){
	url = String.trim(url);
	if (!url || (url.indexOf(".") == -1) 
			|| (url.indexOf("https://")==0)
			|| (url.indexOf("http://")==0)) 
		return url;

	if (url.indexOf("/")==0){
		isCrossServiceUrl = true;
	}
	if (url.indexOf("$UI/") == "0"){
		url = url.substring(3);
	}else if (url.indexOf("$model/UI2/") == 0){
		url = url.substring(10);
	}
	var baseUrl = prepareBaseUrl(url, isCrossServiceUrl, serviceName, contextPath);
	if (isWindowRes(url)){
		if (baseUrl){
			url = baseUrl + url;
		}
		return prepareWindowUrl(url);
	}else if (window && window.isMicroService) {
		let base = "";
		let context = "";
		if (serviceName && contextPath){
			base = serviceName;
			context = contextPath;
		}else{
			let currentReactPage = getCurrentReactPage();
			base = currentReactPage.props.basePath;
			context = currentReactPage.props.contextPath;
		}
		return wx.App.baseUrl + parentPath + "/" + base + "/" + context + url;
	}else {
		if (wx.App.isMicroService){
			return wx.App.baseUrl + baseUrl + url;
		}else{
			return wx.App.resPath + url;	
		}
	}
}

export function hint(title, duration){
	wx.showToast({
		title: title,
		duration: duration,
		mask: true,
		icon: 'success'
	});
}

export function confirm(content, okFn, cancelFn, showCancel){
	wx.showModal({
		title: '提示',
		content: content || '',
		showCancel: showCancel,
		success: function(res) {
			if (res.confirm) {
				okFn && okFn();
		    } else if (res.cancel) {
		    	cancelFn && cancelFn();
		    }
		}
	});
}

// 增加数据相关函数
export function count(data){
	if(data && isFunction(data.count)) return data.count();
	else return 0;
}

export function sum(data,col){
	if(data && isFunction(data.sum)) return data.sum(col);
	else return 0;
}

export function avg(data,col){
	if(data && isFunction(data.avg)) return data.avg(col);
	else return 0;
}

export function min(data,col){
	if(data && isFunction(data.min)) return data.min(col);
	else return 0;
}

export function max(data,col){
	if(data && isFunction(data.max)) return data.max(col);
	else return 0;
}

export function total(data){
	if(data && isFunction(data.getTotal)) return data.getTotal();
	else return 0;
}

export function cloneJSON(obj, isTransformDate){
	if (obj){
		// 此处不处理日期格式，要求像在toJSON方法中自己处理完
		var ret = JSON.parse(JSON.stringify(obj));
		/*
		 * var ret = JSON.parse(JSON.stringify(obj, isTransformDate ?
		 * function(k, v){ if (typeof v == 'string' && (v.substr(-1, 1) == "Z") &&
		 * (v.indexOf("T")!=-1)) { try { v = new Date(v); return
		 * _Date.toString(v, _Date.DEFAULT_FORMAT1); } catch (e) { return v; }
		 * }else{ return v; } } : null));
		 */	
		return ret;
	}else{
		return obj;
	}
}

function _isEmptyValue(value) {
	return value === undefined || value === null || value === "";
}

function testNumber(val) {
	return _isEmptyValue(val) || /^(([-+]?([0-9]+(\.[0-9]*)?)|(\.[0-9]+))([eE][-+]?[0-9]+)?|-?INF|NaN)$/.test(val);
}

function testInteger(val) {
	return _isEmptyValue(val) || /^-?[0-9]\d*$/.test(val);
}

function testEmail(val) {
	return _isEmptyValue(val) || /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/.test(val);
}

function testMobile(val) {
	return _isEmptyValue(val) || /^1[3456789]\d{9}$/.test(val);
}

function testTelephoneNumber(val){
	 return _isEmptyValue(val) || /0\d{2,3}-[1-9]\d{6,7}/.test(val);
}

function testTelephone(val){
	 return this.testTelephoneNumber(val);
}

function testChinese(val){
	 return _isEmptyValue(val) || /^[\u2E80-\u9FFF]+$/.test(val);
}

function testNotSpecialChar(val){
	 if(!_isEmptyValue(val)){
		 var pattern = new RegExp("[`~!@#$^&*()=|{}':;',\\[\\].<>《》/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？ ]");
		 return !pattern.test(val);
	 }
	 return true;
}


function testIDCard(id){
	 if(!_isEmptyValue(id)) {
	     // 1 "验证通过!", 0 //校验不通过
	        var format = /^(([1][1-5])|([2][1-3])|([3][1-7])|([4][1-6])|([5][0-4])|([6][1-5])|([7][1])|([8][1-2]))\d{4}(([1][9]\d{2})|([2]\d{3}))(([0][1-9])|([1][0-2]))(([0][1-9])|([1-2][0-9])|([3][0-1]))\d{3}[0-9xX]$/;
	        //号码规则校验
	        if(!format.test(id)){
	            return false;
	        }
	        //区位码校验
	        //出生年月日校验   前正则限制起始年份为1900;
	        var year = id.substr(6,4),//身份证年
	            month = id.substr(10,2),//身份证月
	            date = id.substr(12,2),//身份证日
	            time = Date.parse(month+'-'+date+'-'+year),//身份证日期时间戳date
	            now_time = Date.parse(new Date()),//当前时间戳
	            dates = (new Date(year,month,0)).getDate();//身份证当月天数
	        if(time>now_time||date>dates){
	            return false;
	        }
	        //校验码判断
	        var c = [7,9,10,5,8,4,2,1,6,3,7,9,10,5,8,4,2];   //系数
	        var b = ['1','0','X','9','8','7','6','5','4','3','2'];  //校验码对照表
	        var id_array = id.split("");
	        var sum = 0;
	        for(var k=0;k<17;k++){
	            sum+=parseInt(id_array[k])*parseInt(c[k]);
	        }
	        if(id_array[17].toUpperCase() != b[sum%11].toUpperCase()){
	            return false;
	        }
	        return true;
	}
	return true; 
}

function testBankNO(bankno) {
	if(_isEmptyValue(bankno)) return true; 
   var lastNum = bankno.substr(bankno.length - 1, 1);//取出最后一位（与luhm进行比较）
   var first15Num = bankno.substr(0, bankno.length - 1);//前15或18位
   var newArr = [];

   for (var i = first15Num.length - 1; i > -1; i--) { //前15或18位倒序存进数组
       newArr.push(first15Num.substr(i, 1));
   }

   var arrJiShu = []; //奇数位*2的积 <9
   var arrJiShu2 = []; //奇数位*2的积 >9
   var arrOuShu = []; //偶数位数组
   for (var j = 0; j < newArr.length; j++) {
       if ((j + 1) % 2 == 1) {//奇数位
           if (parseInt(newArr[j]) * 2 < 9)
               arrJiShu.push(parseInt(newArr[j]) * 2); else
               arrJiShu2.push(parseInt(newArr[j]) * 2);
       }
       else //偶数位
           arrOuShu.push(newArr[j]);
   }

   var jishu_child1 = [];//奇数位*2 >9 的分割之后的数组个位数
   var jishu_child2 = [];//奇数位*2 >9 的分割之后的数组十位数
   for (var h = 0; h < arrJiShu2.length; h++) {
       jishu_child1.push(parseInt(arrJiShu2[h]) % 10);
       jishu_child2.push(parseInt(arrJiShu2[h]) / 10);
   }

   var sumJiShu = 0; //奇数位*2 < 9 的数组之和
   var sumOuShu = 0; //偶数位数组之和
   var sumJiShuChild1 = 0; //奇数位*2 >9 的分割之后的数组个位数之和
   var sumJiShuChild2 = 0; //奇数位*2 >9 的分割之后的数组十位数之和
   var sumTotal = 0;
   for (var m = 0; m < arrJiShu.length; m++) {
       sumJiShu = sumJiShu + parseInt(arrJiShu[m]);
   }
   for (var n = 0; n < arrOuShu.length; n++) {
       sumOuShu = sumOuShu + parseInt(arrOuShu[n]);
   }
   for (var p = 0; p < jishu_child1.length; p++) {
       sumJiShuChild1 = sumJiShuChild1 + parseInt(jishu_child1[p]);
       sumJiShuChild2 = sumJiShuChild2 + parseInt(jishu_child2[p]);
   }
   //计算总和
   sumTotal = parseInt(sumJiShu) + parseInt(sumOuShu) + parseInt(sumJiShuChild1) + parseInt(sumJiShuChild2);
   //计算Luhm值
   var k = parseInt(sumTotal) % 10 == 0 ? 10 : parseInt(sumTotal) % 10;
   var luhm = 10 - k;
   return lastNum == luhm;
}

function testLength(val, min, max) {
	if (val === undefined || val === null) {
		if (min <= 0 && max >= min) {
			return true;
		} else {
			return false;
		}
	}
	if (val === "") {
		if (min <= 1 && max >= min) {
			return true;
		} else {
			return false;
		}
	}
	return (typeof (val) === 'string' && (min === -1 || val.length >= min) && (max === -1 || val.length <= max));
}


function getColuRuleValue(owner, pName, rName){
	try{
		return owner.$data.getColRuleValue(pName, rName)
 	}catch(_$e){
	 return null;
 	}
}

function mergeUserdata(userdata, ext){
	ext = ext || {};
	let ruleNames = ["hidden", "readonly", "required", "constraint"];
	for (let name in ext){
		if (ext.hasOwnProperty(name)){
			let item = ext[name];
			if (userdata[name]){
				for (let i=0; i<ruleNames.length; i++){
					let ruleName = ruleNames[i];
					if (item.hasOwnProperty(ruleName)){
						userdata[name] = item[ruleName];
					}
				}
			}else{
				userdata[name] = item;
			}
		}
	}
}

function prepareUserData(owner, userdata, names, disableExt){
	let allNames = names;
	if (owner.$data && owner.$data._dataChangedUUID){
		owner.$data._dataChangedUUID.get(); //数据变化后触发userdata重新计算
	}
	if (!disableExt && owner.$schema && (typeof owner.$schema.$getExtUserdata === "function")){
		let ext = owner.$schema.$getExtUserdata.call(owner) || {};
		mergeUserdata(userdata, ext); 
		if (owner._$queryExtendProps){
			let extProps = owner._$queryExtendProps();
			allNames = names.concat(extProps);
		}
	}
	
	for (var i=0; i<allNames.length; i++){
		var name = allNames[i];
		userdata[name] = userdata[name] || {};
		var propRule = userdata[name];
		let hideRule = getColuRuleValue(owner, name, 'hidden');
		propRule.hidden = propRule.hidden || hideRule;
		//虚拟节点全部只读
		let readonlyRule = getColuRuleValue(owner, name, 'readonly');
		propRule.readonly = propRule.readonly || readonlyRule || (owner.$data?owner.$data.getReadonly():false) 
			|| (owner.$data?owner.$data.isVirtualRow(owner):false);
		propRule.required	= propRule.required || {};
		
		/**
		 * defVal存储表达式的值, val存储开启检查后表达式的值 
		 * 支持数据集定义列必填，但检查时间是保存后， 在保存前需要出红星，但不出红框和错误提示
		 */
		propRule.required.defVal = propRule.required.val;  
		
		let requiredRule = getColuRuleValue(owner, name, 'required');
		propRule.required.val = propRule.required.val || requiredRule;
		
		let constraintRule = getColuRuleValue(owner, name, 'constraint') || {val: true};
		propRule.constraint = propRule.constraint || {val: true};
		if (propRule.constraint.val){
			//如果为true时取colRule上的
			propRule.constraint.val = constraintRule.val;
		}
		if (constraintRule.msg){
			propRule.constraint.msg = constraintRule.msg;
		}
		
		
		
		
		//支持只检查当前行和检查时机
		if (owner && owner.$data && (!owner.$data.enabledCheck.get() 
			|| (owner.$data.checkRange==="editRow" && typeof owner.getState=="function" && owner.getState()!='edit' && owner.getState()!='new')	
			|| ((owner.$data.checkRange==="currentRow") && (owner.$data.getCurrentRow() !== owner)))){
			if (propRule.required) propRule.required.val = false;  //表示不检查
			delete propRule.constraint;
		}
	}
	
	
	
	//性能优化, 如果为false, 不生成相关的信息
	for (var i=0; i<allNames.length; i++){
		var name = allNames[i];
		var propRule = userdata[name];
		if (!propRule) continue;
		if (!propRule.hidden && !propRule.readonly && (!propRule.required || !propRule.required.defVal) && (!propRule.constraint || propRule.constraint.val)){
			delete userdata[name];
		}else {
			if (!propRule.hidden){
				delete propRule.hidden;
			}
			if (!propRule.readonly){
				delete propRule.readonly;
			}
			if (propRule.required && !propRule.required.defVal){
				delete propRule.required;
			}
			//约束规则值为false时，表示不满足条件
			if (!propRule.constraint || propRule.constraint.val){
				delete propRule.constraint;
			}
		}
	}	
	return userdata;
}

function showError(info){
	let msg = info.message || '未知错误';
	wx.showToast({duration:3000,title:msg,icon:'none'});
}

function showModalError(info){
	let msg = info.message || '未知错误';
	wx.showModal({
	    showCancel:false,
	    title: '友情提示',
	    content: msg
	  });
}


//刷新页面后清空sessionStorage 屏蔽各种浏览器差异。
window && sessionStorage.clear();
function requestCacheWrapper(requestFn,level = "session"){
	if(!(window && document)){
		
		return requestFn; 
	}	
	let storage = level == "session"? sessionStorage : localStorage;
	return async function(params){
		let pageRequestFn = requestFn.bind(this);
		try {
			let url = params.url;
			let data = params.data;
			let cacheKey = url + (typeof data == "object"?JSON.stringify(data):data);
			if(sessionStorage[cacheKey]){
				return JSON.parse(storage[cacheKey]);
			}
			let result = await pageRequestFn(params);
			storage.setItem(cacheKey,JSON.stringify(result));
			return result;
		}catch(e){
			console.error(e);
		}
	};
}


export function getGlobalValue(key){
	if (window){
		if(!window.__justep) window.__justep = {};
		if(!window.__justep._userData) window.__justep._userData = {}; 
		return window.__justep._userData[key];
	}else{
		if (!global.__justep) global.__justep = {};
		if(!global.__justep._userData) global.__justep._userData = {}; 
		return global.__justep._userData[key];
	}
}

export function setGlobalValue(key, value){
	if (window){
		if(!window.__justep) window.__justep = {};
		if(!window.__justep._userData) window.__justep._userData = {}; 
		window.__justep._userData[key] = value;
	}else{
		if(!global.__justep) global.__justep = {};
		if(!global.__justep._userData) global.__justep._userData = {}; 
		global.__justep._userData[key] = value;
	}
}

export function classConcat(classInfo){
	classInfo = classInfo || {};
	var classArray = [];
	for (var ojbKey in classInfo) {
		if(classInfo[ojbKey]){
			classArray.push(ojbKey);
		}
	}
	return classArray.join(" ");
}

export function styleConcat(styleInfo){
	styleInfo = styleInfo || {};
	var styleArray = [];
	for (var ojbKey in styleInfo) {
		if(styleInfo[ojbKey]){
			styleArray.push(ojbKey + ":" + styleInfo[ojbKey]);
		}
	}
	return styleArray.join(";");
}

export function resolve(value){
	return Promise.resolve(value);
}


export const RichText = {
	parseForUI: function($page,value,sourceServiceName){
		let wrapper = document.createElement('div')
		wrapper.innerHTML = value || "";

		let baseUrl = $page.getServiceUrl("/",sourceServiceName);
		let relativeBaseUrl = baseUrl.split(location.host).pop();
		let compatiablePrefixServiceNamePath = "/" + $page.serviceName + "/storage";

		wrapper.querySelectorAll('img').forEach((img)=>{
			let src = img.getAttribute('src');
			if(src.indexOf("://") != -1 || src.indexOf("data:") == 0){

			}else if(src.indexOf(compatiablePrefixServiceNamePath) == 0){
				//特殊处理存储的url中带serviceName的场景
				src = src.replace(compatiablePrefixServiceNamePath,"/storage");
				src = $page.getServiceUrl(src,sourceServiceName);
				img.setAttribute('src',src);
			}else {
				src = $page.getServiceUrl(src,sourceServiceName);
				img.setAttribute('src',src);
			}
		});
		return wrapper.innerHTML;
	},
	stringifyForData: function($page,value,sourceServiceName){
		let wrapper = document.createElement('div')
		wrapper.innerHTML = value;
		//去掉带serviceName和parentpath部分
		let baseUrl = $page.getServiceUrl("/",sourceServiceName);
		let relativeBaseUrl = baseUrl.split(location.host).pop();

		let compatiablePrefixServiceNamePath = "/" + $page.serviceName + "/storage";
		wrapper.querySelectorAll('img').forEach((img)=>{
			let src = img.getAttribute('src');
			if(src && src.indexOf("data:") == 0){
				return;
			}
			src = CryptoRequest.decode(src);
			if(src.indexOf(baseUrl) == 0){
				src = src.replace(baseUrl,"/");
				img.setAttribute('src',src);
			}else if(src.indexOf(relativeBaseUrl) == 0){
				src = src.replace(relativeBaseUrl,"/");
				img.setAttribute('src',src);
			}else if(src.indexOf(compatiablePrefixServiceNamePath) == 0){
				//特殊处理存储的url中带serviceName的场景
				src = src.replace(compatiablePrefixServiceNamePath,"/storage");
				img.setAttribute('src',src);
			}
		});
		return wrapper.innerHTML;
	}
}




var Util = {};
Util.resolve = resolve;
Util.getGlobalValue = getGlobalValue;
Util.setGlobalValue = setGlobalValue;
Util.prepareUserData = prepareUserData;
Util.isObject = isObject;
Util.isArray = isArray;
Util.isBoolean = isBoolean;
Util.isString = isString;
Util.isFunction = isFunction;
Util.toArray = toArray;
Util.arrayPushObj = arrayPushObj;
Util.arrayPush = arrayPush;
Util.arraySplice = arraySplice;
Util.iif = iif;
Util.hint = hint;
Util.confirm = confirm;
Util.toResUrl = toResUrl;

Util.showError = showError;
Util.showModalError = showModalError;

Util.testNumber = testNumber;
Util.testInteger = testInteger;
Util.testEmail = testEmail;
Util.testMobile = testMobile;
Util.testMobilephoneNumber = testMobile;
Util.testBankNO = testBankNO;
Util.testIDCard = testIDCard;
Util.testLength = testLength;
Util.testChinese = testChinese;
Util.testNotSpecialChar = testNotSpecialChar;

Util.count = count;
Util.sum = sum;
Util.avg = avg;
Util.min = min;
Util.max = max;
Util.total = total;
Util.cloneJSON = cloneJSON;
Util.requestCacheWrapper = requestCacheWrapper;

Util.classConcat = classConcat;
Util.styleConcat = styleConcat;
Util.RichText = RichText;
export default Util;
