import Component from "../../lib/base/component";
import {observable, autorun, toJS, untracked} from  "../../lib/mobx/mobx-2.6.2.umd";
import renderComponent from "../../lib/base/renderComponent";
import {cloneJSON} from "../../lib/base/util";

export default class WxContainer extends Component{

	constructor(page, id, props, context) {
	    super(page, id, props, context);

		//this.componentRendered = observable(false);


	}



/**
	buildState(context){


		var state = super.buildState(context);
		state.componentRendered = this.componentRendered.get();
		this.template = this.props.$template || null;



		//构建h5运行时和上层状态数之间的关系
		var key = this.page.comp(this.props.dataProviderId).getProvideData('key',context.vars.$item);
		this.page.bridgeInfo = this.page.bridgeInfo || {};
		this.page.bridgeInfo[this.id] = this.page.bridgeInfo[this.id] || {};
        this.page.bridgeInfo[this.id][key] = this.getCompid();

    	return state;
	}


	_copyVars(vars){
    	var result = {};
    	for (var key in vars){
    		result[key] = vars[key];
    	}
    	return result;
	}

	onRenderTemplate(params){
		if(this.props.item){
			//子组件的更新依赖不进入list组件生命周期中
			var vars = this.context.vars;
			untracked(() => {
				//释放无用的组件
				this.templateComp && this.templateComp.forEach(function(c){
					c.destroy();
				});

				//hcr
				var path = this.getStatePath();
				var itemPath = path;

				var curVars = this._copyVars(vars);

				if(this.props.dataProviderId){
					curVars[this.props.item] = this.page.comp(this.props.dataProviderId).getProvideData(this.props.itemName,params);
					if(this.props.index){
						curVars[this.props.index] = this.page.comp(this.props.dataProviderId).getProvideData(this.props.indexName,params);
					}

					if (this.templateComp){
						for (var i=0; i < this.templateComp.length; i++){
							this.templateComp[i]._update({vars: curVars, parentPath: itemPath});
						}
					}else{
						this.templateComp = renderComponent(this.page, this.template, itemPath, curVars);
					}
				}
			});
			this.componentRendered.set(true);
		}
	}

	//创建自定义上下文template内的组件实例
	didBuildState(state, context){
		this.context = context;
		super.didBuildState(state, context);
	}
	 */
}

wx.comp = wx.comp || {};
wx.comp.WxContainer = WxContainer;
