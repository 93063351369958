import PageImpl from "../../../lib/base/pageImpl";
import Comp from "../dialogSelect";
import {observable, extendObservable, autorun, toJS, isObservableArray, isObservableObject, isObservable } from  "../../../lib/mobx/mobx-2.6.2.umd";
import {cloneJSON} from "../../../lib/base/util";
import Breadcrumb from "../../../comps/breadcrumb/breadcrumb";

const ID_COL_NAME = "__id_";
const SELECTED_COL_NAME = "__selected_";
export default class IndexPage extends PageImpl {
	constructor(...args){
		super(...args);
        this.dataUUID = observable();
		this.dataDef = null;
	}

    getLabels(){
        let v = Comp.getDataDef(this.dataUUID.get());
        if(v && v.labels){
            return v.labels;
        }
        return [];
    }

    getLabel(listitem,labelItem){;
        return listitem[labelItem.name];
    }

    onSearch(event){
        let treeComp = this.comp('tree');
        let row = treeComp.current.get();
        event.refreshOption.parentRow = row;
    }
    
	getItems(){
        let v = Comp.getDataDef(this.dataUUID.get());
        if(v && v.data){
        	let idCol = v.data.getIdColumn();
			let selectedCol = v.selectedCol;
            this.comp('list').setData(v.data);

            let treeComp = this.comp('tree');
            let row = treeComp.current.get();
            let data = v.data;
		 	let dataValues = row?data.getChildren(row):data.value;
            
            let items = cloneJSON(toJS(dataValues, true, null, true), true);
            if(items && items.length>0){
            	let comp = Comp.getComp(this.dataUUID.get());
            	for(let i=items.length-1;i>=0;i--){
            		let item = items[i];
            		if(comp){
            			if(comp && !comp.execFilter(item)){
            				items.splice(i,1);
            				continue;
            			}            			
            		}
        			ID_COL_NAME!==idCol && (item[ID_COL_NAME] = item[idCol]);
        			if(SELECTED_COL_NAME!==selectedCol){
        				item[SELECTED_COL_NAME] = item[selectedCol];
        				item._userdata[SELECTED_COL_NAME] = {readonly: false};
        			}
            	}
            }
            return items;
        }
        return [];
	}

	isMultiSelection(){
		return "true"===this.params.multiSelection;	
	}
	
    onSelectTap(event){
		if(this.dataDef && this.dataDef.data){
			let data = this.dataDef.data;
			let selectedCol = this.dataDef.selectedCol;
			let idCol = data.getIdColumn();
			let idVal = event.context && event.context.listitem[idCol];
			data.each((p)=>{
				let id = p.row[idCol];
				if(!this.isMultiSelection()){
					p.row[selectedCol]=(id==idVal?"1":"");
				}else if(id==idVal){
					p.row[selectedCol]=(p.row[selectedCol]=="1"?"":"1");
					return false;
				}
			});
		}
    }
    
    getRowLabel(row){
    	let labels = this.getLabels();
    	if(labels && labels.length>0){
    		let label = row[labels[0].name] || '';
    		return label.length<=10?label:(label.substring(0,10)+'...')
    	}
    	return "";
    }
    
    clearFilter(){
    	this.comp("searchBar").clearDataFilter();
    }
    
	onChildrenTap(evt){
		if(this.dataDef && this.dataDef.data){
			let data = this.dataDef.data;
			let idCol = data.getIdColumn();
			let rowid = evt.context.$item[idCol];
			let row = data.getRowByID(rowid);
			
			this.clearFilter();
			
			let treeComp = this.comp('tree');
			let oldParentRow = treeComp.current.get();
			let oldParentRowid = (oldParentRow && oldParentRow[idCol])||""; 
			treeComp.getBarComp().push(this.getRowLabel(row), row);//
			if(!data.isLoaded(row)){
				data.refreshData({parentRow:row});
			}
			treeComp.current.set(row);
			treeComp._resetSelectAllState();
			
			this.currentParentRowChange(oldParentRowid, rowid);
		}
	 }

    setHideSearchBar(b){
    	let v = b?"true":"false";
    	this.comp("ctrlData").setValue("hideSearchBar",v);
    }
    
    currentParentRowChange(oldRowId, newRowId){
    	let searchBar = this.comp("searchBar");
    	var value = searchBar.getInputValue();
    	this.searchTexts[oldRowId] = value;
    	searchBar.setInputValue(this.searchTexts[newRowId] || "");
    }
    
    onPageLoaded(event){
    	this.searchTexts = {};
		if(this.params.compid){
			this.dataDef = Comp.getDataDef(this.params.compid);
			let v = this.dataDef; 
			if(v && v.filters && v.data){
				let searchBar = this.comp('searchBar');
				searchBar.setData(v.data);
				let cols = [],hideSearchBar = true;
				v.filters.forEach((filter, i)=>{
					if('smart'===filter.type){
						let filterCols = filter.filterCols;
						if(filterCols){
							filterCols = filterCols.split(',');
							cols.push.apply(cols, filterCols);
							hideSearchBar = false;
						}
					}else{
						filter.filterCol && (cols.push(filter.filterCol),hideSearchBar = false);
					}
				});
				searchBar.setFilterProps(cols.join());
				this.setHideSearchBar(hideSearchBar);
			}else{
				this.setHideSearchBar(true);
			}
			
			let idCol = v.data.getIdColumn();
			let treeComp = this.comp('tree');
			treeComp.getBarComp().setLabel(0, v.treeRootLabel||"一级");
			treeComp.getBarComp().on(Breadcrumb.EVENT_POP,(evt)=>{
				this.clearFilter();
				let rowid = (evt.data && evt.data[idCol])||"";
				let oldParentRowid = (evt.oldData && evt.oldData[idCol])||"";
				this.currentParentRowChange(oldParentRowid, rowid);
			});
		}
        this.dataUUID.set(this.params.compid);
	}

/*特殊代码目前保留,当组件绑定外部传入data时需要下面函数重载
	comp(id){
		let comp = super.comp(id);
		if(!comp && this.dataDef && this.dataDef.data && this.dataDef.data.id==id){
			comp = this.dataDef.data;
		}
		return comp;
	}
*/
	
    onOkTap(event){
        this.navigateBack({
            params: {
              isOk: true,
              compid: this.dataUUID.get()
            }
        });
	}
}
