import _createPageConfig from '../../../lib/base/createPageConfig';
import PageClass from './authorize.user';
import '../../row/css/row.css'; 
import '../../page/page'; 
import '../../page/css/page.css'; 
import '../../commonOperation/commonOperation'; 
import '../../icon/css/icon.css'; 
import '../../text/text'; 
import '../../toptips/toptips'; 
import '../../toptips/css/toptips.css'; 
import '../../loading/loading'; 
import '../../loading/css/loading.css'; 
import '../../button/button'; 
import '../../button/css/button.css'; 
import '../../wxApi/wxApi'; 
var methods = {
$evtH_button1_tap: function({$event,$row,$data,$item,params,$page,props}){
let $$$args = arguments[0];
	let args={};
	 $page.$compByCtx('commonOperation',$event.source).executeOperation('close', args, $$$args);

}

}
var template = [
	{
		"cls":wx.compClass('$UI/wxsys/comps/page/page'),
		"props":{
			"id":"page"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/loading/loading'),
		"props":{
			"loadingNum":0,
			"id":"__auto1"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/wxApi/wxApi'),
		"props":{
			"id":"wxapi"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/commonOperation/commonOperation'),
		"props":{
			"id":"commonOperation"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/text/text'),
		"props":{
			"id":"text",
			"text":"需要您授权获取您的公开信息（昵称、头像等）"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/button/button'),
		"props":{
			"id":"button"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/button/button'),
		"props":{
			"id":"button1"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/toptips/toptips'),
		"props":{
			"id":"__toptips__"
		}
	}
];
var dependTree = {
	"page":{
		"__toptips__":{
			"__auto8":{
				"depends":["__toptips__.msgs","__toptips__.items[].typeClass","__toptips__.items[].show","__toptips__.items[].message"]
			},
			"depends":["__toptips__.show","__toptips__.compid"]
		},
		"view":{
			"depends":[]
		},
		"inVisibleCompContainer":{
			"depends":[]
		},
		"depends":["page.compid"],
		"__auto1":{
			"depends":["__auto1.loadingAfterShow","__auto1.loadingNum","__auto1.compid"],
			"__auto2":{
				"__auto7":{
					"depends":[]
				},
				"depends":[],
				"__auto5":{
					"depends":[]
				},
				"__auto6":{
					"depends":[]
				},
				"__auto3":{
					"depends":[]
				},
				"__auto4":{
					"depends":[]
				}
			}
		},
		"row":{
			"col":{
				"depends":[]
			},
			"depends":[],
			"col2":{
				"depends":[]
			},
			"col1":{
				"button":{
					"depends":["button.debounce","button.compid","__pageid__"],
					"icon":{
						"depends":[]
					},
					"view1":{
						"depends":[]
					}
				},
				"depends":[],
				"text":{
					"depends":["text.compid","text.text"]
				},
				"view2":{
					"depends":[]
				},
				"button1":{
					"depends":["button1.debounce","button1.compid","__pageid__"],
					"view3":{
						"depends":[]
					},
					"icon2":{
						"depends":[]
					}
				},
				"icon2":{
					"depends":[]
				}
			}
		}
	}
}
export function createPageConfig(){
	return _createPageConfig(PageClass, template, methods, {"navigationBarTitleText":"授权"},{}, dependTree)
}
export function createPage(owner, pageid, props){
	var page = new PageClass(owner, props);
	page.$init(template, methods, pageid);
	return page;
}
