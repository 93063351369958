import Component from "../../lib/base/component";
import BindComponent from "../../lib/base/bindComponent";
import {observable, autorun, toJS, untracked} from  "../../lib/mobx/mobx-2.6.2.umd";
import renderComponent from "../../lib/base/renderComponent";
import {cloneJSON} from "../../lib/base/util";


export default class ReactComponent extends BindComponent{
     constructor(page, id, props, context){
        super(page, id, props, context);
        this.template = this.props.$template || null;
        this.data = this.props.data || "";

        this.item = this.props.item || "item";
        this.index = this.props.index || "index";
        this.key = this.props.key || "";

        this.children = []; //结构 [{comps: [], key: xx}, {comps: [], key: xx}]
     }

     _hasData(){
    	 return !!this.data;
     }
     
     //TODO reactjs事件取计算行可能有问题
     getEventVars(event){
    	 var result = {};
    	 if (this._hasData()){
        	 var currentTarget = event.currentTarget;
        	 if (event.data && event.data.originalEvent){
        		 currentTarget = event.data.originalEvent.currentTarget;
        	 }
        	 if (currentTarget && currentTarget.dataset && currentTarget.dataset.hasOwnProperty("index")){
            	 result[this.index] = currentTarget.dataset.index; 
            	 if (this.itemsOB.length > result[this.index]){
            		 result[this.item] = toJS(this.itemsOB[result[this.index]]);
            		 result.$item = this.itemsOB[result[this.index]];
            	 }
        	 }
        	 
        	 result.$data = this._getData();
    	 }
    	 return result;
     }

     _getData(){
    	 if (this.props.data){
    		 return this.getDataByContext(this.props.data);
    	 }
    	 return null
     }

     destroy(){
    	 this.destroyChildren();
    	 super.destroy();
     }


     calItems(vars){
        var result = observable([]); 
        if (this.props.$items){
            var items = this.page[this.props.$items](vars) || [];
            if (this.props.$filter && (items.length > 0)){
                var newVars = this._copyVars(vars);
            	for (var i=0; i<items.length; i++){
                    var item = items[i];
                    newVars[this.item] = item;
                    newVars[this.index] = i;   //计算filter时, 可以引用index
                    if (this.page[this.props.$filter](newVars)){
                   		result.push(item);
                    }
                }
            }else{
            	result = items;
            }
        }

        return result;
     }


    findChildByKey(oldChildren, key) {
        if (key){
            for (var i=0; i<oldChildren.length; i++){
                if (oldChildren[i].key === key){
                    return oldChildren.splice(i, 1)[0];
                }
            }
        }
        return null;
    }

    createChild(key, path, curVars){
        var result = {key: key, comps: []};
        if (this.template){
            result.comps = renderComponent(this.page, this.template, path, curVars);
        }

        return result;
    }

    updateChild(child, path, vars){
        for (var i=0; i<child.comps.length; i++){
            child.comps[i]._update({vars: vars, parentPath: path});
        }    
    }
     
    destroyChildren(){
        if (this.children){
        	this.children.forEach(function (child) {
        		if (child) {
        			child.comps.forEach(function (c) {
        				c.destroy();
        			});
        		}
            });
        }
        this.children = [];
    }
    
    _copyVars(vars){
    	var result = {};
    	for (var key in vars){
    		result[key] = vars[key];
    	}
    	return result;
    }
    
    
    buildState(context){
    	var state = super.buildState(context);
    	if (this._hasData()){
        	this.itemsOB = this.calItems(context.vars);
        	state.items = cloneJSON(toJS(this.itemsOB, true, null, true), true);
        	if (this.props.$ReactAttrBindFns){
        		var realItems = this.getRealItems(state.items, this.itemsOB, "items");
        		for (let i=0; i<realItems.length; i++){
                    var curVars = this._copyVars(context.vars);
                    curVars[this.item] = realItems[i].item;
                	curVars[this.index] = i;
                	curVars.$data = this._getData();
                	curVars.$item = realItems[i].itemOB;
                	//必须用以下写法,否则会影响原始data中的字段
                	//state.items[i] = Object.assign({}, state.items[i], {_attr: this._evalAttr(this.props.$ReactAttrBindFns, curVars) || {}});
                	//改成这种写法, hcr, 理论上没有问题
                	realItems[i].item._attr = this._evalAttr(this.props.$ReactAttrBindFns, curVars) || {};
            	}
        	}
    	}
    	return state;
    }
    
    isTree(){
    	return false;
    }
    
    //如果是树形，需要展开所有的子节点，否则嵌套的组件实例不会创建
    getRealItems(items, itemsOB, path){
    	var ret = [];
    	path = path || "items";
    	if (items){
    		var data = this._getData();
    		var treeOp = {};
    		if (data && data.getTreeOption){
    			treeOp = data.getTreeOption() || {};
    		}
			var childRelation = treeOp.children;
			for (var i=0; i<items.length; i++){
    			var item = items[i];
    			var itemOB = itemsOB[i];
    			ret.push({item: items[i], itemOB: itemsOB[i], path: path + "[" + i + "].$children"});
    			if (this.isTree() && childRelation){
    				if (item[childRelation]){
    					var children = this.getRealItems(item[childRelation], itemOB[childRelation], path + "[" + i + "]." + childRelation);
    					if (children){
        					for (var j=0; j<children.length; j++){
        						ret.push(children[j]);
        					}
    					}
    				}
    			}
    		}
    	}
    	return ret;
    }
    
    //TODO list当前的更新算法不是最优, 会更新所有的子节点
	didBuildState(state, context){
		super.didBuildState(state, context);
		//子组件的更新依赖不进入list组件生命周期中
		if (this._hasData()){
			var vars = context.vars;
			untracked(() => {
		        var oldChildren = this.children;
		        var remainChildren = [];
		        var realItems = this.getRealItems(state.items, this.itemsOB, "items");
		        for (var i=0; i<realItems.length; i++){
		            var item = realItems[i].item;
		            var child = this.findChildByKey(oldChildren, item[this.key]);
		            if (child){
		            	remainChildren.push(child);
		            }
		        }
		        //释放无用的组件
		        oldChildren.forEach(function (child) {
		            if (child){
		                child.comps.forEach(function(c){
		                    c.destroy();
		                });
		            }
		        });
		        
		       
		        var children = [];
		        var path = this.getStatePath();
		        for (var i=0; i<realItems.length; i++){
		        	//var itemPath = path + ".items[" + i + "].$children";
		        	var itemPath = path + "." + realItems[i].path;
		            var item = realItems[i].item;
		            var curVars = this._copyVars(vars);
		            //curVars["$$" + this.item + "$$"] = path + ".items[" + i + "]";
		            curVars["$$" + this.item + "$$"] = itemPath.substr(0, itemPath.lastIndexOf(".")); //将最后的.$children删除
		            curVars[this.item] = realItems[i].itemOB;;//item, 解决list中依赖断了的问题
		        	curVars[this.index] = i;
		        	curVars.$data = this._getData();
		        	curVars.$item = realItems[i].itemOB;
		            var child = this.findChildByKey(remainChildren, item[this.key]);
		            if (child){
		                this.updateChild(child, itemPath, curVars);
		            }else{
		                child = this.createChild(item[this.key], itemPath, curVars);
		            }
		            children.push(child);
		        }
		        this.children = children;
			});
		}
     }
}
