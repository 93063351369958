import ReactComponent from "./reactComponent";

export default class ReactLibraryComponent extends ReactComponent {
    constructor(page, id, props, context) {
        super(page, id, props, context);
    }

    findReact(name=""){
    	let ref = this.page.owner.reactRefs[this.compid.get() + "-" + name];
    	return ref? ref.current : ref;
    }
}
