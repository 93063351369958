import WrapperComponent from "../wrapper/wrapper";
import minioUtil from "../image/util/minioUtil";
import {observable,toJS,autorun, untracked} from '../../lib/mobx/mobx-2.6.2.umd';

export default class Audio extends WrapperComponent{
	get audioCtx() {
		return wx.createAudioContext(this.id);
	}

    constructor(page, id, props, context){
        super(page, id, props, context);
        this.id = id;
        this.fileType = [".mp3",".wav",".ogg",".mp4",".m4a",".flac",".aac",".wma",".amr",".ape",".aiff",".caf"];
        this.posterFileType = [".gif",".jpeg",".jpg",".png",".svg"];
		this.actionUrl = this.page.getServiceUrl(this.props.actionUrl || "/storage");
       
        this.poster = observable("");
        this.name = observable("");
        this.author = observable("");
        
        if(this.props.src){
        	var path = "";
        	if(this.props.src.indexOf("$model/UI2")== 0 ){
        		path = this.props.src.replace("$model/UI2","$UI");
        		this.url = observable(this.page.getUIUrl(path));
        	}else if (this.props.src.indexOf("$UI")== 0){
        		path = this.props.src;
        		this.url =  observable(this.page.getUIUrl(path));
        	}else{
        		path = this.props.src;
        		this.url =  observable(path);
        	}
        	this.staticUrl = this.url.get();
        }else{        	
        	this.url = observable("");
        }
        
        this.statics = (!this.props.statics || this.props.statics == "false") ? false : true;
        var self = this;
        autorun(() => {
        	if (self.props.$nameFn && (typeof self.page[self.props.$nameFn]==="function")){
        		var nameResult = self.page[self.props.$nameFn](self.context.vars);
        		 untracked(() => {        			 
        			 if(nameResult){
        				 self.name.set(nameResult);
        			 }
        		 })
        	}
        	if (self.props.$authorFn && (typeof self.page[self.props.$authorFn]==="function")){
        		var authorResult = self.page[self.props.$authorFn](self.context.vars);
        		 untracked(() => {        			 
        			 if(authorResult){
        				 self.author.set(authorResult);
        			 }
        		 })
        	}
            if (self.props.$posterFn && (typeof self.page[self.props.$posterFn]==="function")){
        		 var posterResult = self.page[self.props.$posterFn](self.context.vars);
        		 untracked(() => {        			 
        			 if(posterResult && posterResult.indexOf("[{\"storeFileName\"") == 0){
        				 posterResult = JSON.parse(posterResult);
        				 for(var i = 0 ; i < posterResult.length ; i ++){
        					 var posterName = posterResult[i].realFileName;
        					 var posterType = posterName.substring(posterName.lastIndexOf("."),posterName.length).toLocaleLowerCase();
        					 var posterStoreFileName = posterResult[i].storeFileName;
        					 if(this.posterFileType.indexOf(posterType) != -1){
        						 if(!self.statics && posterStoreFileName.indexOf("anoy_") == 0){
        							 minioUtil.getFileUrl(this.actionUrl,posterStoreFileName).then(function(data){
        								 self.poster.set(data);
        							 })
        						 }else{     	    					
        							 self.page.request({
        								 url: this.actionUrl + '/presignedGetObject',
        								 data: {
        									 objectName: posterStoreFileName
        								 },
        								 header: {
        									 'content-type': 'application/json'
        								 },
        								 success: function (res) {
        									 self.poster.set(res.data);
        								 }
        							 })
        						 }
        						 break;
        					 }
        				 }
        			 }else{
        				 self.poster.set(posterResult);
        			 }
        		 })
            }	
    	 if (self.props.$urlFn && (typeof self.page[self.props.$urlFn]==="function")){
     		 var result = self.page[self.props.$urlFn](self.context.vars);
     		 untracked(() => {     			 
     			 if(result && result.indexOf("[{\"storeFileName\"") == 0){
     				 result = JSON.parse(result);
     				 for(var i = 0 ; i < result.length ; i ++){
     					 var name = result[i].realFileName;
     					 var type = name.substring(name.lastIndexOf("."),name.length).toLocaleLowerCase();
     					 var storeFileName = result[i].storeFileName;
     					 if(self.fileType.indexOf(type) != -1){
     						 if(!self.statics && storeFileName.indexOf("anoy_") == 0){
     							 minioUtil.getFileUrl(this.actionUrl,storeFileName).then(function(data){
     								 self.url.set(data);
     							 })
     						 }else{     	    					
     							 self.page.request({
     								 url: this.actionUrl + '/presignedGetObject',
     								 data: {
     									 objectName: storeFileName
     								 },
     								 header: {
     									 'content-type': 'application/json'
     								 },
     								 success: function (res) {
     									 self.url.set(res.data);
     								 }
     							 })
     							 
     						 }
     						 break;
     					 }
     				 }
     			 }else{
     				 self.url.set(result);
     			 }
     		 })
     	 	}
        });        
    }
    
    buildState(context){
      	 var state = super.buildState(context);
      	 state.url = this.url.get(); 
      	 state.poster = this.poster.get();
      	 state.name = this.name.get();
      	 state.author = this.author.get();
      	 return state;
    }
    
    setSrc(result){
    	var self = this;
    	//var result = self.page[self.props.$urlFn](self.context.vars);
		 if(result && result.indexOf("[{\"storeFileName\"") == 0){
			 	result = JSON.parse(result);
	    		for(var i = 0 ; i < result.length ; i ++){
	    			var name = result[i].realFileName;
	    			var type = name.substring(name.lastIndexOf("."),name.length).toLocaleLowerCase();
	    			var storeFileName = result[i].storeFileName;
	    			if(self.fileType.indexOf(type) != -1){
	    				if(!self.statics && storeFileName.indexOf("anoy_") == 0){
	    					minioUtil.getFileUrl(this.actionUrl,storeFileName).then(function(data){
	    						self.audioCtx.setSrc(data);
	    					})
	    				}else{     	    					
	    					self.page.request({
	    						url: this.actionUrl + '/presignedGetObject',
	    						data: {
	    							objectName: storeFileName
	    						},
	    						header: {
	    							'content-type': 'application/json'
	    						},
	    						success: function (res) {
	    							self.audioCtx.setSrc(res.data);
	    						}
	    					})
	    						
	    				}
	    				break;
	    			}else{
	    				console.warn("目前不能播放" + type + "类型格式的音频");
	    			}
	    		}
		 	}else{
		 		self.audioCtx.setSrc(result);
		 	}
    }
    
    play(){
    	 this.audioCtx.pause();
    	 setTimeout(()=>{
    		 this.audioCtx.play();
    	 },500);
    }
    
    pause(){
    	this.audioCtx.pause();
    }
    
    seek(position){
    	this.audioCtx.seek((position || 0) * 1);
    	/*if(this.audioCtx.state.isPlaying){
    		this.audioCtx.pause();
    		setTimeout(()=>{
       		 	this.audioCtx.play();
       		 	this.audioCtx.seek((position || 0) * 1);
       	 	},500);	
    	}else{
    		
    	}*/
    }
    
     initOperation(){
    	 super.initOperation();
	   	 this.defineOperation('setSrc', {
	   		 label : "设置音频",
	   		 icon : '',
	   		 init : function() {},
	   		 argsDef : [ {
	   			 name : 'src',
	   			 displayName : "音频路径"
	   		 }],
	   		 method : function(args) {
	   			 this.owner.setSrc(args.src);
	   		 }
	   	 });
	   	 this.defineOperation('play', {
	   		 label : "播放",
	   		 icon : '',
	   		 init : function() {},
	   		 method : function(args) {
	   			 this.owner.play();
	   		 }
	   	 });
	   	 this.defineOperation('pause', {
	   		 label : "暂停",
	   		 icon : '',
	   		 init : function() {},
	   		 method : function(args) {
	   			 this.owner.pause();
	   		 }
	   	 });
	   	 this.defineOperation('seek', {
	   		 label : "跳转到指定位置",
	   		 icon : '',
	   		 init : function() {},
	   		 argsDef : [ {
	   			 name : 'position',
	   			 displayName : "位置"
	   		 }],
	   		 method : function(args) {
	   			 this.owner.seek(args.position);
	   		 }
	   	 });
     }
};
wx.comp = wx.comp || {};
wx.comp.Audio = Audio;
