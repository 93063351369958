import React, {Component} from "react";
import WxComponentBase from "../base/WxComponentBase";
import cx from "classnames";
import detector from 'detector';



export default class WxLabel extends WxComponentBase{
	constructor(...args){
		super(...args);
	}

	onClickHandler(...args) {
		if(detector.os.name == "ios"){
			if(this.rootNode.querySelector("input")){
				this.rootNode.querySelector("input").click();
			}
		}
	}
	doRender(){
		return (
			<label {...this.toDOMAttrs(this.props)} {...this.htmlProps} ref={node => this.rootNode = node}> {this.props.children}</label>
		);
	}
}
