import Component from "../../lib/base/component";
var SHAKE_THRESHOLD = 80;
var last_update = 0;
var x, y, z, last_x, last_y, last_z;
import ShakeImpl from "./ShakeImpl";
export default class Shake extends Component{
    constructor(page, id, props, context){
    	super(page, id, props, context);
    	this.interval = props.interval ? props.interval*1 : 1000;
		this.impl = new ShakeImpl(page);
     }
     
    stopShake(){
    	return this.impl.stopShake();
    }
    
    startShake(){
    	var self = this;
		this.impl.startShake(function(res){ 
            var curTime = new Date().getTime();
            if (self.fireEventTime && self.interval && curTime - self.fireEventTime < self.interval) {
              return;//小于间隔时间
            }
            if ((curTime - last_update) > 300) {
              var diffTime = curTime - last_update;
              last_update = curTime;
              x = res.x;
              y = res.y;
              z = res.z;
              var speed = Math.abs(x + y + z - last_x - last_y - last_z) / diffTime * 10000;
              if (speed > SHAKE_THRESHOLD) {
                self.fireEvent(Shake.EVENT_SUCCESS, res);
                self.fireEventTime = new Date().getTime();
              }
              last_x = x;
              last_y = y;
              last_z = z;
            } 
    	});
    }
    
	destroy(){
		this.stopShake();
		super.destroy();
	}
    initOperation(){
    	 super.initOperation();
	   	 this.defineOperation('startShake', {
	   		 label : "开始监听摇一摇",
	   		 icon : '',
	   		 init : function() {},
	   		 argsDef : [],
	   		 method : function(args) {
	   			 return this.owner.startShake();
	   		 }
	   	 });
	   	 this.defineOperation('stopShake', {
	   		 label : "停止监听摇一摇",
	   		 icon : '',
	   		 init : function() {},
	   		 argsDef : [],
	   		 method : function(args) {
	   			return this.owner.stopShake();
	   		 }
	   	 });
     }
};

Shake.EVENT_SUCCESS = "success";

wx.comp = wx.comp || {};
wx.comp.Shake = Shake;
