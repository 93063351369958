import String from "../../lib/base/string";

let downloadURL = "/excel/download";
let exportURL = "/excel/export/file";
let taskURL = "/excel/task/";
import uaa from "../user/js/uaa";
import minioUtil from "../image/util/minioUtil";

export default class PrintImpl {
	constructor(page) {
		this.page = page;
	}

	getDataComp(id) {
		if (id)
			return this.page.comp(id);
	}

	async getImageValue(value) {
		if (!this.actionUrl) {
			this.actionUrl = this.getUrl("/storage");
		}

		let ImgfileType = [".gif", ".jpeg", ".jpg", ".png", ".svg", ".webp"];
		let imgs = JSON.parse(value) || [];
		let ret = [];
		for (let i = 0; i < imgs.length; i++) {
			let name = imgs[i].realFileName;
			let storeFileName = imgs[i].storeFileName;
			let type = name.substring(name.lastIndexOf("."), name.length).toLocaleLowerCase();
			if (ImgfileType.indexOf(type) != -1) {
				let imgUrl;
				if (storeFileName.indexOf("anoy_") == 0) {
					imgUrl = await minioUtil.getFileUrl(this.actionUrl, storeFileName);
				} else {
					imgUrl = await this.page.request({
						url: this.actionUrl + '/presignedGetObject',
						data: {
							objectName: storeFileName
						},
						header: {
							'content-type': 'application/json'
						}
					});
				}
				ret.push({ url: imgUrl });
			}
		}
		return ret;
	}

	async getDataRow(tableData, row) {
		var ret = {};
		let items = tableData.getColumnDefs();
		for (let o in items) {
			let def = items[o];
			let v = row[o];
			let extType = wx.StringUtilFn.toLowercase(def.extType);
			let extAttr = def.extAttr || {};
			if (extType !== "image" && extType !== "file") {
				ret[o] = !(v instanceof Date) ? v : (extAttr.format ? wx.Date.toString(v, extAttr.format) : (v + ""));
			} else if (extType === "image" && v) {
				ret[o] = await this.getImageValue(v);
			}
		}
		return ret;
	}

	async getDataRows(tableData) {
		let rows = [];
		for (let j = 0, len = tableData.value.length; j < len; j++) {
			let row = await this.getDataRow(tableData, tableData.value[j]);
			rows.push(row);
			let slaveRows = await this.getSlaveDataRows(tableData, row);
			if (slaveRows) {
				for (let n in slaveRows) {
					row[n] = slaveRows[n];
				}
			}
		}
		return rows;
	}

	async getSlaveDataRows(tableData, row) {//小程序目前只支持一层主从，并且主只有一条数据
		let len = tableData.slaveDatas.length;
		if (len > 0) {
			let ret = {};
			for (let i = 0; i < len; i++) {
				let slaveData = tableData.slaveDatas[i];
				if (slaveData) {
					ret[slaveData.id] = await this.getDataRows(slaveData);
				}
			}
			return ret;
		}
	}

	checkCreateFileSuccess(data, dtd) {
		let taskid = data && data.taskid;
		if (taskid) {
			let checkExportHandle, times = 1, self = this;
			let checkFn = () => {
				checkExportHandle && clearTimeout(checkExportHandle);

				//检查时间进行递增
				let t = 2000 * times++;
				t = t > 20000 ? 20000 : t;
				checkExportHandle = setTimeout(function () {
					self.page.request({
						url: self.getUrl(taskURL) + taskid,
						dataType: "json",
						method: "GET",
						success: (res) => {
							let info = res.data;
							if (info && 2 === info.state) {
								if (0 === info.exitValue) {
									dtd.resolve(info);
								} else {
									dtd.reject(info);
								}
							} else checkFn();
						},
						error: function (res) {
							dtd.reject(res);
						}
					});
				}, t);
			};

			checkFn();
		} else {
			dtd.reject(data);
		}
	}

	async getContextData(mainDataRows) {
		let datas = {};
		if (this.dataIds) {
			for (let i = 0, len = this.dataIds.length; i < len; i++) {
				let dataid = this.dataIds[i];
				if (this.dataId !== dataid) {
					let dataComp = this.getDataComp(dataid);
					dataComp && (datas[dataid] = await this.getDataRows(dataComp));
				} else {
					datas[dataid] = mainDataRows;
				}
			}
		}
		let cuser = uaa.getCurrentUser(true);
		let eData = {
			'context': {
				"printer": cuser && (cuser.name || cuser.userName || ""),
				"printDate": wx.Date.toString(wx.Date.now(), wx.Date.STANDART_FORMAT_SHOT),
				"datas": datas
			}
		};
		if (this.contextFn && (typeof this.page[this.contextFn] === "function")) {
			let ctx = this.page[this.contextFn](this.context.vars);
			ctx && (Object.assign(eData.context, ctx));
		}

		let attrs = ['option', 'pageSize', 'pageHeight', 'pageWidth'];
		for (let i = 0, len = attrs.length; i < len; i++) {
			let n = attrs[i];
			if (this[n + 'Fn'] && (typeof this.page[this[n + 'Fn']] === "function")) {
				let c = this.page[this[n + 'Fn']](this.context.vars);
				c && (eData.context[n] = c);
			}
		}
		if (this.dispatchEventFn && typeof this.dispatchEventFn == "function") {
			this.dispatchEventFn('context', eData)
		}
		return (eData.context && JSON.stringify(this.processOption(eData.context))) || "";
	}

	processOption(ctx) {
		if (ctx) {
			if (ctx.pageWidth) {
				ctx.option = ctx.option || "";
				ctx.option += " --page-width " + ctx.pageWidth;
			}
			if (ctx.pageHeight) {
				ctx.option = ctx.option || "";
				ctx.option += " --page-height " + ctx.pageHeight;
			}
			if (ctx.pageSize) {
				ctx.option = ctx.option || "";
				ctx.option += " --page-size " + ctx.pageSize;
			}
			ctx.option && ctx.option.indexOf("--footer-center") < 0 && (ctx.option += " --footer-center [page]/[toPage]");
		}
		return ctx;
	}

	getUrl(url) {
		return this.page.getServiceUrl(url);
	}

	print(params) {
        const preview = params.preview;
		this.dataId = params.dataId;
		this.dataIds = params.dataIds;
		this.type = params.type;
		this.template = params.template;
		this.downloadFilename = params.downloadFilename;
		this.contextFn = params.contextFn;
		this.pageSizeFn = params.pageSizeFn;
		this.pageHeightFn = params.pageHeightFn;
		this.pageWidth = params.pageWidthFn;
		this.optionFn = params.optionFn;
		this.context = params.context;
		this.dispatchEventFn = params.dispatchEventFn;
		let evtData = { cancel: false };

		if (this.dispatchEventFn && typeof this.dispatchEventFn == "function") {
			this.dispatchEventFn('beforePrint', evtData)
		}
		if (evtData.cancel)
			return;
		let promise = new Promise(async (resolve, reject) => {
			try {
				let mainDataRows = await this.getDataRows(this.getDataComp(this.dataId));
				let param = { type: this.type, template: this.template, rows: JSON.stringify(mainDataRows), context: await this.getContextData(mainDataRows) };
				if (wx.showLoading) {
					wx.showLoading({
						title: '打印文件生成中，请稍候',
						mask: true
					});
				} else {
					wx.showToast({
						title: '打印文件生成中，请稍候',
						icon: 'loading',
						duration: 1000 * 120
					});
				}
				this.page.request({
					url: this.getUrl(exportURL),
					dataType: "json",
					method: "POST",
					data: param,
					header: {
						'content-type': 'application/x-www-form-urlencoded'
					}
				}).then((res) => {
					let data = res.data;
					this.checkCreateFileSuccess(data, { resolve, reject });
				}, (err) => {
					reject(err);
				});
			} catch (err) {
				reject(err);
			}
		});

		let ret = new Promise((resolve, reject) => {
			promise.then((data) => {
				if (data && data.result) {
					let fileName = this.downloadFilename || "print.pdf";
					let len = fileName.length;
					let extName = fileName.substring(len - 4).toUpperCase();
					if (len < 4 || [".PDF"].indexOf(extName) < 0) {
						fileName += ".PDF";
					}
					let url = this.getUrl(downloadURL) + "?file=" + encodeURI(data.result.file) + '&filename=' + encodeURI(fileName);
                    if (preview === true) {
                        url += "&operationType=inline"
						wx.hideLoading ? wx.hideLoading() : wx.hideToast();
                        window.open(url);
                    } else if (window && window.isPcx) {
						wx.hideLoading ? wx.hideLoading() : wx.hideToast();
						this.page.hint('打印文件下载完成');
						//直接下载pdf文件
						window.open(url);
						if (this.dispatchEventFn && typeof this.dispatchEventFn == "function") {
							this.dispatchEventFn('success', { url })
						}
						resolve({});
					} else {
						wx.downloadFile({
							url: url,
							success: (res) => {
								const filePath = res.tempFilePath;
								wx.openDocument({
									filePath: filePath,
									fileType: this.type,
									success: (res) => {
										wx.hideLoading ? wx.hideLoading() : wx.hideToast();
										let eData = {
											'filePath': filePath,
											'fileName': fileName
										};
										if (this.dispatchEventFn && typeof this.dispatchEventFn == "function") {
											this.dispatchEventFn('success', eData)
										}
										resolve(eData);
									},
									fail: (err) => {
										wx.hideLoading ? wx.hideLoading() : wx.hideToast();
										reject(err);
									}
								});
							},
							fail: (err) => {
								wx.hideLoading ? wx.hideLoading() : wx.hideToast();
								reject(err);
							}
						});
					}
				}
			}, (err) => {
				wx.hideLoading ? wx.hideLoading() : wx.hideToast();
				console.error(err);
				err = err || {};
				if (this.dispatchEventFn && typeof this.dispatchEventFn == "function") {
					this.dispatchEventFn('error', err)
				}
				wx.showModal({
					showCancel: false,
					title: '错误',
					content: "生成打印文件失败"
				});
			});
		});

		return ret;
	}
}
