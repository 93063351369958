import BindComponent from "../../lib/base/bindComponent";
import {observable,autorun,untracked,toJS} from '../../lib/mobx/mobx-2.6.2.umd';
import Breadcrumb from "../breadcrumb/breadcrumb";

export default class Tree extends BindComponent {
     constructor(page, id, props, context){
        super(page, id, props, context);
        
        
		this.current = observable();
		
		this.multiSelection = observable(props.multiSelection);
        this.selectAllID = props.selectAllID;
		this.dataID = props.dataID;
        this.barID = props.barID;
        this.selectedColumn = props.selectedColumn;
        this.labelColumn = props.labelColumn;
        this.rootLabel = props.rootLabel||"根";
        if (this.barID){
            this.compPromise(this.barID).then((bar)=>{
            	bar.push(this.rootLabel,{_sys_isRoot:true});
            	bar.on(Breadcrumb.EVENT_POP,(evt)=>{this._popHandle(evt);});
            });
        }
        
        if (this.selectAllID){
            this.compPromise(this.selectAllID).then((selectAllComp)=>{
            	selectAllComp.on("valuechange",(evt)=>{this._selectAllHandle(evt);});
            });
        }
        
        if (this.dataID){
        	this.compPromise(this.dataID).then((data) => {
        		data.on("valueChanged", (evt)=>{
        			this._dataChangedHandle(evt);
        		});
        	});
        }
     }
     
     _dataChangedHandle(evt){
    	 //单选时，取消其它行的状态
    	 if (!this.multiSelection.get() && (evt.col===this.selectedColumn) && (evt.newValue == "1")){
    		 var data = this.page.comp(this.dataID);
    		 if (data){
    			 let idCol = data.getIdColumn();
    			 data.each((params)=>{
    				 if ((params.row[idCol]!==evt.row[idCol]) && params.row[this.selectedColumn]){
    					 params.row[this.selectedColumn] = false;
    				 }
    			 });
    		 }
    	 }
     }
     
     setMultiSelection(value){
    	 this.multiSelection.set(value);
     }
     
     _resetSelectAllState(){
    	 if (this.multiSelection.get() && this.selectAllID){
    		 this.page.comp(this.selectAllID)._value.set("");
    	 }
     }
     
     buildState(context){
     	var state = super.buildState(context);
     	state.multi = this.multiSelection.get();
     	return state;
     }
     
	 getDataComp(){
		 return this.page.comp(this.dataID);
	 }
	 
	 getBarComp(){
		 return this.page.comp(this.barID);
	 }

	 _selectAllHandle(evt){
		 if (this.multiSelection.get()){
			 var status = evt.value == "1";
			 var items = this.getTreeDatas();
			 if (items){
				 for (var i=0; i<items.length; i++){
					 if (items[i]){
						 items[i][this.selectedColumn] = status; //假设一定是selected字段
					 }
				 }
			 }
		 }
	 }
	 
	 _tapHandle(evt){
		 let row = evt.context.$item;
		 this.getBarComp().push(row[this.labelColumn],row);
		 let data = this.getDataComp();
		 if(!data.isLoaded(row)){
			 data.refreshData({parentRow:row});
		 }
		 this.current.set(row);
		 this._resetSelectAllState();
	 }
	 
	 _popHandle(event){
		 this.current.set(event.data&&!event.data._sys_isRoot?event.data:null);
		 this._resetSelectAllState();
	 }
	 
	 hasChildren(row){
		 let data = this.getDataComp();
		 let isLoaded = data.isLoaded(row);
		 return !isLoaded || (isLoaded && data.hasChildren(row));
	 }
	 
	 getTreeDatas(){
		 let row = this.current.get();
		 let data = this.getDataComp();
		 return data?(row?data.getChildren(row):data.value):[];
	 }
}

wx.comp = wx.comp || {};
wx.comp.Tree = Tree;
