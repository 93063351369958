import BindComponent from "../../lib/base/bindComponent";

export default class PopRadioGroup extends BindComponent {
     constructor(page, id, props, context){
        super(page, id, props, context);
     }
     
     buildState(context){
    	 var state = super.buildState(context);
    	 return state;
     }

     onChange(evt){
    	 this.fireEvent(PopRadioGroup.EVENT_VALUE_CHANGE, {oldValue: evt.oldValue , value: evt.value, source: this});
     }
}

PopRadioGroup.EVENT_VALUE_CHANGE = "valuechange";

wx.comp = wx.comp || {};
wx.comp.PopRadioGroup = PopRadioGroup;

