import PageImpl from "../../../lib/base/pageImpl";
import User from "../user";
import Util from "../../../lib/base/util";


var app = getApp();
export default class EditPage extends PageImpl {
  constructor(...args){super(...args);}
  
  login() {
	  let data = this.comp('loginData');
	  let name = this.username;
	  let password = data.getValue('password');
	  let params = {};
	  params.name = name;
	  params.pretoken = JSON.stringify(this.pretoken);
	  // 自动设置参数的情况
	  params.password = password;
	  if (!params.name) {
		    console.error('登陆用户名不存在');
			return;
	  }
	  if (!params.password) {
			this.hint('请输入短信验证码!','warn');
			return;
		}
	  if (this.userComp) {
		  this.userComp.login(params,this).then(() => {
			  this.navigateBack({delta:2});
		  },()=>{});
	  }
  }
	
  pageLoad(event){
	  let ctrl = this.comp('ctrl');
      let data = this.comp('loginData');
	  ctrl.refreshData();
	  data.setValue("name","");
	  if(this.params.userComp){
	  	this.userComp = User.getUserComp(this.params.userComp);
	  	if(this.params.pretoken){
	  		this.pretoken = JSON.parse(this.params.pretoken);
	  	}
		this.username = this.params.username;
		data.setValue("name",(this.pretoken && this.pretoken.maskedPhoneNumber)||"");
	  }	
  }
  
  sendSmsBtnClick(event) {
		let ctrldata = this.comp('ctrl');
		if(ctrldata.getValue('btnDisabled')) return;
		this.userComp && this.userComp.twofactorloginVerifycode(this.pretoken, this).then(()=>{
			User.bindTimmer(ctrldata,60,"获取验证码","重发");
		},()=>{});
  }
  
  loginBtnClick(event) {
	  this.login();
  }
  
  regBtnClick(event) {
	  if (this.userComp)
		  this.userComp.showRegister();
  }

  btnForgetClick(event) {
	  if (this.userComp)
		  this.userComp.showForgetPassword();
  }
  
}
