export default class ShakeImpl {
	constructor(page) {
		this.page = page;
	}

	stopShake() {
		return wx.stopAccelerometer();
	}

	startShake(fn) {
		wx.onAccelerometerChange(fn)
	}
}
