// 格式化数字型
export default class DecimalFormat {
     constructor(pattern){
    	 this.applyPattern(pattern);
     }
     
     applyPattern(pattern) {
 		if (pattern === undefined) {
 			pattern = "";
 		}
 		function contains(arr, ch) {
 			for (let i = 0; i < arr.length; i++) {
 				if (arr[i] == ch) {
 					return true;
 				}
 			}
 			return false;
 		}
 		for (let i = 0; i < pattern.length; i++) {
 			if (!contains(DecimalFormat.SPECIAL_CHARS, pattern.charAt(i))) {
 				return;
 			}
 		}
 		this.pattern = pattern;
 	}
     
 	format(number) {
		if (isNaN(number)) {
			return number;
		}
		let pattern = this.pattern;
		if (!pattern) {
			return number;
		}
		let strNum = "" + number;
		let numNum = parseFloat(number);
		let isNegative = false;
		if (numNum < 0) {
			isNegative = true;
		}
		if (isNegative) {
			strNum = strNum.substring(1, strNum.length);
			numNum = -numNum;
		}
		let pPos = pattern.indexOf("%");
		let cPos = pattern.indexOf(",");
		if (pPos != -1 || (cPos != -1 && pPos != -1)) {
			return number;
		}
		let dPos,dPosOfNum,adStrLength,snbFixed;
		if (pPos != -1) {
			if (pPos != pattern.length - 1) {
				return number;
			}
			pattern = pattern.substring(0, pattern.length - 1);
			numNum = parseFloat(number) * 100;
			strNum = '' + numNum;
			if (isNegative) {
				strNum = strNum.substring(1, strNum.length);
				numNum = -numNum;
			}
		}
		dPos = pattern.indexOf(".");
		dPosOfNum = strNum.indexOf(".");
		let result = "";
		if (dPos != -1) {
			if (dPosOfNum == -1) {
				dPosOfNum = strNum.length - 1;
			}
			adStrLength = pattern.length - dPos;
			snbFixed = parseFloat(strNum).toFixed(adStrLength - 1);
			if (isNegative) {
				result = "-" + snbFixed;
			} else {
				result = snbFixed;
			}
		} else {
			if (dPosOfNum == -1) {
				dPosOfNum = strNum.length - 1;
			}
			snbFixed = parseFloat(strNum).toFixed();
			if (isNegative) {
				result = "-" + snbFixed;
			} else {
				result = snbFixed;
			}
		}
		if (pPos != -1) {
			result += "%";
		}

		// 123456.12==>123,456.12
		let tmp = "";
		let count3 = 0;
		if (cPos != -1 && result.length) {
			dPos = result.indexOf(".");
			for ( let i = result.length - 1; i >= 0; i--) {
				let c = result.charAt(i);
				if (dPos != -1 && i >= dPos) {
					tmp = c + tmp;
				} else {
					if (count3 == 3 && c != "-") {
						count3 = 1;
						tmp = "," + tmp;
					} else {
						count3++;
					}
					tmp = c + tmp;

				}
			}
			result = tmp;
		}

		return result;
	}
}
	
DecimalFormat.SPECIAL_CHARS = [ "0", ".", "-", ",", "E", "%", "\u00A4", "\u2030" ];
