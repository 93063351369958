import BindComponent from "../../lib/base/bindComponent";
import {calItems} from "../checkboxGroup/util";

export default class RadioGroup extends BindComponent {
     constructor(page, id, props, context){
        super(page, id, props, context);
     }
     
     buildState(context){
    	 var state = super.buildState(context);
    	 if (this.props.$filter){
        	 var items = calItems(this, context.vars);
        	 if (items) state.items = items;
    	 }
    	 return state;
     }

     onChange(evt){
    	 var dataset = evt.currentTarget.dataset;
    	 this.setValue(dataset.path, dataset.prop, evt.detail.value, (value, oldValue) => {
    		 this.fireEvent(RadioGroup.EVENT_VALUE_CHANGE, {oldValue: oldValue , value: value, source: this});
    	 });
     }
}

RadioGroup.EVENT_VALUE_CHANGE = "valuechange";

wx.comp = wx.comp || {};
wx.comp.RadioGroup = RadioGroup;

