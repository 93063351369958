/*! 
 * WeX5 v3 (http://www.justep.com) 
 * Copyright 2015 Justep, Inc.
 * Licensed under Apache License, Version 2.0 (http://www.apache.org/licenses/LICENSE-2.0) 
 */
import Component from "../../lib/base/component";
import RequestImpl from "./RequestImpl";
export default class Request extends Component {
	constructor(page, id, props, context) {
		super(page, id, props, context);
		this.impl = new RequestImpl(page);
	}

	send(cfg) {
		return this.impl.send(cfg, {
			method: this.props.type,
			dataType: this.props.dataType,
			headersExpr: this.props.executeExprBinding_headersExpr,
			urlExpr: this.props.urlExpr,
			$executeExprBinding_urlExpr: this.props.$executeExprBinding_urlExpr,
			url: this.props.url,
			contentType: this.props.contentType,
			dataExpr: this.props.dataExpr,
			$executeExprBinding_dataExpr: this.props.$executeExprBinding_dataExpr,
			context: this.context,
			dispatchEventFn: (eventName, data) => {
				data.source = this;
				this.fireEvent(eventName,data)
			}
		});
	}

	initOperation() {
		super.initOperation();
		this.defineOperation('sendRequest', {
			label: "发送请求",
			icon: '',
			init: function () { },
			argsDef: [{
				name: 'options',
				displayName: "参数"
			}],
			method: function (args) {
				return this.owner.send(args.options);
			}
		});
	}

	destroy() {
		super.destroy();
	}
}
wx.comp = wx.comp || {};
wx.comp.Request = Request;
