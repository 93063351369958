import _createPageConfig from '../../../lib/base/createPageConfig';
import PageClass from './pcxSelectValue.user';

 var $g_fns_tableData = {
		get _userdata(){
			return wx.Util.prepareUserData(this, {

			}, ['__id_','_key']);
		}
}; 


 var $g_fns_tableData1 = {
		get _userdata(){
			return wx.Util.prepareUserData(this, {

			}, ['name','_key']);
		}
}; 


 var $g_fns_ctrlData = {
		get _userdata(){
			return wx.Util.prepareUserData(this, {

			}, ['id','hideSearchBar','_key']);
		}
}; 


import '../../wrapper/wrapper';
import '../../commonOperation/commonOperation'; 
import '../../tableData/tableData'; 
import '../../icon/css/icon.css'; 
import '../../input/input'; 
import '../../input/css/input.css'; 
import '../../button/button'; 
import '../../button/css/button.css'; 
import '../../buttonGroup/css/buttonGroup.css'; 
import '../../page/page'; 
import '../../page/css/page.css'; 
import '../../wrapper/wrapper'; 
import '../../text/text'; 
import '../../toptips/toptips'; 
import '../../toptips/css/toptips.css'; 
import '../../pagination/pagination'; 
import '../../pagination/css/pagination.css'; 
import '../../table/table'; 
import '../../panel/css/panel.css'; 
import '../../searchBar/searchBar'; 
import '../../searchBar/css/searchBar.css'; 
import '../../loading/loading'; 
import '../../loading/css/loading.css'; 
import '../../wxApi/wxApi'; 
var methods = {

 $items_table: function({tableitem,ctrlData,$row,tableData1,tableData,tableindex,$comp,params,$page,props}){
 try{return $page.getItems()}catch(_e){} ;
}

,
 $attrBindFn_class_panel: function({ctrlData,$row,tableData1,tableData,$comp,params,$page,props}){
 try{return {'x-hide-search-bar':wx.Util.iif(ctrlData.current.hideSearchBar=="true",true,false)}}catch(e){return ''} ;
}

,$evtH_cancel_tap: function({tableitem,$event,ctrlData,$row,$data,tableData1,tableData,tableindex,$item,params,$page,props}){
let $$$args = arguments[0];
	let args={};
	 $page.$compByCtx('commonOperation',$event.source).executeOperation('close', args, $$$args);

}

}
var template = [
	[
		{
			"cls":wx.compClass('$UI/wxsys/comps/tableData/tableData'),
			"props":{
				"schema":{
					"limit":20,
					"orderBy":[],
					"type":"array",
					"items":{
						"type":"object",
						"props":{
							"__id_":{
								"define":"__id_",
								"label":"id",
								"type":"string",
								"extType":"String"
							},
							"_key":{
								"type":"string"
							}
						},
						"key":"_key",
						"fns":$g_fns_tableData
					},
					"id":"tableData",
					"keyItems":"_key"
				},
				"options":{
					"calcTotal":"",
					"isMain":false,
					"autoMode":"",
					"directDelete":true,
					"checkRange":"",
					"confirmDelete":true,
					"enableContextReadonly":true,
					"confirmRefreshText":"",
					"allowEmpty":false,
					"checkMode":"",
					"confirmDeleteText":"",
					"confirmRefresh":true,
					"idColumn":"__id_"
				},
				"id":"tableData",
				"filters":{}
			}
		},
		{
			"cls":wx.compClass('$UI/wxsys/comps/tableData/tableData'),
			"props":{
				"schema":{
					"limit":20,
					"orderBy":[],
					"type":"array",
					"items":{
						"type":"object",
						"props":{
							"name":{
								"define":"name",
								"label":"名称",
								"type":"string",
								"extType":"String"
							},
							"_key":{
								"type":"string"
							}
						},
						"key":"_key",
						"fns":$g_fns_tableData1
					},
					"id":"tableData1",
					"keyItems":"_key"
				},
				"options":{
					"calcTotal":"",
					"isMain":false,
					"autoMode":"",
					"directDelete":true,
					"checkRange":"",
					"confirmDelete":true,
					"enableContextReadonly":true,
					"confirmRefreshText":"",
					"allowEmpty":false,
					"checkMode":"",
					"confirmDeleteText":"",
					"confirmRefresh":true,
					"idColumn":"name"
				},
				"id":"tableData1",
				"filters":{}
			}
		},
		{
			"cls":wx.compClass('$UI/wxsys/comps/tableData/tableData'),
			"props":{
				"schema":{
					"limit":20,
					"orderBy":[],
					"type":"array",
					"items":{
						"type":"object",
						"props":{
							"id":{
								"define":"id",
								"label":"id",
								"type":"string",
								"extType":"String"
							},
							"hideSearchBar":{
								"define":"hideSearchBar",
								"label":"隐藏搜索",
								"type":"string",
								"extType":"String"
							},
							"_key":{
								"type":"string"
							}
						},
						"key":"_key",
						"fns":$g_fns_ctrlData
					},
					"id":"ctrlData",
					"keyItems":"_key"
				},
				"initData":[
					{
						"id":"data",
						"hideSearchBar":"false"
					}
				],
				"options":{
					"calcTotal":"",
					"isMain":false,
					"autoMode":"",
					"directDelete":true,
					"checkRange":"",
					"confirmDelete":true,
					"enableContextReadonly":true,
					"confirmRefreshText":"",
					"allowEmpty":false,
					"checkMode":"",
					"confirmDeleteText":"",
					"confirmRefresh":true,
					"idColumn":"id"
				},
				"id":"ctrlData",
				"filters":{}
			}
		}
	],
	{
		"cls":wx.compClass('$UI/wxsys/comps/page/page'),
		"props":{
			"$events":{
				"loaded":"onPageLoaded"
			},
			"id":"page",
			"params":{
				"compid":"String"
			}
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/wxApi/wxApi'),
		"props":{
			"id":"wxapi"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/commonOperation/commonOperation'),
		"props":{
			"id":"commonOperation"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/loading/loading'),
		"props":{
			"loadingNum":0,
			"id":"__auto1"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/wrapper/wrapper'),
		"props":{
			"id":"panel",
			"$attrBindFns":{
				"class":"$attrBindFn_class_panel"
			}
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/searchBar/searchBar'),
		"props":{
			"input":"input",
			"id":"searchBar"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/input/input'),
		"props":{
			"id":"input"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/wrapper/wrapper'),
		"props":{
			"id":"icon1"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/text/text'),
		"props":{
			"id":"text",
			"text":"搜索"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/table/table'),
		"props":{
			"item":"tableitem",
			"data":"tableData.value",
			"$template":[],
			"columns":[
				{
					"dataIndex":"name",
					"width":"120px",
					"id":"column",
					"title":"名称",
					"key":"name0"
				}
			],
			"index":"tableindex",
			"$items":"$items_table",
			"dataId":"tableData",
			"$events":{
				"custom":"{{table.compid}}:doCustomEvent"
			},
			"id":"table",
			"dataSource":"tableData.value",
			"key":"_key",
			"idColumn":"__id_"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/pagination/pagination'),
		"props":{
			"$events":{
				"custom":"{{pagination.compid}}:doCustomEvent"
			},
			"id":"pagination"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/button/button'),
		"props":{
			"id":"cancel"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/button/button'),
		"props":{
			"id":"ok"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/toptips/toptips'),
		"props":{
			"id":"__toptips__"
		}
	}
];
var dependTree = {
	"page":{
		"__toptips__":{
			"__auto8":{
				"depends":["__toptips__.msgs","__toptips__.items[].typeClass","__toptips__.items[].show","__toptips__.items[].message"]
			},
			"depends":["__toptips__.show","__toptips__.compid"]
		},
		"inVisibleCompContainer":{
			"depends":[]
		},
		"depends":["page.compid","__pageid__"],
		"__auto1":{
			"depends":["__auto1.loadingAfterShow","__auto1.loadingNum","__auto1.compid"],
			"__auto2":{
				"__auto7":{
					"depends":[]
				},
				"depends":[],
				"__auto5":{
					"depends":[]
				},
				"__auto6":{
					"depends":[]
				},
				"__auto3":{
					"depends":[]
				},
				"__auto4":{
					"depends":[]
				}
			}
		},
		"panel":{
			"top":{
				"buttonGroup":{
					"depends":[]
				},
				"depends":[],
				"searchBar":{
					"view":{
						"input":{
							"depends":["searchBar.value","input.compid","__pageid__","searchBar.compid"]
						},
						"depends":[],
						"icon":{
							"depends":[]
						},
						"icon1":{
							"depends":["searchBar.value.length","icon1.compid","searchBar.compid","__pageid__"]
						}
					},
					"depends":["searchBar.compid"],
					"text":{
						"depends":["text.compid","searchBar.compid","__pageid__","text.text"]
					}
				}
			},
			"depends":["panel.class","panel.compid"],
			"bottom":{
				"depends":[],
				"buttonGroup1":{
					"cancel":{
						"depends":["cancel.debounce","cancel.compid","__pageid__"],
						"icon3":{
							"depends":[]
						},
						"view3":{
							"depends":[]
						}
					},
					"depends":[],
					"ok":{
						"depends":["ok.debounce","ok.compid","__pageid__"],
						"view2":{
							"depends":[]
						},
						"icon2":{
							"depends":[]
						}
					}
				}
			},
			"content":{
				"pagination":{
					"depends":["pagination.current","pagination.pageSize","pagination.total","pagination.hideOnSinglePage","pagination.pageSizeOptions","pagination.showQuickJumper","pagination.showSizeChanger","pagination.size","pagination.compid","__pageid__"]
				},
				"depends":[],
				"table":{
					"depends":["table.items","table.columns","table.bordered","table.loading","table.pagination","table.size","table.showHeader","table.tableLayout","table.rowSelectionType","table.rowSelectionFixed","table.scroll","table.compid","__pageid__"]
				}
			}
		}
	}
}
export function createPageConfig(){
	return _createPageConfig(PageClass, template, methods, {"navigationBarTitleText":"请选择..."},{}, dependTree)
}
export function createPage(owner, pageid, props){
	var page = new PageClass(owner, props);
	page.$init(template, methods, pageid);
	return page;
}
