
/*
  {
  		"defCols": {
  			"code":{
				"define":"code",
				"label":"编码",
				"type":"string",
				"readonly": fn($row),
				"default": fn(),
				"required": {
					"fn": fn($row),
					"msg": "",
				},
				"constraint": {
					"fn": fn($row),
					"msg": "",
				},
				"calculate": fn($row)
			},
			name: {
                define: "name",
                label: "名称",
                type: "string"
           },
            id: {
                define: "name",
                label: "名称",
                type: "string"
            }
  		},
  		"initData":[
			{
				"code":"2",
				"name":"1",
				"id":"3"
			}
		],
  		"filters": {},
  		"options":{
  			"limit":20,  
			"orderBy":[], 
			
			"depends":[
				"tableCustomData0"
			],
			"isMain":false,
			"autoMode":"new",
			"directDelete":true,
			"confirmDelete":true,
			"confirmRefreshText":"",
			"allowEmpty":false,
			"confirmDeleteText":"",
			"confirmRefresh":true,
			"idColumn":"id",
			"dataReadonly": fn($data),   //原来是$roFn
  			"deleteConstraint": fn($row), //原来是"$checkDeleteRowFn":"$checkDeleteRowFn_tableCustomData0",
  			"deleteConstraintMsg": fn($row),//原来是"$getCanNotDeleteRowMsgFn":"$getCanNotDeleteRowMsgFn_tableCustomData0",
		}
  }
 */
var READONLY_RULE = "readonly";
var DEFAULT_RULE = "default";
var REQUIRED_RULE = "required";
var CONSTRAINT_RULE = "constraint";
var CALCULATE_RULE = "calculate";

function buildColInfo(defCols, isAppend){
	defCols = defCols || {};
	let autoruns = {};
	let cols = [];
	let rules = [];
	let props = [];
	for (let name in defCols){
		if (defCols.hasOwnProperty(name)){
			cols.push(name);
			let col = defCols[name];
			if (!col || !col.type) continue;
			if (!col.define) col.define = name;
			props[name] = col;
			let defaultFn = col[DEFAULT_RULE];
			delete col[DEFAULT_RULE];
			if (typeof defaultFn == "function"){
				props[name][DEFAULT_RULE] = defaultFn;
			}
			
			let readonlyFn = col[READONLY_RULE];
			delete col[READONLY_RULE];
			if (typeof readonlyFn == "function"){
				rules[name] = rules[name] || {};
				rules[name].readonly = function($row){
					try{
			 			return readonlyFn($row);
					}catch(_$e){
						return null;
					}
				};
			}
			
			let requiredFn = col[REQUIRED_RULE];
			delete col[REQUIRED_RULE];
			if (requiredFn){
				rules[name] = rules[name] || {};
				if (typeof requiredFn.fn == "function"){
					rules[name].required = {
						val: function($self){
							try{
					 			return requiredFn.fn($self);
							}catch(_$e){
								return null;
							}
						},
						msg: requiredFn.msg
					};
				}
			}
			
			let constraintFn = col[CONSTRAINT_RULE];
			delete col[CONSTRAINT_RULE];
			if (constraintFn){
				rules[name] = rules[name] || {};
				if (typeof constraintFn.fn == "function"){
					rules[name].constraint = {
						val: function($self){
							try{
					 			return constraintFn.fn($self);
							}catch(_$e){
								return null;
							}
						},
						msg: constraintFn.msg
					};
				}
			}
			
			let calculateFn = col[CALCULATE_RULE];
			delete col[CALCULATE_RULE];
			if (typeof calculateFn == "function"){
				autoruns[name] = function($row){
					//不处理计算中的依赖，由用户函数自身实现
					try{
						$row[name] = calculateFn($row);
					}catch(__$e){
						console.error(__$e);
					}
				};
				
			}
		}
	}
	let fns = {};
	let $getExtUserdata = null;
	if (isAppend){
		$getExtUserdata = function(){
			let rulesValue = {};
			for (let key in rules){
				if (rules.hasOwnProperty(key)){
					let rule = rules[key];
					rulesValue[key] = {};
					if (rule.readonly){
						rulesValue[key].readonly = rule.readonly(this);
					}
					if (rule.required && rule.required.val){
						rulesValue[key].required = {
							val: rule.required.val(this),
							msg: rule.required.msg
						};
					}
					if (rule.constraint && rule.constraint.val){
						rulesValue[key].constraint = {
							val: rule.constraint.val(this),
							msg: rule.constraint.msg
						};
					}
				}
			}
			return wx.Util.prepareUserData(this, rulesValue, cols, true);
		}	
		
	}else{
		fns = {
			get _userdata(){
				let rulesValue = {};
				for (let key in rules){
					if (rules.hasOwnProperty(key)){
						let rule = rules[key];
						rulesValue[key] = {};
						if (rule.readonly){
							rulesValue[key].readonly = rule.readonly(this);
						}
						if (rule.required && rule.required.val){
							rulesValue[key].required = {
								val: rule.required.val(this),
								msg: rule.required.msg
							};
						}
						if (rule.constraint && rule.constraint.val){
							rulesValue[key].constraint = {
								val: rule.constraint.val(this),
								msg: rule.constraint.msg
							};
						}
					}
				}
				return wx.Util.prepareUserData(this, rulesValue, cols);
			}	
		};
	}
	return {
		props: props,
		fns: fns,
		$getExtUserdata: $getExtUserdata,
		autoruns: autoruns
	}
}

function processColumnRefData(config){
	let options = config.options || {};
	config.options = options;
	options.depends = options.depends || [];
	if (config.shcema && config.schema.items && config.schema.items.props){
		for (let name in config.shcema.items.props){
			if (config.schema.items.props.hasOwnProperty(name) && config.schema.items.props[name]){
				let refData = config.schema.items.props[name].refData;
				//只阻止了自己引用自己形成的环
				if (refData && (options.depends.indexOf(refData)===-1) && (refData !== config.id)){
					options.depends.push(refData);
				}
			}
		}
	}
}

/**
 * 处理treeOption：
 * 	如果是树，默认将rootMode设置为vtree；没有虚拟节点列时，默认添加虚拟节点计算列
 */
function prepareTreeOption(config){
	if (config && config.options && config.options.treeOption){
		let treeOption = config.options.treeOption;
		if (treeOption.isTree && treeOption.fullPaths && treeOption.fullPaths.length>0){
			if (!treeOption.rootMode){
				treeOption.rootMode = "vtree";
			}
			if ((treeOption.rootMode != "default") && !treeOption.virtualCol){
				if (config.schema && config.schema.items && config.schema.items.props){
					let props = config.schema.items.props;
					let virName = "$sysNodeKind";
					props[virName] = {
							"define":"EXPRESS",
							"label":"虚拟节点列",
							"type":"string",
							"extType":"String"
					}
					treeOption.virtualCol = virName;
				}
			}
		}
	}
}

function buildJsonSchema(schema){
	if (schema){
		if (schema.type == "object"){
			let props = schema.props;
			if (props){
				for (let key in props){
					if (props.hasOwnProperty(key)){
						let prop = props[key];
						if (!props.define) props.define = key;		
						if (prop.type == "object"){
							buildJsonSchema(prop);
						}else if (prop.type == "array"){
							buildJsonSchema(prop.items);
						}		
					}
				}				
				if (schema.fns || schema.autorun){
					//表示已经有fns或autorun, 是由java生成的，不需要处理
				}else{
					let colInfo = buildColInfo(props);
					schema.props = colInfo.props;
					schema.fns = colInfo.fns;
					schema.autorun = colInfo.autoruns;
				}
			}
		}else if (schema.type == "array"){
			if (schema.items){
				buildJsonSchema(schema.items);
			}
		}
	}
}


export function buildConfig(id, config){
	if (id && !config.id){
		config.id = id;
	}
	let clz = config.clz;
	delete config.clz;
	if (clz == "JSONData"){
		buildJsonSchema(config.schema);
		return config;
	}else if (config && config.schema){
		config.options = config.options || {};
		if (config.$roFn){
			config.options.dataReadonly = config.$roFn;
			delete config.$roFn;
		}
		if (config.options.$checkDeleteRowFn){
			config.deleteConstraint = config.options.$checkDeleteRowFn;
			delete config.options.$checkDeleteRowFn;
		}
		if (config.options.$getCanNotDeleteRowMsgFn){
			config.deleteConstraintMsg = config.options.$getCanNotDeleteRowMsgFn;
			delete config.options.$getCanNotDeleteRowMsgFn;
		}
		prepareTreeOption(config);
		processColumnRefData(config);
		return config;
	}else{
		let defCols = config.defCols || {};
		let colInfo = buildColInfo(defCols);
		colInfo.props._key = {type:"string"};
		let schema = {
			type:"array",	
			id: id,
			keyItems: "_key",
			items: {
				autorun: colInfo.autoruns,
				fns: colInfo.fns,
				type: "object",
				key: "_key",
				props: colInfo.props
			}
		};
		
		let options = config.options || {};
		schema.limit = options.limit || 20;
		delete options.limit;
		if (options.orderBy){
			schema.orderBy = options.orderBy;
			delete options.orderBy;
		}
		
		let ret = {
			schema: schema,
			options: options,
			id: id
		};
		options.dynamicCreate = true;
		if (config.filter){
			ret.filter = config.filter;
		}
		if (config.initData){
			ret.initData = config.initData;
		}
		prepareTreeOption(config);
		processColumnRefData(ret);
		return ret;
	}
	
}

export function appendCols(config, cols){
	let colInfo = buildColInfo(cols, true);
	let items = config.schema.items;
	items.$getExtUserdata = colInfo.$getExtUserdata;
	items.autoruns = Object.assign(items.autoruns || {}, colInfo.autoruns);
	items.props = Object.assign(items.props || {}, colInfo.props);
}

export function cloneConfig(config){
	let ret = null;
	if (config){
		let tmp = {};
		let prefix = "$$cloneFnStart$$";
		let postfix = "$$cloneFnEnd$$";
		let i = 0;
		let str = JSON.stringify(config, function(key, value){
			if (typeof value === "function" || (key==="fns" && value._userdata)){
				//console.log("key: " + key + ", value: " + value);
				let newKey = prefix + (i++) + postfix;
				tmp[newKey] = value;
				return newKey;
			}else{
				return value;
			}
			return value;
		});
		ret = JSON.parse(str, function(key, value){
			return tmp[value] || value;
		});	
	}else{
		ret = config;
	}
	return ret;
}
