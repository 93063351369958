import BindComponent from "../../lib/base/bindComponent";

export default class SearchBar extends BindComponent {
     constructor(page, id, props, context){
        super(page, id, props, context);
        this.dataComp = null;
        this.filterProps = this.props.filterProps;
     }
     
     buildState(context){
		var state = super.buildState(context);
		state.value = this.getInputValue();
		return state;
     }
     
     getInputValue(){
    	 var input = this.getInput();
    	 if (input){
    		 return input.getValue();	 
    	 }else{
    		 return "";
    	 }
     }
     
     setInputValue(val){
    	 var input = this.getInput();
    	 if (input){
        	 input.doChange({value: val});
        	 this._update(this.context);
    	 }
     }

     setData(data){
    	 this.dataComp = data; 
     }
     
     setFilterProps(filterProps){
    	 this.filterProps = filterProps; 
     }

     getInput(){
    	 if (this.props.input){
    		 return this.page.$compByCtx(this.props.input, this);	 
    	 }else{
    		 return null;
    	 }
    	 
     }
     
     // 当前只支持一个列
     getFilter(value){
    	 if (this.filterProps){
    		 var filterPropsRows = this.filterProps.split(",");
    		 var rows = [];
    		 rows.push({
    			 op: "LBRAC"
			 });
    		 for(var i = 0 ; i < filterPropsRows.length; i ++){
    			  if(i == 0){
    				  rows.push({
    					  name: filterPropsRows[i],
    					  op: "ilike",
    					  value: value
    				  })
    			  }else{
    				  rows.push({
    					  name: filterPropsRows[i],
    					  op: "ilike",
    					  value: value,
    					  kind:'or'
    				  })
    			  }
    		 }
    		 rows.push({
    			 op: "RBRAC"
			 });
    		 return rows;
    	 }else{
    		 return null;
    	 }
     }
     
     onClear(event){
    	 this.setInputValue("");
     }
     
     onInput(event){
		 this._update(this.context);
	 }
     
     onSearch(event){
    	 var value = this.getInputValue();
    	 var filter = this.getFilter(value);
    	 if (filter){
        	 var eventData = {source: this, value: value, filterProps: this.filterProps, cancel: false, filter: filter, refreshOption:{}};
        	 this.fireEvent(SearchBar.EVENT_SEARCH, eventData);
        	 if (!eventData.cacnel){
        		 this.doSearch(eventData.filter, eventData.refreshOption);
        	 }
    	 }
     }
     
     doSearch(filter, refreshOption){
    	 let data = this.dataComp;
    	 if (!data && this.props.data){
    		 data = this.page.comp(this.props.data);
    	 }
    	 if (data){
    		 data.setFilter(this.props.id + "__auto_filter", filter);
    		 data.refreshData(refreshOption);
    	 }
     }
     
     clear(){
    	this.onClear();
    	this.clearDataFilter();
     }
     
     clearDataFilter(){
    	 let data = this.dataComp;
    	 if (!data && this.props.data){
    		 data = this.page.comp(this.props.data);
    	 }
    	 if (data){
    		 data.setFilter(this.props.id + "__auto_filter", null);
    	 }
     }
}

SearchBar.EVENT_SEARCH = "search";
wx.comp = wx.comp || {};
wx.comp.SearchBar = SearchBar;

