import WrapperComponent from "../wrapper/wrapper";
import ScanCodeImpl from "./ScanCodeImpl";
export default class ScanCode extends WrapperComponent {
	constructor(page, id, props, context) {
		super(page, id, props, context);
		this.impl = new ScanCodeImpl(page);
	}
	scan(args) {
		var self = this;
		return this.impl.scan(args).then(function (res) {
			self.fireEvent(ScanCode.EVENT_SUCCESS , {source: self, originalEvent: res});
      		self.fireEvent(ScanCode.EVENT_COMPLETE , {source: self, originalEvent: res});
      	}).catch(function(res){
      		self.fireEvent(ScanCode.EVENT_FAIL , {source: self, originalEvent: res});
      		self.fireEvent(ScanCode.EVENT_COMPLETE , {source: self, originalEvent: res});
		});;
	}

	initOperation() {
		super.initOperation();
		this.defineOperation('scan', {
			label: "扫一扫",
			icon: '',
			init: function () { },
			argsDef: [{
				name: 'onlyFromCamera',
				displayName: "只允许从相机扫码"
			}],
			method: function (args) {
				return this.owner.scan(args);
			}
		});
	}
};
ScanCode.EVENT_SUCCESS = "success";
ScanCode.EVENT_FAIL = "fail";
ScanCode.EVENT_COMPLETE = "complete";

wx.comp = wx.comp || {};
wx.comp.ScanCode = ScanCode;
