export default class ScanCodeImpl {
    constructor(page, id) {
        this.page = page;
    }

    scan(args) {
        var onlyFromCamera = true;
        if (args && !args.onlyFromCamera || args.onlyFromCamera === "false") {
            onlyFromCamera = false;
        }
        return wx.scanCode({
            onlyFromCamera: onlyFromCamera
        });
    }
}
