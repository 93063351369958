import BindComponent from "../../lib/base/bindComponent";
import {calItems} from "../checkboxGroup/util";
import {untracked} from  "../../lib/mobx/mobx-2.6.2.umd";
export default class Select extends BindComponent {
     constructor(page, id, props, context){
        super(page, id, props, context);
     }

     buildState(context){
    	 var state = super.buildState(context);
    	 Object.assign(state, this._buildState(context.vars));
    	 return state;
     }
     
     _buildState(vars){
    	 var ret = {index: -1, items: null};
    	 this.items = calItems(this, vars);
    	 if (this.items && this.items.length){
    		 if (this.props.$filter) ret.items = this.items;	
    		 var value = this.getValue();
    		 for (let i=0; i<this.items.length; i++){
    			 if (this.items[i][this.props.itemValue] === value){
    				 ret.index = i;
    				 break;
    			 }
    		 }
    	 }
    	 
    	 return ret;
     }
     
     onChange(evt){
    	 untracked(() => {
        	 if (this.items && this.items.length){
        		 if (evt.detail.value>=0 && evt.detail.value < this.items.length){
        			 var item =  this.items[evt.detail.value];
        			 var value = item[this.props.itemValue];
        			 var label = item[this.props.itemLabel]; 
        			 var dataset = evt.currentTarget.dataset;
        			 this.doChange({value: value});//触发valueChange时间，必须先修改value
        			 this.setValue(dataset.labelpath, dataset.labelprop, label);
        		 }
        	 }
    	 });
     }
}

Select.EVENT_VALUE_CHANGE = "valuechange";

wx.comp = wx.comp || {};
wx.comp.Select = Select;

