import { toUrl } from "../../lib/base/util";

export default class RequestImpl {
	constructor(page) {
		this.page = page;
	}

	_processCfg() {
		var ret = {
			method: this.method,
			dataType: this.dataType || 'json'
		};
		ret.header = {};
		if (this.headersExpr) {
			ret.header = this.page[this.$executeExprBinding_headersExpr](this.context.vars) || {};
		}

		if (this.urlExpr) {
			this.url = this.page[this.$executeExprBinding_urlExpr](this.context.vars) || this.url;
		}
		if (this.url) {
			if (this.url.startsWith("$UI")) {
				ret.url = toUrl(this.url);
			} else {
				ret.url = this.url;
			}
		}
		if (this.contentType !== null && this.contentType !== undefined) ret.header['content-type'] = this.contentType;
		if (this.dataExpr) {
			ret.data = this.page[this.$executeExprBinding_dataExpr](this.context.vars) || {};
		}
		return ret;
	}

	send(cfg,params) {
		this.method = params.method;
		this.dataType = params.dataType;
		this.headersExpr = params.headersExpr;
		this.urlExpr = params.urlExpr;
		this.$executeExprBinding_headersExpr = params.$executeExprBinding_headersExpr
		this.$executeExprBinding_urlExpr = params.$executeExprBinding_urlExpr;
		this.url = params.url;
		this.contentType = params.contentType;
		this.dataExpr = params.dataExpr;
		this.$executeExprBinding_dataExpr = params.$executeExprBinding_dataExpr;
		this.context = params.context;
		this.dispatchEventFn = params.dispatchEventFn;
		let options = this._processCfg();
		if (cfg) {
			if (cfg.data) {
				Object.assign(options.data, cfg.data);
				delete cfg.data;
			}
			if (cfg.header) {
				Object.assign(options.header, cfg.header);
				delete cfg.header;
			}
			Object.assign(options, cfg);
		}
		let dfd = {};
		let promise = new Promise(function (resolve, reject) {
			dfd.resolve = resolve;
			dfd.reject = reject;
		});
		//防止Uncaught (in promise)异常
		promise.catch(() => { });

		let self = this;
		options.fail = function (result) {
			result = result || {};
			var evtData = {  data: result, textStatus: result.statusCode, result: result, resultData: result.data, statusCode: result.statusCode, header: result.header };
			dfd.reject(evtData);//data,textStatus兼容保留
			self.dispatchEventFn("error",evtData);
		};
		options.success = function (result) {
			result = result || {};
			var evtData = {  data: result, textStatus: result.statusCode, result: result, resultData: result.data, statusCode: result.statusCode, header: result.header };//data,textStatus兼容保留
			dfd.resolve(evtData);
			self.dispatchEventFn("success",evtData);
		};
		// self.fireEvent('beforeRequest',{source:self,options:options});
		if (!options.url)
			throw new Error("url是必须的参数");
		self.page.request(options);
		return promise;
	}
}
