import React, {Component} from "react";
import WxComponentBase from "../base/WxComponentBase";
import _ from 'lodash';
import {_getPlaceholderClass, _getPlaceholderStyle} from './utils';
import {fireWxEvent, __styleToJSON} from '../../../core/utils';


export default class WxInput extends WxComponentBase {
  constructor(...args) {
    super(...args);
    this.changeHandlerFn = this.changeHandler.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.state = {
      value:(this.props.value == null ? '' : this.props.value)
    }
  }


  getInitialEvent() {
    return {onFocus: "bindfocus", onBlur: "bindblur"};
  }
  setValue(value){
    let _value = value;
    if(value){
      let maxLength = this.props.maxlength;
      maxLength = Number(maxLength);
      if (isNaN(maxLength)) {
        maxLength = 140;
      } else if (maxLength < -1) {
        maxLength = 140;
      }
      if (maxLength != -1) {
        _value = value.substr(0, maxLength);
      }
      _value = this.formatValue(this.props.type, value);
    }
    this.setState({
      value:_value
    });
    return _value;
  }
  /**
   * 值改变时同步将password类型和maxlength改变
   */
  changeHandler(event) {
    let value = event.target.value;

    value = this.setValue(value);
    //将value和cursor作为input事件的参数
    let pos = this.getCursorPosition(this._input);
    let ret = fireWxEvent(this, "input", {value: value, cursor: pos}, event.currentTarget);

    //接收返回的value和cursor
    if (ret) {
      if (ret.value)
        this.setValue(value);
        if (ret.cursor) {
          setTimeout(() => {
            this.setCursorPosition(this._input, ret.cursor);
          }, 0);
        }
    }
  }

  componentDidMount() {
    setTimeout(() => {
      //this.getComputedStyle();
    });
    const {placeholder}=this.props;
    if (this.toBoolean(this.props['auto-focus']) || this.toBoolean(this.props['focus'])) {
    	//解决auto-focus不起作用的问题, 延迟设置才起作用
    	setTimeout(() => {
    		this._input.focus();
    	}, 500);
    }
    if (placeholder) {
      this._input.removeAttribute("placeholder");
    }

  };
  componentWillReceiveProps(nextProps){
    if(this.props.hasOwnProperty("value") && (nextProps.value != this.props.value || document.activeElement != this._input)){
      this.setState({
        value:(nextProps.value == null?'':nextProps.value)
      });
    }
  }

  formatValue(type, value) {
    let result = value;
    switch (type) {
      case "idcard":
        result = value.substr(0, 18);
        break;
      case "number":
  		//支持+/-号
  		let first = value.substr(0,1);
  		if (first=="-" || first=="+"){
  			value = value.substr(1);
  		}else{
  			first = "";
  		}
        result = first + value.replace(/[^\d]/gi, "");
        break;
      default:
        break;
    }
    return result;
  }

  getCursorPosition(ctrl) {
    var CaretPos = 0;   // IE Support
    if (document.selection) {
      ctrl.focus();
      var Sel = document.selection.createRange();
      Sel.moveStart('character', -ctrl.value.length);
      CaretPos = Sel.text.length;
    }
    // Firefox support
    else if (ctrl.selectionStart || ctrl.selectionStart == '0')
      CaretPos = ctrl.selectionStart;
    return (CaretPos);
  }

  keyPressHandler(event) {
    if (event.charCode === 13) {
      fireWxEvent(this, "confirm", {value: event.target.value}, event.currentTarget);
    }
  }

  setCursorPosition(ctrl, pos) {
    if (ctrl.setSelectionRange) {
      ctrl.focus();
      ctrl.setSelectionRange(pos, pos);
    } else if (ctrl.createTextRange) {
      var range = ctrl.createTextRange();
      range.collapse(true);
      range.moveEnd('character', pos);
      range.moveStart('character', pos);
      range.select();
    }
  }

  getComputedStyle() {
    const eleRootStyle = window.getComputedStyle(this.root);
    const position = this.root.getBoundingClientRect();
    const offsetLfRt = ['Left', 'Right'].map(item =>
      parseFloat(eleRootStyle[`border${item}Width`]) + parseFloat(eleRootStyle[`padding${item}`])
    );
    const offsetTpBt = ['Top', 'Bottom'].map(item =>
      parseFloat(eleRootStyle[`border${item}Width`]) + parseFloat(eleRootStyle[`padding${item}`])
    );
    this._input.style.width = `${position.width - offsetLfRt[0] - offsetLfRt[1]}px`;
    this._input.style.height = `${position.height - offsetTpBt[0] - offsetTpBt[1]}px`;
    this._input.style.color = eleRootStyle.color;
    this._placeholder.style.width = `${position.width - offsetLfRt[0] - offsetLfRt[1]}px`;
    this._placeholder.style.height = `${position.height - offsetTpBt[0] - offsetTpBt[1]}px`;
  }

  doRender() {
    const {className}=this.htmlProps;
    const holderStyle = this.props['placeholder-style'] && __styleToJSON(this.props['placeholder-style']);
    const placeHolClass = this.props['placeholder-class'];
    let newProps = _.omit(this.props, ["style","className","placeholder-style", "placeholder-class", "auto-focus", "focus","id"]); //需要排除checked属性和children属性
    //text, number, idcard, digit
    let type = "text";
    //let step;
    if (this.toBoolean(this.props.password)) {
      type = "password";
    } else if (this.props.type) {
      if (this.props.type == "number") {
        type = "tel";
        //step = "1";
      } else if (this.props.type == "digit") {
        type = "number";
        //step = "any";
      }
    }
    let placeholder = this.state.value || this.state.value === 0 ? "" : this.props.placeholder;

    return (
      <div {...this.toDOMAttrs(this.props)} {...this.htmlProps} ref={ref => this.root = ref}>
        <div disabled={this.toBoolean(this.props.disabled)}>
          <p className={_getPlaceholderClass(placeHolClass)} ref={ref => this._placeholder = ref}
             style={_getPlaceholderStyle(holderStyle)}>{placeholder}</p>
          <input {...this.toDOMAttrs(newProps)} value={this.state.value} type={type}
                 onKeyPress={this.keyPressHandler.bind(this)}
                 ref={(ref) => this._input = ref} disabled={this.toBoolean(this.props.disabled)}
                 onChange={this.changeHandlerFn}
          />
        </div>
      </div>
    );
  }

  compatEventObj(event, wxEventName, rnEventName) {
    let newEvent = super.compatEventObj(event, wxEventName, rnEventName);
    if (newEvent.type == "focus") {
      newEvent.detail = {value: event.target.value}
    } else if (newEvent.type == "blur") {
      newEvent.detail = {value: event.target.value}
    }
    return newEvent;
  }

}

