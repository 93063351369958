import Component from "../../lib/base/component";
import PrintImpl from "./PrintImpl"

let PrintType = {
	PDF: 'pdf'
}
export default class Print extends Component {
	constructor(page, id, props, context) {
		super(page, id, props, context);
		this.data = props.data;
		if (props.datas) {
			this.dataIds = props.datas.split(',');
		}
		this.downloadFilename = props.downloadFilename;
		this.type = props.type || PrintType.PDF;
		this.template = props.template;
		this.contextFn = props.$context;
		this.pageSizeFn = props.$pageSize;
		this.pageHeightFn = props.$pageHeight;
		this.pageWidthFn = props.$pageWidth;
		this.optionFn = props.$option;
		this.impl = new PrintImpl(page);
	}

	print() {
		let self = this;
		
		return Promise.resolve(this.impl.print({
			// 数据集ID
			dataId: this.data,
			// 附加数据
			dataIds: this.dataIds,
			// 文件类型
			type: this.type,
			// 模板
			template: this.template,
			// 下载文件名
			downloadFilename: this.downloadFilename,
			//页面大小
			pageSizeFn: this.pageSizeFn,
			// 页面宽高
			widthFn: this.pageHeightFn,
			heightFn: this.pageWidthFn,
			//打印参数
			optionFn: this.optionFn,
			// 打印上下文
			contextFn: this.contextFn,
			// 上下文对象
			context: this.context,
			// 事件派发
			dispatchEventFn(eventName, data) {
				data.source = self;
				self.fireEvent(eventName, data);
			}
		}));
	}

	preview() {
		let self = this;
		
		return Promise.resolve(this.impl.print({
            preview: true,
			// 数据集ID
			dataId: this.data,
			// 附加数据
			dataIds: this.dataIds,
			// 文件类型
			type: this.type,
			// 模板
			template: this.template,
			// 下载文件名
			downloadFilename: this.downloadFilename,
			//页面大小
			pageSizeFn: this.pageSizeFn,
			// 页面宽高
			widthFn: this.pageHeightFn,
			heightFn: this.pageWidthFn,
			//打印参数
			optionFn: this.optionFn,
			// 打印上下文
			contextFn: this.contextFn,
			// 上下文对象
			context: this.context,
			// 事件派发
			dispatchEventFn(eventName, data) {
				data.source = self;
				self.fireEvent(eventName, data);
			}
		}));
	}

	initOperation() {
		super.initOperation();
		this.defineOperation('print', {
			label: "打印",
			icon: '',
			init: function () { },
			argsDef: [],
			method: function (args) {
				this.owner.print();
			}
		});
		this.defineOperation('preview', {
			label: "预览",
			icon: '',
			init: function () { },
			argsDef: [],
			method: function (args) {
				this.owner.preview();
			}
		});
	}
}

Print.PrintType = PrintType;

wx.comp = wx.comp || {};
wx.comp.Print = Print;
