import TableData from '../tableData/tableData';
import {reaction, toJS} from "../../lib/mobx/mobx-2.6.2.umd";
import {includes,pullAll} from 'lodash';
import Data from "../tableData/data";
/**
 *  展开数据集
 */
export default class ExpandedData extends TableData{
    static getInstance({refData}){
        let key = `${refData.id}__expandedData`;
        if(!refData[key]){
            refData[key] = refData.clone({
                class: ExpandedData,
                id: key,
                buildConfig:(config)=>{

                    /*config.schema.items.props = omitBy(config.schema.items.props,(_,key)=>{
                        return key != columnName && key != idColumn;
                    });*/

                    config.schema.items.props = {};
                    config.schema.items.props['_rowKey'] = {
                        "define":"_rowKey",
                        "label":"唯一标识",
                        "type":"string",
                        "extType":"String"
                    }

                    config.schema.items.props['expandRowIds'] = {
                        "define":"expandRowIds",
                        "label":"展开列ids",
                        "type":"string",
                        "extType":"String"
                    }

                    config.options.primaryColumns = ["_rowKey"];
                    config.options.idColumn = "_rowKey";
                    config.options.treeOption = {};
                    config.options.refData = refData;
                }
            });
        }
        return refData[key];
    }


    init(){
        super.init();
        this.refData = this.props.options.refData;
        if(!this.getCurrentRowID()){
            this.newData({
                defaultValues:{
                    expandRowIds:[]
                }
            });
        }
        let refDataId = this.refData.id;
        reaction(`${refDataId}-current-expanded-reactivity`,()=>{
            return this.refData._currentOB.get();
        },(colValue,reactionContext)=>{
            //当前行变化 保证要展开父
            let idColumn = this.refData.getIdColumn();
            let parentId = this.refData.getParentRow()?.[idColumn];
            this.addExpandRowIds(this.getCurrentRowParentRowIds());
        });


        reaction(`${refDataId}-expanded-reactivity`,()=>{
            return toJS(this.refData.value);
        },(value,reactionContext)=>{
            let expandedRowIds= this.getExpandedRowIds();
            let removeRowIds = [];
            for (const expandedRowId of expandedRowIds) {
                let row = this.refData.getRowByID(expandedRowId);
                if(!row || !this.refData.isLoaded(row)){
                    //不存在的行 或者 没有加载子的行  不能作为展开行
                    removeRowIds.push(expandedRowId);
                }
            }
            this.removeExpandRowIds(removeRowIds);
        });

        //刷新数据后 如果有过滤条件认为是搜索的 默认展开所有
        this.refData.on(Data.EVENT_REFRESHDATA_AFTER, (event)=>{
            if(!event.options.parentRow){
                this.expandAll();
            }
        });
    }

    getExpandedRowIds(){
        return [...this.current.expandRowIds];
    }

    getCurrentRowParentRowIds(){
        let idColumn = this.refData.getIdColumn();
        let currentRow = this.refData.getCurrentRow();
        let parentRowIds = [];
        let parentRow = currentRow;
        while((parentRow = this.refData.getParentRow(parentRow)) != null){
            let parentId = parentRow[idColumn];
            parentRowIds.push(parentId);
        }
        return parentRowIds;
    }

    addExpandRowIds(rowIds = []){
        let expandRowIds = this.current.expandRowIds;
        for(let rowId of rowIds){
            if(!includes(expandRowIds,rowId)){
                expandRowIds.push(rowId);
            }
        }
    }

    removeExpandRowIds(rowIds = []){
        pullAll(this.current.expandRowIds,rowIds);
    }

    getParentIds(){
        let idColumn = this.refData.getIdColumn();
        let parentRowIds = [];
        this.refData.each(({parent, row})=>{
            if(parent){
                parentRowIds.push(parent?.[idColumn]);
            }
        })
        return parentRowIds;
    }

    expandAll(){
        this.addExpandRowIds(this.getParentIds());
    }

    collapseAll(){
        this.current.expandRowIds.clear();
    }

}
