import Component from "../../lib/base/component";

export default class Articles extends Component {
     constructor(page, id, props, context){
        super(page, id, props, context);
     }
    
}

wx.comp = wx.comp || {};
wx.comp.Articles = Articles;

