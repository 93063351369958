import Eventable from "./eventable";
import UUID from "./uuid";
import {isArray,isObject} from "./util";
import ServiceUtil from "./serviceUtil";


let baseUrl = "/batch";

export default class BatchRequest extends Eventable{
	constructor(config){
		super();
		config = config || {};
		
		this._transaction = config.transaction!==false;
		this._concurrent = config.concurrent===true;
		
		this.basePath = config.basePath;
		if(config.page && config.page.basePath){
			this.basePath = config.page.basePath;
		}
		this.page = config.page || wx;
		this._reqs = [];
	}
	
	add(p){
		//返回Promise用于通知批请求结束
		let params = Object.assign({},p);
		delete params.batch;
		return new Promise((resolve, reject) => {
			this._reqs.push({id:UUID.createUUID(), params, deferred: {resolve, reject}});
          });
	}
	
	_cls(){
		this._reqs.splice(0, this._reqs.length);
	}
	
	getReqParams(){
		let headers = {Accept: "application/json"};
		!this._transaction && (headers['X-batch-transaction']=false); 
		this._concurrent && (headers['X-batch-concurrent']=true);
		let reqs = [];
		for(let i=0,len=this._reqs.length;i<len;i++){
			let req = this._reqs[i];
			reqs.push({id: req.id, url: req.params.url, method: req.params.method, header: req.params.header, data: req.params.data});
		}

		var url = this.page.getServiceUrl(baseUrl, this.basePath);
		return {
			method: "POST",
			url: url,
			header: headers,
			data: reqs
		};
	}
	
	execAfter(res){
		if((res.statusCode >= 200) && (res.statusCode < 300)){
			let data = res.data;
			for(let i=0,len=this._reqs.length;i<len;i++){
				let req = this._reqs[i];
				req.deferred.resolve(data[req.id]||res);
			}
		}else{
			for(let i=0,len=this._reqs.length;i<len;i++){
				let req = this._reqs[i];
				req.deferred.reject(res.data || res);
			}
		}
		this._cls();
	}
	
	exec(){
		return new Promise((resolve, reject) => {
			if(this._reqs.length>0){
				let param = this.getReqParams();
				this.page.request(param).then((ret)=>{
					this.execAfter(ret);
					resolve(ret);
				},(err)=>{
					this.execAfter(err || {});
					reject(err);
				});
			}else{
				reject(new Error("批请求为空"));
			}
        });
	}
	
	static batch(fn, option){
		return new Promise((resolve, reject) => {
			let batchReq = new BatchRequest(option);
			let req = ()=>{
				batchReq.exec().then(
						(ret)=>resolve(ret),
						(err)=>reject(err)
				);
			};
			if("function"===typeof(fn)){
				let p = fn(batchReq);
				if(p instanceof Promise){
					p.then(()=>req(), (err)=>reject(err));
				}else{
					req();
				}
			}else{
				req();
			}
		});
	}
}


