import BindComponent from "../../lib/base/bindComponent";
import {observable,autorun,untracked} from '../../lib/mobx/mobx-2.6.2.umd';

export default class Quantity extends BindComponent {
     constructor(page, id, props, context){
        super(page, id, props, context);
        this.size = observable(props.size || '');
        this.placeholder = observable(props.placeholder || '');
        this.compDisabled = observable(!!props.compDisabled);
        this.min = observable(-Number.MAX_VALUE);
        this.max = observable(Number.MAX_VALUE);
        this.inputRefreshFlag = observable(true);
        var self = this;
        autorun(() => {
          if (self.props.$min && (typeof self.page[self.props.$min] === "function")) {
            var minResult = self.page[self.props.$min](self.context.vars);
            untracked(() => {
              if (minResult || 0===minResult) {
                try {
                  console.log(minResult)
                  self.min.set(minResult);
                } catch (e) {
                  // TODO: handle exception
                }
              }
            })
          }
          if (self.props.$max && (typeof self.page[self.props.$max] === "function")) {
            var maxResult = self.page[self.props.$max](self.context.vars);
            untracked(() => {
              if (maxResult || 0===maxResult) {
                try {
                  console.log(maxResult)
                  self.max.set(maxResult);
                } catch (e) {
                  // TODO: handle exception
                }
              }
            })
          }
        })
     }
     
     buildState(context){
     	var state = super.buildState(context);
     	this.inputRefreshFlag.get();
     	state.min = this.min.get();
     	state.max = this.max.get();
     	state.placeholder = this.placeholder.get();
     	state.size = this.size.get();
     	state.disabled = this.compDisabled.get();
     	state.quantity = this.getValue();

     	return state;
     }
     
     _handleMinus(event){
    	 let oldValue = this.getValue();
    	 if(!oldValue){
    		 oldValue = 0;
    	 }
    	 let value = oldValue-1;
    	 if(value>=this.min.get())
    		 this.doChange({value:value});
    	 var res = {
        		 oldValue:oldValue,
        		 value : value
        	 };
        this.fireEvent(Quantity.EVENT_VALUE_DECREASE,{source:this,oldValue:oldValue,value:value});
     }
     
     _handleBlur(event){
    	 let value = event.detail.value;
    	 if(value == ""){
    		 value = null;
    	 }
    	 let oldValue = this.getValue();
    	 let max = this.max.get();
    	 let min = this.min.get();
    	 if(value){
    		 if (Number(value) > Number(max)) value = max;
             if (Number(value) < Number(min)) value = min;
    	 }
    	 this.doChange({value: value});
    	 if(value==oldValue) this.inputRefreshFlag.set(!this.inputRefreshFlag.get());//当值没有变化时刺激input刷新
     }
     
     _handlePlus(event){
    	 let oldValue = this.getValue();
    	 if(!oldValue){
    		 oldValue = 0;
    	 }
    	 let value = oldValue+1;
    	 if(value<=this.max.get())
    		 this.doChange({value:value});
    	 var res = {
    		 oldValue:oldValue,
    		 value : value
    	 };
    	 
    	 this.fireEvent(Quantity.EVENT_VALUE_INCREASE,{source:this,oldValue:oldValue,value:value});
     }
}
Quantity.EVENT_VALUE_CHANGE = "valuechange";
Quantity.EVENT_VALUE_INCREASE = "valueIncrease";
Quantity.EVENT_VALUE_DECREASE = "valueDecrease";

wx.comp = wx.comp || {};
wx.comp.Quantity = Quantity;
