import _createPageConfig from '../../../lib/base/createPageConfig';
import PageClass from './bindphone.user';

 var $g_fns_data = {
		get _userdata(){
			return wx.Util.prepareUserData(this, {

			}, ['id','phonenumber','verifyCode','_key']);
		}
}; 


 var $g_fns_ctrl = {
		get _userdata(){
			return wx.Util.prepareUserData(this, {

			}, ['id','btnLabel','btnDisabled','_key']);
		}
}; 

import '../../commonOperation/commonOperation'; 
import '../../tableData/tableData'; 
import '../../icon/css/icon.css'; 
import '../../input/input'; 
import '../../input/css/input.css'; 
import '../../button/button'; 
import '../../button/css/button.css'; 
import '../../row/css/row.css'; 
import '../../page/page'; 
import '../../page/css/page.css'; 
import '../../wrapper/wrapper'; 
import '../../text/text'; 
import '../../toptips/toptips'; 
import '../../toptips/css/toptips.css'; 
import '../../loading/loading'; 
import '../../loading/css/loading.css'; 
import '../../wxApi/wxApi'; 
var methods = {

 $refFn_input1: function({data,$row,ctrl,$comp,params,$page,props}){
 return data.current.phonenumber ;
}

,$evtH_button1_tap: function({$event,data,$row,$data,ctrl,$item,params,$page,props}){
let $$$args = arguments[0];
	let args={};
	 $page.$compByCtx('commonOperation',$event.source).executeOperation('close', args, $$$args);

}

,
 $refPathFn_input21: function({data,$row,ctrl,$comp,params,$page,props}){
 return data.current._path ;
}

,
 $refPathFn_input1: function({data,$row,ctrl,$comp,params,$page,props}){
 return data.current._path ;
}

,
 $refFn_input21: function({data,$row,ctrl,$comp,params,$page,props}){
 return data.current.verifyCode ;
}

}
var template = [
	[
		{
			"cls":wx.compClass('$UI/wxsys/comps/tableData/tableData'),
			"props":{
				"schema":{
					"limit":20,
					"orderBy":[],
					"type":"array",
					"items":{
						"type":"object",
						"props":{
							"id":{
								"define":"id",
								"label":"ID",
								"type":"string",
								"extType":"String"
							},
							"phonenumber":{
								"define":"phonenumber",
								"label":"电话号码",
								"type":"string",
								"extType":"String"
							},
							"verifyCode":{
								"define":"verifyCode",
								"label":"验证码",
								"type":"string",
								"extType":"String"
							},
							"_key":{
								"type":"string"
							}
						},
						"key":"_key",
						"fns":$g_fns_data
					},
					"id":"data",
					"keyItems":"_key"
				},
				"initData":[
					{
						"id":"data"
					}
				],
				"options":{
					"calcTotal":"",
					"isMain":false,
					"autoMode":"load",
					"directDelete":true,
					"checkRange":"",
					"confirmDelete":true,
					"enableContextReadonly":true,
					"confirmRefreshText":"",
					"allowEmpty":false,
					"checkMode":"",
					"confirmDeleteText":"",
					"confirmRefresh":true,
					"idColumn":"id"
				},
				"id":"data",
				"filters":{}
			}
		},
		{
			"cls":wx.compClass('$UI/wxsys/comps/tableData/tableData'),
			"props":{
				"schema":{
					"limit":20,
					"orderBy":[],
					"type":"array",
					"items":{
						"type":"object",
						"props":{
							"id":{
								"define":"id",
								"label":"ID",
								"type":"string",
								"extType":"String"
							},
							"btnLabel":{
								"define":"btnLabel",
								"label":"获取验证码按钮文字",
								"type":"string",
								"extType":"String"
							},
							"btnDisabled":{
								"define":"btnDisabled",
								"label":"获取验证码按钮可用",
								"type":"boolean",
								"extType":"Boolean"
							},
							"_key":{
								"type":"string"
							}
						},
						"key":"_key",
						"fns":$g_fns_ctrl
					},
					"id":"ctrl",
					"keyItems":"_key"
				},
				"initData":[
					{
						"btnDisabled":false,
						"id":"data",
						"btnLabel":"获取验证码"
					}
				],
				"options":{
					"calcTotal":"",
					"isMain":false,
					"autoMode":"load",
					"directDelete":true,
					"checkRange":"",
					"confirmDelete":true,
					"enableContextReadonly":true,
					"confirmRefreshText":"",
					"allowEmpty":false,
					"checkMode":"",
					"confirmDeleteText":"",
					"confirmRefresh":true,
					"idColumn":"id"
				},
				"id":"ctrl",
				"filters":{}
			}
		}
	],
	{
		"cls":wx.compClass('$UI/wxsys/comps/page/page'),
		"props":{
			"$events":{
				"load":"pageLoad"
			},
			"id":"page"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/loading/loading'),
		"props":{
			"loadingNum":0,
			"id":"__auto1"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/wxApi/wxApi'),
		"props":{
			"id":"wxapi"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/commonOperation/commonOperation'),
		"props":{
			"id":"commonOperation"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/text/text'),
		"props":{
			"id":"text",
			"text":"需要您使用手机号进行账号关联绑定"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/input/input'),
		"props":{
			"$propName":"phonenumber",
			"$refFn":"$refFn_input1",
			"id":"input1",
			"$refPathFn":"$refPathFn_input1"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/input/input'),
		"props":{
			"$propName":"verifyCode",
			"$refFn":"$refFn_input21",
			"id":"input21",
			"$refPathFn":"$refPathFn_input21"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/wrapper/wrapper'),
		"props":{
			"id":"button2"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/button/button'),
		"props":{
			"id":"button"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/button/button'),
		"props":{
			"id":"button1"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/toptips/toptips'),
		"props":{
			"id":"__toptips__"
		}
	}
];
var dependTree = {
	"page":{
		"__toptips__":{
			"__auto8":{
				"depends":["__toptips__.msgs","__toptips__.items[].typeClass","__toptips__.items[].show","__toptips__.items[].message"]
			},
			"depends":["__toptips__.show","__toptips__.compid"]
		},
		"view":{
			"depends":[]
		},
		"inVisibleCompContainer":{
			"depends":[]
		},
		"form":{
			"cell4":{
				"bd4":{
					"depends":[],
					"input21":{
						"depends":["data.current._userdata.verifyCode.required.val","data.current._userdata","data.current._userdata.verifyCode","data.current._userdata.verifyCode.readonly","data.current.verifyCode","input21.compid","__pageid__","data.current._userdata.verifyCode.hidden","data.current"]
					}
				},
				"depends":[],
				"ft4":{
					"button2":{
						"label_vcode-btn":{
							"depends":["ctrl.current.btnLabel"]
						},
						"depends":["button2.compid","__pageid__","ctrl.current.btnDisabled"]
					},
					"depends":[]
				},
				"hd4":{
					"label4":{
						"label4_1":{
							"depends":[]
						},
						"depends":[]
					},
					"depends":[]
				}
			},
			"depends":[],
			"cell1":{
				"hd1":{
					"depends":[],
					"label1":{
						"depends":[],
						"label2_1":{
							"depends":[]
						}
					}
				},
				"depends":[],
				"bd1":{
					"depends":[],
					"input1":{
						"depends":["data.current._userdata.phonenumber.required.val","data.current._userdata","data.current._userdata.phonenumber","data.current._userdata.phonenumber.readonly","data.current.phonenumber","input1.compid","__pageid__","data.current._userdata.phonenumber.hidden","data.current"]
					}
				}
			}
		},
		"depends":["page.compid","__pageid__"],
		"__auto1":{
			"depends":["__auto1.loadingAfterShow","__auto1.loadingNum","__auto1.compid"],
			"__auto2":{
				"__auto7":{
					"depends":[]
				},
				"depends":[],
				"__auto5":{
					"depends":[]
				},
				"__auto6":{
					"depends":[]
				},
				"__auto3":{
					"depends":[]
				},
				"__auto4":{
					"depends":[]
				}
			}
		},
		"text":{
			"depends":["text.compid","text.text"]
		},
		"row":{
			"col":{
				"depends":[]
			},
			"depends":[],
			"col2":{
				"depends":[]
			},
			"col1":{
				"button":{
					"depends":["button.debounce","button.compid","__pageid__"],
					"view2":{
						"depends":[]
					},
					"icon1":{
						"depends":[]
					}
				},
				"depends":[],
				"button1":{
					"depends":["button1.debounce","button1.compid","__pageid__"],
					"view3":{
						"depends":[]
					},
					"icon2":{
						"depends":[]
					}
				}
			}
		},
		"icon3":{
			"depends":[]
		}
	}
}
export function createPageConfig(){
	return _createPageConfig(PageClass, template, methods, {"navigationBarTitleText":"绑定手机"},{}, dependTree)
}
export function createPage(owner, pageid, props){
	var page = new PageClass(owner, props);
	page.$init(template, methods, pageid);
	return page;
}
