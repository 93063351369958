import BaseComponent from "components/pcx/BaseComponent";
import React from 'react';


export default class PageComponent extends BaseComponent {
    constructor(props, context) {
        super(props, context);
        this.__root__ = this.props.__root__;
        this.page = this.getPage();
        this.page.title = this.page.params?.title || this.props.navigationBarTitleText;

        this.page.emitter.on('onLoad', (event) => {
            this.fireEvent("onLoad", event);
        });

        this.page.emitter.on('onShow', (event) => {
            this.fireEvent("onShow", event);
        });

        this.page.emitter.on('onInitState', (event) => {
            if (this.props["onInitState"]) {
                event.waits.push(this.props["onInitState"].bind(this)());
            }
        });
        this.page.emitter.on('onMessage', (event) => {
            this.fireEvent("onMessage", event);
        });

        this.page.emitter.on('onReady', (event) => {
            this.fireEvent("onReady", event);
        });

        this.page.emitter.on('onReactRefChanged', (event) => {
            this.fireEvent("onReactRefChanged", event);
        });

        this.page.emitter.on('onHide', (event) => {
            this.fireEvent("onHide", event);
        });

        this.page.emitter.on('onUnload', (event) => {
            this.fireEvent("onUnload", event);
        });
    }



    shouldComponentUpdate(nextProps, nextState, nextContext){


        if (this.constructDeferredState != this.page.constructDeferred.state) {
            this.constructDeferredState = this.page.constructDeferred.state;
            return true;
        }
        if (nextProps.loading == true && this.props.loading == true) {
            return false;
        }
        return true;
    }


};

