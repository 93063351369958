import Component from "../../lib/base/component";

let PrintType = {
		PDF : 'pdf'
	};
let uploadURL = "/excel/upload";
let importURL = "/excel/import";
let taskURL = "/excel/task/";


export default class ImportExcel extends Component{
    constructor(page, id, props, context){
        super(page, id, props, context);
		this.data = props.data;
		
		this.fileType = props.fileType || ".xls,.xlsx";
		this.datePattern = props.datePattern || "";
		this.dateTimePattern = props.dateTimePattern || "";
		this.timePattern = props.timePattern || "";
		this.to = props.to || "";
		this.excelInfoCol = props.excelInfoCol || "";
    } 
    
	getData() {
		if (this.data)
			return this.page.comp(this.data);
	}
    
	importExcel() {
		let evtData = {source:this,cancel:false};
		this.fireEvent('beforeImport',evtData);
		if (evtData.cancel)
			return;
		return this.chooseExcel();
	}

	checkImportSuccess(data, dtd){
		let taskid = data && data.taskid;
		if(taskid){
			let checkImportHandle, times=1, self=this;
			let checkFn = ()=>{
				checkImportHandle && clearTimeout(checkImportHandle);
				
				//检查时间进行递增
				let t = 2000*times++;
				t = t>20000?20000:t;
				checkImportHandle = setTimeout(function(){
					self.page.request({
						url: self.getUrl(taskURL)+taskid,
						dataType: "json",
						method: "GET",
						success: (res)=>{
							let info = res.data;
							if(info && 2===info.state){
								if(0===info.exitValue){
									dtd.resolve(info);
								}else{
									dtd.reject(info);
								}
							}else checkFn();
						},
						error: function(res){
							dtd.reject(res);
						}
					});								
				}, t);
			};
			
			checkFn();
		}else{
			dtd.reject(data);
		}						
	}
	
	getUrl(url){
		return this.page.getServiceUrl(url);
	}
	
	chooseExcel(){
		wx.chooseAllFile({
			success: (res) => {
				let tempFilePaths = res.tempFilePaths;
				if(res.tempFiles){
					tempFilePaths  = res.tempFiles
				}
				if(tempFilePaths.length>0){
					let filePath = tempFilePaths[0];
					this.upLoadFile(filePath.path || filePath);
				}
			}
		})           
	}
	
	_loadDataToUI(response){
		if ('UI' == this.to) {
			let retRows = response && response.result && response.result.rows;
			if(retRows){
				if(!this.excelInfoCol && retRows && retRows.length>0){
					for(let i=0,len=retRows.length;i<len;i++){
						delete retRows[i].excelInfo;
					}
				}
				let data = this.getData();				
				data.newData({defaultValues:retRows});
			}
		}
	}

	upLoadFile(filePath){
		let param = {
		}
		let evtData = {source:this,param:param};
		this.fireEvent('createImportParam',evtData);

		let uploadTask = wx.uploadFile({
			url: this.getUrl(uploadURL),
			filePath: filePath,
			name: 'excel',
			formData: param,
			success: (res) => {
				if(window){
					res.json().then((data)=>this._doImport(data))
				}else{
					this._doImport(res.data);
				}
			},
			fail: (error) => {
				wx.showToast({
					title: `上传失败`,
					icon: 'loading',
					duration: 3000
				})				
			}
		});
		uploadTask && uploadTask.onProgressUpdate && uploadTask.onProgressUpdate((res) => {
			//上传进度
		});
	}
	
	_doImport(res) {
		let promise = new Promise(async (resolve, reject) => {
			try{
		    	if(wx.showLoading){
		    		wx.showLoading({
		    			title: '导入Excel中，请稍候',
		    			mask: true
		    		});
		    	}else{
		    		wx.showToast({
		    			title: '导入Excel中，请稍候',
		    			icon: 'loading',
		    			duration: 1000*120
		    		});
		    	}
				let fileName = res.fileName ? res.fileName : res.response;
				let restData = this.getData();
				let param = {excel:fileName,dataPath:restData.className,to:this.to,excelInfoCol:this.excelInfoCol};
				this.page.request({
					url: this.getUrl(importURL),
					dataType: "json",
					method: "POST",
					data: param,
					header: {
						'content-type': 'application/x-www-form-urlencoded'
					}
				}).then((imres)=>{
					let data = imres.data;
					this.checkImportSuccess(data,{resolve, reject});
				},(err)=>{
					reject(err);
				});			
			}catch(err){
				reject(err);
			}
		});

		let ret = new Promise((resolve, reject) => {
			promise.then((data)=>{
				wx.hideLoading?wx.hideLoading():wx.hideToast();
				this._loadDataToUI && this._loadDataToUI(data);
				this.fireEvent('success',{
					source : this,
					response : data
				});
				if(data && data.result && data.result.info){
					var info = data.result.info.replace(/\n/g,'<br>');
					wx.showModal({
					    showCancel:false,
					    title: '提示',
					    content: info
					  });
				}
			},(err)=>{
				wx.hideLoading?wx.hideLoading():wx.hideToast();
				console.error(err);
				err = err || {};
				err.source = this;
				this.fireEvent('error', err);
				wx.showModal({
				    showCancel:false,
				    title: '错误',
				    content: "导入Excel失败"
				  });
			});
		});
		
		return ret;
	}

    initOperation(){
   	 super.initOperation();
	   	 this.defineOperation('importExcel', {
	   		 label : "导入",
	   		 icon : '',
	   		 init : function() {},
	   		 argsDef : [],
	   		 method : function(args) {
	   			 this.owner.importExcel();
	   		 }
	   	 });
    }
}

wx.comp = wx.comp || {};
wx.comp.ImportExcel = ImportExcel;
