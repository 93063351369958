import string from "../base/string";
export default {
		parseInt : function(str,defaultValue){
			return string.toInt(str,defaultValue);
		},
		parseFloat : function(str,defaultValue){
			return string.toFloat(str,defaultValue);
		},
		callCompFn: function(page, id, fnName){
			if (!page) throw new Error("调用组件方法时页面对象不能为空");
			if (!id) throw new Error("调用组件方法时页面组件id不能为空");
			if (!fnName) throw new Error("调用组件方法时页面方法名不能为空");
			
			var comp = page.comp(id);
			if (!comp){
				throw new Error("组件" + id + "不存在");
			}
			if (!comp[fnName]){
				throw new Error("组件" + id + "上方法" + fnName + "不存在");
			}
			let args = [];
			let oldArgs = arguments || [];
			for (let i=3; i<oldArgs.length; i++){
				args.push(oldArgs[i]);
			}
			return comp[fnName].apply(comp, args);
		}
	};
