import React, {Component} from 'react';
import WxComponentBase from '../base/WxComponentBase';
import WxPickerRing from './WxPickerRing';
import _ from 'lodash'

export default class PickerDate extends WxComponentBase {
  constructor(...args) {
    super(...args);
    //设置默认的年月日
    let yearArr = [],
      monthArr = [],
      dayArr = [];
    for (var i = 1; i <= 12; i++) {
      monthArr.push(i > 9 ? `${i}月` : `0${i}月`);
    }
    for (var a = 1900; a <= 2100; a++) {
      yearArr.push(`${a}年`);
    }
	let _cur = new Date();
	dayArr = this.getDayArr(_cur.getFullYear(), _cur.getMonth()+1);
    this.state = {
      yearArray: yearArr,
      monthArray: monthArr,
      dayArray: dayArr,
      yearCurrent: _cur.getFullYear(),
      monthCurrent: _cur.getMonth(),
      dayCurrent: _cur.getDate()

    };
    this.onDaySelect = this.onDaySelect.bind(this);
    this.onMonthSelect = this.onMonthSelect.bind(this);
    this.onYearSelect = this.onYearSelect.bind(this);
    this.validDate = this.validDate.bind(this);
    this.setCurrent = this.setCurrent.bind(this);
  }

  getDayArr(year, month){
	var ret = [];
	var size = this.getMaxDay(year, month);
	for (var i = 1; i <= size; i++) {
      ret.push(i > 9 ? `${i}日` : `0${i}日`);
    }
	return ret;
  }

  getMaxDay(year, month){
	month = month * 1;
   	var max = 30;
   	if (month == 1 || month == 3 || month == 5 || month == 7 || month == 8 || month == 10 || month == 12) {
   		max = 31;
   	} else if (month == 2) {
   		year = year * 1;
		if (year % 100 == 0){
			if (year % 400 == 0) {
				max = 29;
			} else {
				max = 28;
			}
		}else{
			if (year % 4 == 0) {
				max = 29;
			} else {
				max = 28;
			}
		}
	}
	return max;
  }


  componentWillReceiveProps(nextProps) {
    if (nextProps.current != this.props.current) {
		let _cur = new Date();
		let year = null;
		let month = null;
		let day = null;
		if (nextProps.current){
		  let arr = nextProps.current.split("-");
		  year = parseInt(arr[0]);
		  month = parseInt(arr[1]);
		  day = parseInt(arr[2]);
		}
		year = !_.isNumber(year)  ? _cur.getFullYear() : Math.min(Math.max(year, 0), 2100);
		month = !_.isNumber(month) ? _cur.getMonth() : Math.min(Math.max(month - 1, 0), 11);
		day = !_.isNumber(day) ? _cur.getDate() : Math.min(Math.max(day, 0), 31);
		let dayArr = this.getDayArr(year, month+1);
      this.setState({
        yearCurrent: year,
        monthCurrent: month,
        dayCurrent: day,
		dayArray: dayArr
      })
    }
    nextProps.start != this.props.start && this.setRange("start", nextProps.start || "1900-01-01");
    nextProps.end != this.props.end && this.setRange("end", nextProps.end || "2100-01-01");
  }

  componentDidMount() {
    this.range = {};
    this.setRange("start", this.props.start || "1900-01-01");
    this.setRange("end", this.props.end || "2100-01-01");
    this.setCurrent(this.props.current);
  }

  //判断有效值
  validDate(yearCurrent, monthCurrent, dayCurrent) {
	  var day = null;
	  var maxDay = this.getMaxDay(yearCurrent, monthCurrent+1);
	  if (!dayCurrent){
		  day = maxDay;
	  }else{
		 dayCurrent = dayCurrent * 1;
		 day = dayCurrent>maxDay ? maxDay : dayCurrent;
      }

	  const validVal = new Date(yearCurrent,monthCurrent,day);
      day = validVal.getDate();
      isNaN(day) && (day=1);
  	  this.state.dayCurrent != day && this.setState({
        dayCurrent: day
      });
      this._dayPicker.setCurrent(day - 1);
      return validVal;
  }

  //设置start和end的范围
  setRange(rangeStr, propsRange) {
    if (propsRange) {
      const invalidVal = new Date(propsRange); //设置标注的时间
      "Invalid Date" != invalidVal && (this.range[rangeStr] = invalidVal.getTime()); //判断填入的时间是否合法
    }
  }

  //设置当前的值
  setCurrent(current) {
    if (current) {
      let invalidVal = new Date(current);
      "Invalid Date" === invalidVal && (invalidVal = new Date);
      invalidVal.getTime() < this.range.start ?
        (invalidVal = new Date(this.range.start)): invalidVal.getTime() > this.range.end && (invalidVal = new Date(this.range.end));
	  var dayArr = this.getDayArr(invalidVal.getFullYear(), invalidVal.getMonth()+1);
      this.setState({
        yearCurrent: invalidVal.getFullYear(),
        monthCurrent: invalidVal.getMonth(),
        dayCurrent: invalidVal.getDate(),
		dayArray: dayArr
      })
    }
  }

  //当设置了start或者end时,对year的限制
  onYearSelect(current) {
    let year = current + 1900;
    const validVal = this.validDate(year, this.state.monthCurrent, this.state.dayCurrent);
    validVal.getTime() < this.range.start ? (year = new Date(this.range.start).getFullYear()): validVal.getTime() > this.range.end && (year = new Date(this.range.end).getFullYear());
	var dayArr = this.getDayArr(year, this.state.monthCurrent+1);
    this.setState({
      yearCurrent: year,
	  dayArray: dayArr
    });
    this._yearPicker.setCurrent(year - 1900)
  }

  //当设置了start或者end时,对month的限制
  onMonthSelect(current) {
    let month = current;
    const validVal = this.validDate(this.state.yearCurrent, month, this.state.dayCurrent);
    validVal.getTime() < this.range.start ? (month = new Date(this.range.start).getMonth()): validVal.getTime() > this.range.end && (month = new Date(this.range.end).getMonth());
	var dayArr = this.getDayArr(this.state.yearCurrent, month+1);
    this.setState({
      monthCurrent: month,
	  dayArray: dayArr
    });
    this._monthPicker.setCurrent(month)
  }

  //当设置了start或者end时,对day的限制
  onDaySelect(current) {
    let day = current + 1;
    const validVal = this.validDate(this.state.yearCurrent, this.state.monthCurrent, day);
    validVal.getTime() < this.range.start ? (day = new Date(this.range.start).getDate()): validVal.getTime() > this.range.end && (day = new Date(this.range.end).getDate());
    this.setState({
      dayCurrent: day
    });
    this._dayPicker.setCurrent(day - 1);
  }

  getValue() {
    let outputVal = this.state.yearCurrent;
    if (this.props.fields === "month") {
      let monthCurrent = this.state.monthCurrent + 1;
      monthCurrent = monthCurrent > 9 ? monthCurrent : `0${monthCurrent}`;
      outputVal = `${outputVal}-${monthCurrent}`;
    } else if (this.props.fields === "day") {
      let month = this.state.monthCurrent + 1;
      month = month > 9 ? month : `0${month}`;
      let day = this.state.dayCurrent;
      day = day > 9 ? day : `0${day}`;
      outputVal = `${outputVal}-${month}-${day}`
    }
    return outputVal;
  }

  doRender() {
    return (
      <div className="wx-picker-bd">
        <WxPickerRing array={this.state.yearArray} current={this.state.yearCurrent - 1900}
                      ref={ref => this._yearPicker = ref}
                      onPickerSelect={this.onYearSelect}/>
        <WxPickerRing array={this.state.monthArray} current={this.state.monthCurrent}
                      hidden={"year" === this.props.fields}
                      ref={ref => this._monthPicker = ref} onPickerSelect={this.onMonthSelect}/>
        <WxPickerRing array={this.state.dayArray} current={this.state.dayCurrent - 1}
                      hidden={"day" !== this.props.fields}
                      ref={ref => this._dayPicker = ref} onPickerSelect={this.onDaySelect}/>
      </div>
    )
  }
}
