import Component from "../../lib/base/component";
import _String from "./string";

export default class BindComponent extends Component {
    constructor(page, id, props, context){
        super(page, id, props, context);
    }
    
    getRefPath(){
    	if (this.props.$refPathFn){
    		return this.page[this.props.$refPathFn](this.context.vars);
    	}else{
    		return;
    	}
    }
    
    getRefPropName(){
    	return this.props.$propName;
    }
    
    getValue(){
    	if (this.props.$refFn){
    		return this.page[this.props.$refFn](this.context.vars);	
    	}else{
    		return null;
    	}
    }
    
    getRow(){
	    let path = this.getRefPath();
	    if(!path) return;
	    var info = this._parsePath(path);
	    if (!info) return;
	    var data = this.page.comp(info.compid);
	    if (data) {
	      return data.getValueByPath(info.path);
	    }
	  }
    
    doChange(options){
   	 	this.setValue(this.getRefPath(), this.getRefPropName(), options.value, (value, oldValue) => {
   	 		this.fireEvent(BindComponent.EVENT_VALUE_CHANGE, {oldValue: oldValue , value: value, source: this});
   	 	});
    }
    
    onChange(evt){
    	this.doChange({value: evt.detail.value});
    }

    
    
    _parsePath(path){
    	var index = path.indexOf("."); 
   	 	if (index>0){
   	 		return {
   	 			compid: path.substr(0, index),
   	 			path: path.substr(index+1)
   	 		}
   	 	}else{
   	 		return null;
   	 	}
    }
    
    getValueByPath(path, prop){
    	if (path){
       	 	var info = this._parsePath(path);
       	 	if (info){
           	 	var data = this.page.comp(info.compid);
           	 	if (data){
           	 		var obj = data.getValueByPath(info.path);
           	 		if (obj){
           	 			return obj[prop];
           	 		}
           	 	}
       	 	}
    	}
    	
    	return null;
    }
    
    isReadonly(){
    	var ret = false;
    	let obj = this._getRefValue();
		if (obj && obj._userdata){
			var propName = this.getRefPropName();
			if (obj._userdata[propName]){
				ret = obj._userdata[propName].readonly;
			}
		}
		return ret;
	} 

    _isEmptyValue(value){
    	return value===undefined || value===null || value==="" || ""===_String.trim(value+"");
    }    

    _getRefValue(){
		let obj;
    	let path = this.getRefPath();
		if (path){
			let info = this._parsePath(path);
			if (info){
				let data = this.page.comp(info.compid);
				data && (obj = data.getValueByPath(info.path));
			}
		}
    	return obj;
    }
    
    isValid(){
    	if (!this._enabledCheck()) return true;	
    	
    	let ret = true;
    	let obj = this._getRefValue();
		if (obj && obj._userdata){
			let propName = this.getRefPropName();
			if(obj._userdata[propName]){
				let userdata = obj._userdata[propName];
				let required = userdata.required;
				if (required && required.val && this._isEmptyValue(obj.$mobx.getRealValue(propName))){
					ret = false;
				}else{
					let constraint = userdata.constraint;
					if (constraint && !constraint.val){
						ret = false;
					}
				}
			}
		}
		return ret;
    }
    
    _enabledCheck(){
    	let path = this.getRefPath();
	    if(!path) return true;
	    var info = this._parsePath(path);
	    if (!info) return true;
	    var data = this.page.comp(info.compid);
	    if (data){
	    	return data.enabledCheck.get();
	    }else{
	    	return true;
	    }
    }
    
    invalidInfo(){
    	if (!this._enabledCheck()) return "";
    	
    	let ret = "";
    	let obj = this._getRefValue();
		if (obj && obj._userdata){
			let propName = this.getRefPropName();
			if(obj._userdata[propName]){
				let userdata = obj._userdata[propName];
				let required = userdata.required;
				if (required && required.val && this._isEmptyValue(obj.$mobx.getRealValue(propName))){
					ret = required.msg || ((obj.$schema.props[propName].label || propName) + "不允许为空");
				}else{
					let constraint = userdata.constraint;
					if (constraint && !constraint.val){
						ret = constraint.msg || ((obj.$schema.props[propName].label || propName) + "不符合规则");
					}
				}
			}
		}
		return ret;
    }
    
    setValue(path, prop, value, callback){
    	this.beforeSetValue(path, prop, value, callback);
    	if (path){
       	 	var info = this._parsePath(path);
       	 	if (!info) return;
       	 	var data = this.page.comp(info.compid);
       	 	if (data){
       	 		var obj = data.getValueByPath(info.path);
       	 		if (obj){
       	 			if (obj[prop] !== value){
       	 				let oldVal = obj[prop];
       	 				obj[prop] = value;
       	 				callback && callback(value, oldVal);
       	 			}
       	 		}else{
       			 //throw new Error("找不到数据项" + path + "");
       	 		}
       	 	}else{
       		 //throw new Error("组件" + info.compid + "未定义");
       	 	}
    	}else{
    		callback && callback(value, undefined);
    	}
    }
    
    beforeSetValue(path, prop, value, callback){
    	
    }
}

BindComponent.EVENT_VALUE_CHANGE = "valuechange";
