import {runInAction, toJS} from "../../../../wxsys/lib/mobx/mobx-2.6.2.umd";

function getFn(obj, path) {
    var items = path.split(".");
    var fn = obj;
    for (var i = 0; i < items.length; i++) {
        if (items[i]) {
            fn = fn[items[i]];
            if (!fn) break;
        } else {
            fn = null;
            break;
        }
    }
    return fn;
}

//支持自定义事件的处理器是{{}}的情况
function evalCompid(page, compid) {
    if (compid && (compid.slice(0, 2) == "{{") && (compid.slice(-2) == "}}")) {
        compid = compid.substring(2, compid.length - 2);
        var o = page.$getObjectByPath(page.data, compid);
        if (o && o.obj && o.key && o.obj[o.key]) {
            return o.obj[o.key];
        }
    }
    return compid;
}


export function doEventHandler(page, ownerComp, handlers, evt, isNative) {
    runInAction(() => {
        try {//所有的异常延迟抛出，保证runInAction能完整执行
            var handlerList = handlers.split(",");
            for (var i = 0; i < handlerList.length; i++) {
                var handler = handlerList[i];
                if (handler) {
                    var compid = "";
                    var fnPath = "";
                    var items = handler.split(":");
                    if (items.length == 2) {
                        compid = items[0];
                        fnPath = items[1];
                    } else {
                        compid = "";
                        fnPath = items[0];
                    }
                    compid = evalCompid(page, compid);
                    var comp = compid ? page.comp(compid) : page;
                    if (!fnPath) {
                        throw new Error("'" + fnPath + "' is undefined!");
                    }

                    var fn = getFn(comp, fnPath);
                    if (typeof fn === "function") {
                        var context = {$page: page, params: page.params};
                        if (ownerComp && ownerComp.getContext()) {
                            Object.assign(context, ownerComp.getContext().vars || {});
                            ownerComp.getEventVars && Object.assign(context, ownerComp.getEventVars(evt) || {});
                        }
                        var newEvt = null;
                        if (isNative) {
                            newEvt = Object.assign({context: Object.assign({}, context), source: ownerComp}, evt);
                        } else {
                            newEvt = Object.assign(evt || {}, {context: Object.assign({}, context), source: ownerComp});
                        }
                        var ret = null;
                        if (fnPath.indexOf("$evtH") === 0) {
                            //调用操作
                            context.$event = newEvt;
                            ret = fn.call(null, context);
                        } else {
                            ret = fn.call(comp, newEvt);
                        }
                        //自定义事件只允许挂一个事件处理器
                        if (!isNative) return ret;
                    } else {
                        throw new Error(fnPath + " is not a function");
                    }
                }
            }
        } catch (e) {
            let oldE = e;
            setTimeout(function () {
                throw oldE;
            }, 1);
        }
    });
}


export default function WxPageDeclaration(template,methods,pageConfig,serviceInfo,compTree,$page){
    $page.$template = template;
    $page.$methods = methods;
    return {
        $page:$page,
        __compTree: compTree,
        getConfig:function() {
            return pageConfig;
        },

        onLoad:function(options) {
        },

        onReady:function() {
            return new Promise((resolve,reject)=>{
                runInAction(() => {
                    this._fireEvent("ready");
                    this.loaded = false;
                    let items = [];
                    let compPromises = this.$page.$compPromises;
                    let noComps = [];
                    for (let name in compPromises) {
                        if (compPromises.hasOwnProperty(name) && compPromises[name]) {
                            items.push(compPromises[name]);
                        }

                        if (!this.$page.comp(name)) {
                            noComps.push(name);
                        }
                    }
                    if (noComps.length > 0) {
                        console.error("组件[" + noComps.join(",") + "]不存在，“页面加载完成”事件将不会被触发！");
                    }

                    Promise.all(items).then(() => {
                        resolve();
                    });

                    if (compPromises) {
                        setTimeout(() => {
                            if (!this.loaded) {
                                let errorComps = [];
                                for (let name in compPromises) {
                                    if (compPromises.hasOwnProperty(name)
                                        && compPromises[name]
                                        && (compPromises[name].$status != "resolved")) {
                                        errorComps.push(name);
                                    }
                                }
                                if (errorComps.length > 0) {
                                    console.warn("10s后组件没有初始化完成！可能的组件是：" + errorComps);

                                }
                                reject(errorComps);
                            }
                        }, 10000);
                    }
                });
            })
        },

        onLoaded:function(){
            runInAction(() => {
                this._fireEvent("loaded");
                this.$fireParentPageFrameEvent("loaded");
                this.loaded = true;
            });
        },



        $fireParentPageFrameEvent:function(name) {
            let parentPageFrame = this.$page.getParentFrame();
            if (parentPageFrame && parentPageFrame.fireEvent) {
                parentPageFrame.fireEvent(name, {});
            }
        },



        onShow:function() {
            this._fireEvent("show");
        },

        onHide:function() {
            this._fireEvent("hide");
        },

        onPullDownRefresh:function() {
            this._fireEvent("pullDownRefresh");
        },

        onReachBottom:function() {
            this._fireEvent("reachBottom");
        },

        onUnload:function() {
            this._fireEvent("unload");
        },

        onShareAppMessageHandle:function(res) {
            let eventData = {source: this.$page, result: null, res: res};
            let result;
            runInAction(() => {
                this.$page.fireEvent("beforeShareAppMessage", eventData);
                this.$page.fireEvent("shareAppMessage", eventData);
                this.$page.fireEvent("afterShareAppMessage", eventData);
            });
            return result;
        },

        onPageScroll:function() {
            this._fireEvent("pageScroll");
        },

        _fireEvent:function(event, eventData) {
            eventData = eventData || {source: this.$page};
            let suffix = event.charAt(0).toUpperCase() + event.substring(1);
            runInAction(() => {
                this.$page.fireEvent("before" + suffix, eventData);
                this.$page.fireEvent(event, eventData);
                this.$page.fireEvent("after" + suffix, eventData);
            });
            return eventData;
        },

        dispatchBind:function(evt) {
            this.dispatch(evt, "bind");
        },

        debounceDispatchBind:function(evt) {
            let target = evt.currentTarget || evt.target;
            let pageid = target.dataset.pageid;
            let page = this.getPage(pageid);
            if (target.dataset.compid) {
                var ownerComp = page.comp(target.dataset.compid);
                if (ownerComp.startDebounce) {
                    let timer = function (prevTimer, timeout) {
                        if (prevTimer) {
                            clearTimeout(prevTimer);
                        }
                        let timerId = setTimeout(function () {
                            if (ownerComp) {
                                ownerComp.tapRunning = false;
                                if (ownerComp.stopDebounce) {
                                    ownerComp.stopDebounce();
                                }
                            }
                            wx.request.off("requestSuccess", endRequestFn);
                            wx.request.off("requestError", endRequestFn);
                            wx.request.off("requestSend", sendRequestFn);
                        }, timeout);
                        return timerId;
                    };
                    let endRequestFn = function () {
                        ownerComp.tapTimerId = timer(ownerComp.tapTimerId, 0);
                    };
                    let sendRequestFn = function () {
                        if (ownerComp && ownerComp.startDebounce) {
                            ownerComp.startDebounce();
                        }
                        ownerComp.tapTimerId = timer(ownerComp.tapTimerId, 5000);
                    };
                    wx.request.on("requestSuccess", endRequestFn);
                    wx.request.on("requestError", endRequestFn);
                    wx.request.on("requestSend", sendRequestFn);
                    ownerComp.tapTimerId = timer(ownerComp.tapTimerId, 300);
                    if (!ownerComp.tapRunning) {
                        ownerComp.tapRunning = true;
                        this.dispatchBind(evt, "bind");
                    }
                }
                return;
            }
            this.dispatchBind(evt, "bind");
        },

        dispatchCatch:function(evt) {
            this.dispatch(evt, "catch");
        },

        dispatchCaptureBind:function(evt) {
            this.dispatch(evt, "captureBind");
        },

        dispatchCaptureCatch:function(evt) {
            this.dispatch(evt, "captureCatch");
        },

        dispatch:function(evt, prefix) {
            let target = evt.currentTarget || evt.target; //小程序中有些事件没有currentTarget, 和文档不一致
            let pageid = target.dataset.pageid;
            let page = this.getPage(pageid);
            //handler格式: "compid:fn"
            // 特殊处理地图组件  bindregionchange 视野发生变化事件，由于bindregionchange的type是 end 和 begin。如果遇到这个事件，硬性转换成regionchange
            let type = evt.type == "begin" || evt.type == "end" ? "regionchange" : evt.type;
            let handler = target.dataset[prefix + type];
            if (!handler) {
                return;
            }
            let ownerComp = null;
            if (target.dataset.compid) {
                ownerComp = page.comp(target.dataset.compid);
            }

            //解决textarea的bindblur在bindtap之后执行的问题, 将input上的值更新到data中
            if ((evt.type === "tap") && page.$lastInput) {
                page.$lastInput.$updateValue();
            }
            doEventHandler(page, ownerComp, handler, evt, true);
        },

        dispatchCompEvent:function(compid, tagName, eventName, ...args) {
            runInAction(() => {
                if (compid && this.$page) {
                    var comp = this.$page.comp(compid);
                    var evt = {source: comp, data: args};
                    comp.processNativeEvent(evt, tagName, eventName);
                    comp.fireEvent(eventName, evt);
                }
            });
        },

        getPage:function(pageid) {
            let result = this.$page;
            if (pageid) {
                let ids = pageid.split(";");
                for (let i = 0; i < ids.length; i++) {
                    if (ids[i]) {
                        //取出的是pageComp组件
                        var comp = result.comp(ids[i]);
                        if (!comp) {
                            throw new Error("find page by pageid is null! pageid: " + pageid + ", error id: " + ids[i]);
                        }
                        result = comp.innerPage;
                    }
                }
            }
            return result;
        }
    }
}
