import ReactContainer from "../reactContainer/reactContainer";
import {observable, autorun, toJS, untracked} from  "../../lib/mobx/mobx-2.6.2.umd";
import renderComponent from "../../lib/base/renderComponent";
import {cloneJSON} from "../../lib/base/util";

export default class Treex extends ReactContainer{
     constructor(page, id, props, context){
        super(page, id, props, context);

        this.dataSource = this.props.dataSource || "";
        this.columns = observable(cloneJSON(this.props.columns));

        this.item = this.props.item || "item";
        this.index = this.props.index || "index";
        this.titleColumn = observable(this.props.titleColumn);
        
        //"checkable","showIcon","showLine"
        this.checkable = observable(this.props.checkable=="true" || false);
        this.showIcon = observable(this.props.showIcon=="true" || false);
        this.showLine = observable(this.props.showLine=="true" || false);
        
        this.checkedIds = null;
        	
        this.initEvents();
     }
     
     onRowClick(event){
    	 var data = this._getData();
    	 if (data && event.detail && event.detail.row){
    		 let rowId = event.detail.row[data.getIdColumn()];
    		 rowId && data.to(rowId);
    	 }
     }
     
     initEvents(){
     }
     
     destroy(){
    	 super.destroy();
     }
     
     getCheckedIds(){
    	 return this.checkedIds;
     }
     
     doCustomEvent(event){
    	 let detail = event && event.detail;
    	 if(detail && detail.type){
    		 if(detail.type==='click'){
    			 this.onRowClick(event);
    		 }else if(detail.type==='check'){
    			 this.checkedIds = event.detail && event.detail.checkedKeys;
    		 }else if(detail.type==='loadData'){
    			 let data = this._getData();
    			 let rowId = event.detail.key;
    			 if(data && rowId){
    				 let row = data.getRowByID(rowId);
    				 if(row && !data.isLoaded(row)){
    					 event.detail.promise = data.refreshData({parentRow:row});
    				 }
    			 }
    			 return;
    		 }
    		 this.fireEvent(detail.type, event);
    	 }
     }
     
     set(props){
    	 if(props){
    		 props.hasOwnProperty("checkable") && this.checkable.set(props.checkable);
    		 props.hasOwnProperty("showIcon") && this.showIcon.set(props.showIcon);
    		 props.hasOwnProperty("showLine") && this.showLine.set(props.showLine);
    		 props.hasOwnProperty("titleColumn") && this.showLine.set(props.titleColumn);
    	 }
     }
     
     getTreeItems(vars){
    	 let items = [];
    	 this.treeData = this.buildTreeData(items, vars);
    	 return items;
     }
     
     buildTreeData(items, vars, rows){
        let result = []; 
    	let titleColumn=this.titleColumn.get();
    	if(!rows && this.props.$dataSource){
            rows = this.page[this.props.$dataSource](vars) || [];    		
    	}
    	
        if (titleColumn && rows){
            let data = this._getData();
            for (let i=0; i<rows.length; i++){
            	let row = rows[i];
            	
                if (this.props.$filter){
                	let newVars = this._copyVars(vars);
                	let item = rows[i];
                	newVars[this.item] = item;
                	newVars[this.index] = i;   //计算filter时, 可以引用index
                	if (!this.page[this.props.$filter](newVars)){
                		row = null;
                	}
                }
            	
            	if(row){
            		let treeRow = Object.assign({}, row, {$key: row[this.props.idColumn], $title: row[titleColumn]});
            		result.push(treeRow);
            		items.push(treeRow);
            		
            		if (this.props.$rowDisabled || this.props.$rowIcon){
                    	let newVars = this._copyVars(vars);
                    	let item = rows[i];
                    	newVars[this.item] = item;
                    	newVars[this.index] = i;   //计算filter时, 可以引用index
                    	if (this.props.$rowDisabled && this.page[this.props.$rowDisabled](newVars)){
                    		treeRow.$disabled = true;
                    	}
                    	if(this.props.$rowIcon){
                    		let iconId = this.page[this.props.$rowIcon](newVars);
                    		treeRow.$iconComp = "wc_"+this.id+"_"+iconId;
                    	}
            		}
            		
            		let children = data.isLoaded(row)?data.getChildren(row):null;
            		if(children){
            			let c = this.buildTreeData(items, vars, children);
            			c && c.length>0 && (treeRow.children = c);
            		}
            	}
            }
        }

        return result;
     }

    calItems(vars){
    	vars = this._copyVars(vars);
		vars['$self'] = this;
		vars['$vars'] = vars;
    	
    	return super.calItems(vars);
    }
    
    buildState(context){
    	let state = super.buildState(context);

    	state.dataSource = this.treeData || [];
    	state.checkable = this.checkable.get();
    	state.showIcon = this.showIcon.get();
    	state.showLine = this.showLine.get();
        
    	return state;
    }
    
}



wx.comp = wx.comp || {};
wx.comp.Treex = Treex;
