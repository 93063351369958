import Component from "../../lib/base/component";
import {observable } from  "../../lib/mobx/mobx-2.6.2.umd";

export default class Pagebar extends Component {
     constructor(page, id, props, context){
        super(page, id, props, context);
     }
}


wx.comp = wx.comp || {};
wx.comp.Pagebar = Pagebar;

