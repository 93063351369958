import React,{Component} from 'react';
import WxComponentBase from "../base/WxComponentBase";

import WxPickerRing from './WxPickerRing'

export default class WxPickerSelector extends WxComponentBase{
    constructor(...args){
        super(...args);
        this.onPickerSelect=this.onPickerSelect.bind(this);
    }
    componentWillReceiveProps (nextProps) {
                nextProps.current != this.props.current && (this.current=nextProps.current);
    }
	confirm(evt){
		this.props.confirmFn && this.props.confirmFn(evt);
	}
    onPickerSelect(current, hide, evt){
        this.current=current;
		hide && this.confirm(evt);
    }
    getValue(){
        return this.current;
    }
    doRender(){
    	 let arr = this.props.array;
    	 if(arr && (typeof arr[0] == "object") && this.props["range-key"]){
    		 let nowArr = []
    		 let key = this.props["range-key"];
    		 arr.forEach((item)=>{
    			 nowArr.push(item[key])
    		 })
    		 arr = nowArr
    	 }

        return (
            <div className="wx-picker-bd">
                <WxPickerRing array={arr} current={this.props.current} onPickerSelect={this.onPickerSelect}/>
            </div>
        )
    }
}