/*! 
* WeX5 v3 (http://www.justep.com) 
* Copyright 2015 Justep, Inc.
* Licensed under Apache License, Version 2.0 (http://www.apache.org/licenses/LICENSE-2.0) 
*/
import Component from "../../lib/base/component";
import {observable} from '../../lib/mobx/mobx-2.6.2.umd';

export default class Dialog extends Component {
     constructor(page, id, props, context){
        super(page, id, props, context);
        this.showState = observable(false);
        this.title = observable(props.title||"");
        this.hideTitle = observable(props.hideTitle==='true' || props.hideTitle===true);
     }
     
     buildState(context){
     	var state = super.buildState(context);
     	state.show = this.showState.get();
     	state.title = this.title.get();
     	state.hideTitle = this.hideTitle.get();
     	return state;
     }
     
     show(){
    	 this.showState.set(true);
    	 this.fireEvent('show', {source : this});
     }
     
     hide(){
    	 this.showState.set(false);
    	 this.fireEvent('hide', {source : this});
     }

	 okhide(){
    	 this.showState.set(false);
    	 this.fireEvent('hide', {source : this, status : "ok"});
     }
     
     maskHide(evt){
         if (this.props.dismissible && evt.currentTarget.id == evt.target.id) this.hide();
     }
     
     initOperation() {
         super.initOperation();
         this.defineOperation('show', {
             label: "显示",
             argsDef: [],
             method: function(args) {
                 return this.owner.show();
             }
         });
         this.defineOperation('hide', {
             label: "隐藏",
             argsDef: [],
             method: function(args) {
                 return this.owner.hide();
             }
         });
     }
     
}

wx.comp = wx.comp || {};
wx.comp.Dialog = Dialog;
